import {IResourceComponentsProps, useList, useTranslate} from "@refinedev/core";
import {DateField, DeleteButton, EditButton, EmailField, List, ShowButton, useDataGrid} from "@refinedev/mui";
import {DataGrid, deDE, GridColDef} from "@mui/x-data-grid";
import React, {useState} from "react";
import {Checkbox, Drawer, Tooltip} from "@mui/material";
import {HumanaDataGrid} from "../../components/custom/HumanaDataGrid";
import {HumanaUtils} from "../../utils/HumanaUtils";
import {FaLock, FaLockOpen} from "react-icons/fa";
import {IUnit, IUser} from "../../rest-data-provider/models";
import {UserShow} from "./show";

export const UserList: React.FC<IResourceComponentsProps> = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const translate = useTranslate();
    const { dataGridProps} = useDataGrid({
        // onSearch:HumanaUtils.dataGridSearch,
        filters: {
            permanent: [
                {
                    field: "q",
                    operator: "eq",
                    value: searchTerm,
                },
            ],
        },
        syncWithLocation: true,
    });

    const [selectedUserId, setSelectedUserId] = useState<number>(1)
    const [showDetail, setShowDetail] = useState<boolean>(false);

    const [units, setUnits] = useState<IUnit[]>([])
    const unitResponse = useList<IUnit>({resource: 'locations'});
    if (unitResponse.data && units.length === 0) {
        setUnits(unitResponse.data.data);
    }

  const columns = React.useMemo<GridColDef<IUser>[]>(
      () => [
            {
              field: "id",
              headerName: translate("users.fields.id"),
              type: "number",
              minWidth: 50,
            },
            {
              field: "email",
              flex: 1,
              headerName: translate("users.fields.email"),
              minWidth: 250,
              renderCell: function render({ value }) {
                return <EmailField value={value} />;
              },
            },
            {
              field: "firstName",
              flex: 1,
              headerName: translate("users.fields.firstName"),
              minWidth: 100,
            },
            {
              field: "lastName",
              flex: 1,
              headerName: translate("users.fields.lastName"),
              minWidth: 100,
            },
            {
              field: "phoneNumber",
              flex: 1,
              headerName: translate("users.fields.phoneNumber"),
              minWidth: 50,
            },
            {
                field: "role",
                headerName: translate("users.fields.role"),
                flex: 1.5,
                align: "center",
                headerAlign: "center",
                sortable: false,
                minWidth: 200,
                renderCell: function render({ row }) {
                  return (
                    <Tooltip placement={'right-start'} title={HumanaUtils.formatOrgUnitForUser(row, units)}>
                        <label>{row?.role?.displayName}</label>
                    </Tooltip>
                  )
                },
            },
            {
              field: "active",
              headerName: translate("users.fields.locked"),
              align: "center",
              headerAlign: "center",
              minWidth: 100,
              renderCell: function render({ value }) {
                return value ? <FaLockOpen /> : <FaLock />;
              },
            },
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                renderCell: function render({ row }) {
                    return (
                        <>
                          <EditButton hideText recordItemId={row.id} />
                          <ShowButton hideText recordItemId={row.id} onClick={() => {
                              setSelectedUserId(row.id);
                              setShowDetail(true);
                          }} />
                          <DeleteButton hideText recordItemId={row.id} />
                        </>
                    );
                },
              align: "center",
              headerAlign: "center",
              minWidth: 80,
            },
      ],
      [translate, units],
  );

  return (
      <List title={translate('users.users')}>
          <HumanaDataGrid isLoading={units.length === 0 || dataGridProps.loading} onSearchTermChanged={setSearchTerm} dataGridProps={dataGridProps}   columns={columns} ></HumanaDataGrid>
          <Drawer
              sx={{overflow: 'visible'}}
              variant={"temporary"}
              // anchor={anchor}
              anchor={"right"}
              open={!!showDetail}
              onClose={event => {
                  setShowDetail(false);
              }}

          >
             <UserShow userId={selectedUserId} />
          </Drawer>
      </List>
  );
};
