import React, {useState, useEffect} from "react";
import {useDebounce} from "usehooks-ts";
import TextField from "@mui/material/TextField";
import {InputAdornment} from "@mui/material";
import {FaSearch} from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import {Clear} from "@mui/icons-material";

interface SearchFieldProps {
    searchTermChanged: (searchTerm: string) => void
}

export const SearchField : React.FC<SearchFieldProps> = ({searchTermChanged}) => {

    const [searchTerm, setSearchTerm] = useState('')
    const debouncedSearchTerm = useDebounce(searchTerm, 300);

    useEffect(() => {
        searchTermChanged(debouncedSearchTerm)
    }, [debouncedSearchTerm])

    return (
        <TextField
            value={searchTerm}
            onChange={event => {
                setSearchTerm(event.target.value)
            }}
            autoComplete={"off"}
            InputProps={{
                sx: {borderRadius: 20, fontSize: 16},
                startAdornment: (<InputAdornment position={"start"}><FaSearch/></InputAdornment>),
                endAdornment: (searchTerm.length > 0 ? <InputAdornment position={"end"}><IconButton onClick={() => {
                    setSearchTerm('')
                }}><Clear/></IconButton></InputAdornment> : <></>)
            }}
            placeholder={'Suche...'} fullWidth size={"small"} style={{marginBottom: '10px', paddingLeft: '10px', paddingRight: '10px'}}>
        </TextField>
    )
}