import React, {useEffect, useState} from "react";
import {AggregationDTO, ReportDefaultResponseDTO} from "../../rest-data-provider/customDTOs";
import {Card, CardContent, CardHeader, Grid, Link} from "@mui/material";
import {BudgetCard} from "./BudgetCard";
import {GiClothes, GiClothesline, GiWeight} from "react-icons/gi";
import {RiMoneyEuroCircleLine} from "react-icons/ri";
import {FaTruckArrowRight} from "react-icons/fa6";
import {IoPeople, IoTimeOutline, IoTimeSharp} from "react-icons/io5";
import {MdOutlineWorkHistory, MdSell, MdShoppingCart} from "react-icons/md";
import {DashboardCard} from "./DashboardCard";
import {HumanaUtils} from "../../utils/HumanaUtils";
import {TbHanger} from "react-icons/tb";
import {WeekCharCard} from "./WeekChartCard";
import {ShopCategoryBarChart} from "./ShopCategoryBarChart";
import {BiBarcode} from "react-icons/bi";


interface MainDashboardProps {
    data: ReportDefaultResponseDTO
}
export const MainDashboard : React.FC<MainDashboardProps> = ({data}) => {


    const [percentageBarcode, setPercentageBarcode] = useState(0)
    const [percentageBarcodePrevious, setPercentageBarcodePrevious] = useState(0)

    useEffect(() => {
        const sumBarcodes : any = Object.values(data.currentStatistics.shopCategories).reduce((previousValue, currentValue:any) => {
            if(currentValue.barcode) {
                return previousValue + currentValue.countSold;
            }
            return previousValue;
        }, 0);
        const sumTotal : any = Object.values(data.currentStatistics.shopCategories).reduce((previousValue, currentValue:any) => {
            return previousValue + currentValue.countSold;
        }, 0);
        setPercentageBarcode((sumBarcodes/sumTotal * 100))


        const sumBarcodesPrevious : any = Object.values(data.previousStatistics.shopCategories).reduce((previousValue, currentValue:any) => {
            if(currentValue.barcode) {
                return previousValue + currentValue.countSold;
            }
            return previousValue;
        }, 0);
        const sumTotalPrevious : any = Object.values(data.previousStatistics.shopCategories).reduce((previousValue, currentValue:any) => {
            return previousValue + currentValue.countSold;
        }, 0);
        setPercentageBarcodePrevious((sumBarcodesPrevious/sumTotalPrevious * 100))

    }, [data]);


    return(
        <>
            {data.currentStatistics.sales && data.currentStatistics.sales.sum > 0 && (
                <>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={6} xl={6}>
                            <BudgetCard format={'0.00 a'} title={"Umsatz"} icon={<RiMoneyEuroCircleLine fontSize={35} />} value={HumanaUtils.filterNaNValue(data.currentStatistics.sales.sum)} valuePrevious={HumanaUtils.filterNaNValue(data.previousStatistics.sales.sum)} targetValue={HumanaUtils.filterNaNValue(data.currentStatistics.budget.sum)} unit={"€"}></BudgetCard>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} xl={6}>
                            <BudgetCard format={'0.0 a'} title={"Stück eingehangen"} icon={<GiClothesline fontSize={35} />} value={HumanaUtils.filterNaNValue(data.currentStatistics.additional.restockCount)} valuePrevious={HumanaUtils.filterNaNValue(data.previousStatistics.additional.restockCount)} targetValue={HumanaUtils.filterNaNValue(data.currentStatistics.budget.restockCount)} unit={""}></BudgetCard>
                        </Grid>
                        {data.location.type.toLowerCase() != "department" && (
                            <Grid item xs={12} sm={12} md={6} xl={6}>
                                <BudgetCard format={'0.0 a'} title={"Umsatz/Kilo"} icon={<GiWeight fontSize={35} />} value={HumanaUtils.filterNaNValue(data.currentStatistics.sales.sum / data.currentStatistics.additional.restockWeight)} valuePrevious={HumanaUtils.filterNaNValue(data.previousStatistics.sales.sum / data.previousStatistics.additional.restockWeight)} targetValue={HumanaUtils.filterNaNValue(data.currentStatistics.budget.sum / data.currentStatistics.budget.restockWeight)} unit={"€/kg"}></BudgetCard>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={6} xl={6}>
                            <BudgetCard format={'0.0 a'} title={"Stück verkauft"} icon={<GiClothes fontSize={35} />} value={HumanaUtils.filterNaNValue(data.currentStatistics.sales.countSold)} valuePrevious={HumanaUtils.filterNaNValue(data.previousStatistics.sales.countSold)} targetValue={HumanaUtils.filterNaNValue(data.currentStatistics.budget.countSold)} unit={""}></BudgetCard>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} xl={6}>
                            <BudgetCard format={'0.00 a'} title={"Umsatz/Stück"} icon={<MdSell fontSize={35} />} value={HumanaUtils.filterNaNValue(data.currentStatistics.sales.sum / data.currentStatistics.sales.countSold)} valuePrevious={HumanaUtils.filterNaNValue(data.previousStatistics.sales.sum / data.previousStatistics.sales.countSold)} targetValue={HumanaUtils.filterNaNValue(data.currentStatistics.budget.sum / data.currentStatistics.budget.countSold)} unit={"€"}></BudgetCard>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} xl={6}>
                            <BudgetCard format={'0.00 a'} title={"Umsatz/Eingehangen"} icon={<TbHanger fontSize={35} />} value={HumanaUtils.filterNaNValue(data.currentStatistics.sales.sum / (data.currentStatistics.additional.restockCount))} valuePrevious={HumanaUtils.filterNaNValue(data.previousStatistics.sales.sum / (data.previousStatistics.additional.restockCount ))} targetValue={HumanaUtils.filterNaNValue(data.currentStatistics.budget.sum / (data.currentStatistics.budget.restockCount))} unit={"€"}></BudgetCard>
                        </Grid>
                        {data.location.type.toLowerCase() != "department" && (
                            <Grid item xs={12} sm={12} md={6} xl={6}>
                                <BudgetCard format={'0.0 a'} title={"Anlieferung"} icon={<FaTruckArrowRight fontSize={35} />} value={HumanaUtils.filterNaNValue(data.currentStatistics.additional.restockWeight)} valuePrevious={HumanaUtils.filterNaNValue(data.previousStatistics.additional.restockWeight)} targetValue={HumanaUtils.filterNaNValue(data.currentStatistics.budget.restockWeight)} unit={"kg"}></BudgetCard>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={6} xl={6}>
                            <BudgetCard format={'0.00 a'} title={"Arbeitsstunden"} icon={<IoTimeOutline fontSize={35} />} value={HumanaUtils.filterNaNValue(data.currentStatistics.additional.workTime / 60)} valuePrevious={HumanaUtils.filterNaNValue(data.previousStatistics.additional.workTime / 60)} targetValue={HumanaUtils.filterNaNValue(data.currentStatistics.budget.workTime / 60)} unit={""}></BudgetCard>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} xl={6}>
                            <BudgetCard format={'0.00 a'} title={"Umsatz/Arbeitsstunde"} icon={<MdOutlineWorkHistory fontSize={35} />} value={HumanaUtils.filterNaNValue(data.currentStatistics.sales.sum / (data.currentStatistics.additional.workTime / 60))} valuePrevious={HumanaUtils.filterNaNValue(data.previousStatistics.sales.sum / (data.previousStatistics.additional.workTime / 60))} targetValue={HumanaUtils.filterNaNValue(data.currentStatistics.budget.sum / (data.currentStatistics.budget.workTime / 60))} unit={"€/h"}></BudgetCard>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <DashboardCard title={'Kundenanzahl'} format={'0.0 a'} icon={<IoPeople fontSize={35} />} value={HumanaUtils.filterNaNValue(data.currentStatistics.sales.countConsumer)} valuePrevious={HumanaUtils.filterNaNValue(data.previousStatistics.sales.countConsumer)} unit={''}></DashboardCard>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <DashboardCard title={'Umsatz/Kunde'} format={'0.00 a'} icon={<MdShoppingCart fontSize={35} />} value={HumanaUtils.filterNaNValue(data.currentStatistics.sales.sum / data.currentStatistics.sales.countConsumer)} valuePrevious={HumanaUtils.filterNaNValue(data.previousStatistics.sales.sum / data.previousStatistics.sales.countConsumer)} unit={'€'}></DashboardCard>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <DashboardCard title={'Anteil Barcodes VK'} format={'0.00 a'} icon={<BiBarcode fontSize={35} />} value={HumanaUtils.filterNaNValue(percentageBarcode)} valuePrevious={HumanaUtils.filterNaNValue(percentageBarcodePrevious)} unit={'%'}></DashboardCard>
                        </Grid>
                    </Grid>
                        <Grid container spacing={1} style={{marginTop: '0px'}}>

                        {data.location.type.toLowerCase() != "department" && (
                            <Grid item xs={12} md={6}>
                                <WeekCharCard apiData={data} format={'0 a'} unit={'€'} />
                            </Grid>
                        )}
                        {/*<Grid item xs={12} md={6}>*/}
                        {/*    <ShopCategoryChart apiData={data} />*/}
                        {/*</Grid>*/}
                        <Grid item xs={12} md={6}>
                            <ShopCategoryBarChart apiData={data} format={'0 a'} unit={'€'} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{marginTop: 10}}>
                        <Grid item xs={12} style={{minHeight: '75px', backgroundColor: 'transparent'}}></Grid>
                    </Grid>
                </>
            )}
            {!data.currentStatistics.sales.sum && (
                <div style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    placeContent: 'center',
                    placeItems: 'center'
                }}>
                            <span>Keine Daten vorhanden...</span>
                </div>
            )}
        </>
    )
}