import {Card, CardContent, Grid} from "@mui/material";
import {BarDatum, ResponsiveBar} from "@nivo/bar";
import numeral from "numeral";
import React, {useEffect, useState} from "react";
import {ResponsivePie} from "@nivo/pie";
import {ReportDefaultResponseDTO} from "../../rest-data-provider/customDTOs";
import {format} from "date-fns";

interface ShopCategoryChartProps {
    apiData : ReportDefaultResponseDTO
    format: string
    unit: string
}

export const ShopCategoryBarChart : React.FC<ShopCategoryChartProps> = ({apiData, format, unit}) => {

    const [categories, setCategories] = useState<BarDatum[]>([]);
    const [allSum, setAllSum] = useState(0)

    useEffect(() => {

        const newList = Object.values(apiData.currentStatistics.shopCategories).reduce((items:any[], item: any) => {
            const itemIndex = items.findIndex(i => i.cleanName === item.cleanName)
            if(itemIndex === -1){
                items.push(item);
            } else {
                items[itemIndex].sum += item.sum;
            }
            return items;
        }, []);

        const allSum = newList.reduce( function(a, b){
            return a + b['sum'];
        }, 0);
        setAllSum(allSum);

        const relevantPercentages = newList.reduce(function(a,b) {
            return a + ((b.sum / allSum) * 100);
        }, 0)

        const relevantSum = newList.filter(value => {
            return ((value.sum / allSum) * 100) >= 2;
        }).reduce(function(a,b) {
            return a + b.sum;
        }, 0)

        const segments = newList.filter(value => {
            return ((value.sum / allSum) * 100) >= 2;
        }).map((item) =>{
                const segment : BarDatum  = {id: '', label: '', value: 0};
                // console.log(item)
                segment.id = item.cleanName;
                segment.label = item.cleanName;
                segment.value = item.sum;
                segment[item.cleanName] = item.sum;
                return segment;
            }
        );



        segments.push({
            id:'Sonstige',
            label: 'Sonstige',
            value: 100 - relevantPercentages,
            'Sonstige': allSum - relevantSum,
        })
        segments.sort((a:any,b:any) => {
            return b.value - a.value
        })
        setCategories(segments);

    }, [apiData.currentStatistics.shopCategories]);



    // const data = [
    //     {
    //         "id": "python",
    //         "label": "python",
    //         "value": 418,
    //         "color": "hsl(170, 70%, 50%)"
    //     },
    //     {
    //         "id": "erlang",
    //         "label": "erlang",
    //         "value": 321,
    //         "color": "hsl(243, 70%, 50%)"
    //     },
    //     {
    //         "id": "make",
    //         "label": "make",
    //         "value": 439,
    //         "color": "hsl(328, 70%, 50%)"
    //     },
    //     {
    //         "id": "stylus",
    //         "label": "stylus",
    //         "value": 317,
    //         "color": "hsl(347, 70%, 50%)"
    //     },
    //     {
    //         "id": "lisp",
    //         "label": "lisp",
    //         "value": 185,
    //         "color": "hsl(51, 70%, 50%)"
    //     }
    // ]

    return (
        <Card>
            <CardContent style={{padding: "5px 0px 5px 0px"}}>
                <Grid container spacing={1} style={{padding: 0, paddingTop: 10, paddingBottom: 5}}>
                    <Grid item xs={12} style={{padding: 0, paddingLeft: 20}}>
                        <label  style={{fontWeight: 'bold'}}>Verteilung der Shopkategorien</label>
                    </Grid>
                    <Grid item style={{height: '400px'}} xs={12}>
                        <ResponsiveBar
                            data={categories}
                            keys={categories.map(value => value.label) as string[]}
                            indexBy="label"
                            margin={{ top: 20, right:20, bottom: 100, left: 80 }}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            colors={['#AFA3C5']}
                            borderColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        1.6
                                    ]
                                ]
                            }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: -50,
                                legend: 'Katgeorie',
                                legendPosition: 'middle',
                                legendOffset: 80,
                                truncateTickAt: 0,
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Umsatz in €',
                                format:value => numeral(value).format(format),
                                legendPosition: 'middle',
                                legendOffset: -65,
                                truncateTickAt: 0
                            }}
                            labelSkipWidth={7}
                            labelSkipHeight={5}
                            label={datum => {
                                if(datum.value) {
                                    return `${((datum.value / allSum) * 100).toFixed(0)}%`
                                }
                                return '0%'

                            }}
                            labelTextColor={'#3a0139'}
                            // legends={[
                            //     {
                            //         dataFrom: 'keys',
                            //         anchor: 'bottom-right',
                            //         direction: 'column',
                            //         justify: false,
                            //         translateX: 120,
                            //         translateY: 0,
                            //         itemsSpacing: 2,
                            //         itemWidth: 100,
                            //         itemHeight: 20,
                            //         itemDirection: 'left-to-right',
                            //         itemOpacity: 0.85,
                            //         symbolSize: 20,
                            //         effects: [
                            //             {
                            //                 on: 'hover',
                            //                 style: {
                            //                     itemOpacity: 1
                            //                 }
                            //             }
                            //         ]
                            //     }
                            // ]}
                            role="application"
                            ariaLabel="Nivo bar chart demo"
                            barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}