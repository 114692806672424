import {
    GridActionsCellItem,
    GridApiContext,
    GridColTypeDef,
    GridRowId,
    GridRowModes, GridRowModesModel,
    GridRowParams, GridSingleSelectColDef, GridValueGetterParams, GridValueSetterParams
} from "@mui/x-data-grid";
import {getCellClassName, GridEditDateCell} from "./GridComponents";
import {differenceInDays, format, parseISO} from "date-fns";
import React from "react";
import {IDepartment, IUser} from "../../../rest-data-provider/models";
import {ApiError} from "../error-handling/ApiError";
import { GridApi } from '@mui/x-data-grid'
import {IDelivery, IGenericCategory, IWorkTime} from "../model/model";
import {Cancel, Delete, EditRounded, Save} from "@mui/icons-material";
import {PiAsteriskSimpleFill} from "react-icons/pi";
import {FiPlusCircle} from "react-icons/fi";
import {Theme, Tooltip} from "@mui/material";
import {WorkTimeDTO} from "../model/WorkTimeDTO";
import {ErrorHandler} from "../error-handling/ApiErrorHook";
import {ConfirmOptions} from "material-ui-confirm";
import {BaseExternalData} from "../model/BaseExternalData";
import {HttpError, UseCustomMutationReturnType, UseLoadingOvertimeReturnType} from "@refinedev/core";
import {DeliveryDTO} from "../model/DeliveryDTO";
import {TbArrowsSplit} from "react-icons/tb";


type ErrorHandlingTools = {addError: (key: string, apiErrors: ApiError[]) => void, deleteError: (key: string) => void,  errors: Map<string, ApiError[]> }

const dayErrorHandler = (rowId: GridRowId, hasError: boolean, {errors, addError, deleteError}: ErrorHandlingTools ) => {
    let lErrors = errors.get(`${rowId}`);
    if(hasError) {
        const apiError =  new ApiError('day', 'MinMax Constraint');
        if(lErrors) {
            lErrors = lErrors.filter(value => value.field !== 'day')
            lErrors.push(apiError);
        } else {
            lErrors = [apiError];
        }
    } else {
        if(lErrors) {
            lErrors = lErrors.filter(value => value.field !== 'day');
        }
    }
    if(lErrors && lErrors.length > 0) {
        addError(`${rowId}`, lErrors);
    } else {
        deleteError(`${rowId}`);
    }
}

export function dateColumType(minDate : Date|undefined, maxDate : Date|undefined, errorHandlingTools: ErrorHandlingTools) {
    const dateColumnType: GridColTypeDef<Date|null, string> = {
        type: 'date',
        renderEditCell: (params) => {
            return <GridEditDateCell minDate={minDate} maxDate={maxDate} {...params} />;
        },
        preProcessEditCellProps: (params) => {
            let error = false;
            if(params.hasChanged) {
                const date = (params.props.value)
                if(date instanceof Date && minDate){
                    error = differenceInDays(minDate, date) > 0;
                }
                if(date instanceof Date && maxDate && !error){
                    error = differenceInDays(maxDate, date) < 0;
                }

                if(errorHandlingTools && date instanceof Date && (minDate || maxDate)) {
                    dayErrorHandler(params.id, error, errorHandlingTools);
                }
                console.log('has Error in Day', error)
                return {...params.props, error: error};
            }
            return params.props;
        },
        filterable: false,
        sortComparator: (v1, v2, cellParams1, cellParams2) => {
            const api = cellParams1.api as GridApi;
            const row1 = api.getRow<IWorkTime>(cellParams1.id)
            const row2 = api.getRow<IWorkTime>(cellParams2.id)

            if(row1 && !row1.id) {
                return 1
            }
            if(row2 && !row2.id) {
                return -1
            }

            if (!v1) {
                return 1
            }
            if (!v2) {
                return -1;
            }
            return v1.getTime() - v2.getTime()
        }, valueFormatter: params => {
            if (params.value) {
                return format(params.value, "dd.MM.yyyy");
            } else {
                return ''
            }

        },
    }
    return dateColumnType;
}
export const createdAtColumnType: GridColTypeDef<Date, string> = {
    headerName: 'Erstellt am',
    type: 'date',
    width: 120,
    align: 'center',
    headerAlign: 'center',
    sortable: true,
    hideSortIcons: true,
    valueGetter: ({value}) => {
        if(!value) {
            return new Date()
        }
        return new Date(value)
    },
    valueFormatter: params => {
        return format(params.value, "dd.MM.yyyy");
    },
    renderCell: params => {
        return (
            <span style={{color: "#999"}}>{params.formattedValue}</span>
        )
    }
}

export const updatedAtColumnType: GridColTypeDef<Date, string> = {
    headerName: 'Geändert am',
    type: 'date',
    width:120,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    valueGetter: ({value}) => {
        if(!value) {
            return new Date()
        }
        return new Date(value)
    },
    valueFormatter: params => {
        return format(params.value, "dd.MM.yyyy")
    },
    renderCell: params => {
        return (
            <span style={{color: "#999"}}>{params.formattedValue}</span>
        )
    }
}

export const updatedByColumnType: GridColTypeDef<IUser, string> = {
    headerName: 'Geändert von',
    width: 150,
    align: 'center',
    headerAlign: 'center',
    type: 'string',
    sortable: false,
    renderCell: params => {
        let username = ''
        if(!params.value) {
            username = 'Alte KassenDB'
        } else {
            username = `${params.value.firstName} ${params.value.lastName}`
        }
        let dateStr = '-'
        console.log(params.row.createdAt);
        if(params.row.createdAt) {
            dateStr = format(parseISO(params.row.createdAt), 'dd.MM.yyyy HH:mm');
        }
        if(params.row.id) {
            return (
                <Tooltip title={<>Zuletzt geändert von <b>{username}</b> am <b>{dateStr}</b></>}>
                    <span style={{color: '#999'}}>{username}</span>
                </Tooltip>

            )
        }
        return (
            <span style={{color: '#999'}}>{username}</span>
        )

    }
}

export function departmentTypeColumn(selectableDepartments: IDepartment[] = [], allDepartments:IDepartment[], fieldName:string) : GridSingleSelectColDef<any, number, string> {
    return {
        field: fieldName,
        headerName: 'Abteilung',
        width: 140,
        editable: true,
        type: 'singleSelect',
        sortable: false,
        valueOptions: selectableDepartments,
        getOptionValue: (value: any) => value.id,
        getOptionLabel: (value: any) => value.name,
        valueFormatter: (params) => {
            const department = allDepartments.find(dep => dep.id === params.value);
            return department?.name ? department.name : '-'
        },
        valueGetter: (params: GridValueGetterParams<any, any >) => {
            return params.value.id
        },
        valueSetter: (params: GridValueSetterParams<any, any>) => {
            const department = allDepartments.find(dep => dep.id === params.value);
            if (department) {
                params.row.department = department;
            }
            return params.row;
        }
    }
}

export function  iconTypeColumn(theme: Theme) : GridColTypeDef<any, string> {
    return {
        headerName: '',
        width: 50,
        align: 'center',
        renderCell: params => {
            if(params.row instanceof DeliveryDTO) {
                if(params.row.wasSplit) {
                    return (<TbArrowsSplit color={theme.palette.warning.main} size={20} />)
                }
            }
            if(String(params.id).startsWith('new') && !params.row.id) {
                return (
                    <PiAsteriskSimpleFill size={20} color={theme.palette.primary.main} style={{color: "primary.main"}}  />
                )
            } else if (params.row.uuid) {
                return (<FiPlusCircle color={theme.palette.primary.main} size={15} />)
            }
        }
    }
}

export function categoryColumn(errorHandler: ErrorHandler, categories: IGenericCategory[]) : GridSingleSelectColDef<any, number, string> {
    return {
        field: 'category',
        headerName: 'Kategorie',
        width: 200,
        editable: true,
        sortable: false,
        cellClassName: params => getCellClassName(params, errorHandler.errors),
        type: 'singleSelect',
        valueOptions: categories.filter(value => !value.disabled),
        getOptionValue: (value: any) => value.id,
        getOptionLabel: (value: any) => `${value.group} - ${value.name}`,
        valueFormatter: (params) => {
            const category = categories.find(dep => dep.id === params.value);
            return category?.name ? category.name : '-'
        },
        valueGetter: (params: GridValueGetterParams<any, any>) => {
            console.log('Params Value', params.value)
            if(params.value && params.value.id) {
                return params.value.id
            }
            return '';
        },
        valueSetter: (params: GridValueSetterParams<any, any>) => {
            const category = categories.find(dep => dep.id === params.value);
            if (category) {
                params.row.category = category;
            }
            return params.row;
        }
    }
}
export function actionsColumType(rowModesModel:GridRowModesModel|undefined, errorHandler: ErrorHandler,
                                 confirm: (options?: ConfirmOptions|undefined) => Promise<void>,
                                 setRowModesModel: (model: GridRowModesModel) => void, rows: BaseExternalData[],
                                 setRows: (rows: any[]) => void, apiUrl: string,
                                 mutate: UseCustomMutationReturnType<BaseExternalData> & UseLoadingOvertimeReturnType) {



    const editObject = (params: GridRowParams)  => {
        setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.Edit }});
    };

    const saveObject = (params: GridRowParams)  => {
        setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
    };

    const cancelEditObject = (params: GridRowParams)    => {
        setRowModesModel({
            ...rowModesModel,
            [params.id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
        if (String(params.id).startsWith('new')) {
            setRows(rows.filter((row) => row.id));
        }
        errorHandler.deleteError(`${params.id}`);
    }

    const deleteObject = async (objectToDelete : BaseExternalData) => {
        await mutate.mutateAsync({
                method: "delete",
                url: `${apiUrl}/${objectToDelete.id}`,
                values: {}
            },
            {
                onSuccess: data => {
                    setRows(rows.filter(row => row.id !== objectToDelete.id))
                }
            });
    }

    const actionsColumns = {
        headerName: 'Aktionen',
        type: 'actions',
        sortable: false,
        getActions: (params: GridRowParams) => {
            const isInEditMode = rowModesModel && rowModesModel[params.id]?.mode === GridRowModes.Edit;
            if(isInEditMode) {
                return (
                    [
                        <GridActionsCellItem icon={<Save color={'primary'} />} onClick={() => {saveObject(params)}} label="Speichern" />,
                        ... params.row.id ? [<GridActionsCellItem  icon={<Cancel color={'error'} />} onClick={() => {cancelEditObject(params)}} label="Abbrechen" />] : [],
                    ]
                )
            } else {
                return (
                    [
                        <GridActionsCellItem icon={<EditRounded />} onClick={() => {
                            editObject(params)
                        }} label="Editieren" />,
                        <GridActionsCellItem icon={<Delete color={'error'} />} label="Löschen" onClick={() => {
                            confirm({
                                content: params.row.generateDeleteConfirmation(),
                                title: 'Eintrag löschen',
                                cancellationText: 'Nein',
                                confirmationText: 'Ja'
                            }).then(() => {
                                deleteObject(params.row);
                            });

                        }} />,
                    ]
                )
            }
        }
    }
    return actionsColumns;
}

function isValidDate(d : Date) {
    return !isNaN(d.valueOf());
}