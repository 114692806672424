import {Button, Chip, Drawer, Grid, ListItemButton, ListItemText} from "@mui/material";
import List from "@mui/material/List";
import {IDepartment, ILocation, IShop, IUnit} from "../../../rest-data-provider/models";
import React, {useContext, useEffect, useState} from "react";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {TiChevronLeft} from "react-icons/ti";
import {HumanaUtils} from "../../../utils/HumanaUtils";
import {useList} from "@refinedev/core";
import {ImLocation} from "react-icons/im";
import {LocationContext} from "./LocationContext";


interface LocationChooserProps {
    open: boolean,
    onClose: () => void,
    didSelectLocation: (location: ILocation) => void
}

enum LocationLevel {
    Unit,
    Shop,
    Department
}

export const LocationChooser : React.FC<LocationChooserProps> = ({ open, onClose, didSelectLocation}) => {

    const [xxxLocation] = useContext(LocationContext);


    const [buttonText, setButtonText] = useState(<span>Übernehmen</span>);
    const [headingText, setHeadingText] = useState<string>('')
    const [detailButtonText, setDetailButtonText] = useState('')

    const [locations, setLocations] = useState<ILocation[]>([])
    const [locationPath, setLocationPath] = useState<ILocation[]>([])
    const [selectedLocation, setSelectedLocation] = useState<ILocation|null>(null)
    const [currentLocations, setCurrentLocations] = useState<ILocation[]>([])

    const [units, setUnits] = useState<IUnit[]>([]);
    const unitResponse = useList<IUnit>({resource: 'locations'});
    if (unitResponse.data && units.length === 0) {
        setUnits(unitResponse.data.data);
    }


    // useEffect(() => {
    //     chooseLocation(xxxLocation, true)
    // }, [xxxLocation]);



    const findInUnit  = (cachedLocation : ILocation, units : ILocation[]) =>  {
        for (const unit of units) {
            if(cachedLocation.id === unit.id && cachedLocation.type === 'unit') {
                return unit;
            }
            for (const shop of unit.children) {
                if(cachedLocation.id === shop.id && cachedLocation.type === 'shop') {
                    return shop;
                }
                for (const department of shop.children) {
                    if(cachedLocation.id === department.id && cachedLocation.type === 'department') {
                        return department
                    }
                }
            }
        }
    }

    useEffect(() => {
        setLocations(HumanaUtils.transformUnitsToLocations(units))


    }, [units]);

    useEffect(() => {
        setCurrentLocations(locations)
        if(xxxLocation && locations) {
            const location = findInUnit(xxxLocation, locations);
            if(location) {
                chooseLocation(location, true);
            }
        }
    }, [locations]);

    useEffect(() => {
        setHeadingText(`${titleForLocationLevel(locationPath.length)} auswählen`)
        console.log('location path changed')
    }, [locationPath]);

    useEffect(() => {
        if(selectedLocation) {
            setButtonText(<span><b>{selectedLocation.name}</b> auswählen</span>)
            setDetailButtonText(titleForLocationLevelPlural(locationPath.length+1))
        } else {
            setButtonText(<span>{titleForLocationLevel(locationPath.length)} auswählen...</span>)
        }
    }, [selectedLocation]);

    const titleForLocationLevelPlural = (level : LocationLevel) => {
        switch (level) {
            case LocationLevel.Unit: return 'Gebiete';
            case LocationLevel.Shop: return 'Shops';
            case LocationLevel.Department: return 'Abteilungen';
        }
    }

    useEffect(() => {
        console.log(currentLocations);
    }, [currentLocations]);
    const titleForLocationLevel = (level : LocationLevel) => {
        switch (level) {
            case LocationLevel.Unit: return 'Gebiet';
            case LocationLevel.Shop: return 'Shop';
            case LocationLevel.Department: return 'Abteilung';
        }
    }

    const pushLocation = (location : ILocation) => {
        if(location.type === "unit") {
            setLocationPath([location]);
        } else {
            setLocationPath([...locationPath, location]);
        }
        setCurrentLocations(location.children);
        setSelectedLocation(null)
    }

    const popLocation = () => {
        const newLocationPath = locationPath;
        newLocationPath.pop();
        setLocationPath([...newLocationPath]);
        setSelectedLocation(null)
        if(newLocationPath.length > 0 ) {
            setCurrentLocations(newLocationPath[newLocationPath.length-1].children);
        } else {
            setCurrentLocations(locations)
        }
    }

    const generateLocationPath = (location : ILocation|null, locations : ILocation[] ) : ILocation[] => {
        if(location) {
            locations.unshift(location);
            if(location.parent) {
                return generateLocationPath(location.parent, locations);
            }
        }
        return locations;
    }

    const chooseLocation = (location : ILocation, noPropagation = false) => {
        // alert(location.parent?.children.length)
        setLocationPath(generateLocationPath(location.parent, []));
        console.log(location.parent?.children)
        setCurrentLocations(location.parent?.children ? location.parent.children : locations );
        setSelectedLocation(null);
        if(!noPropagation) {
            didSelectLocation(location);
        }

    }

    const currentStep = () => {
        return locationPath.length;
    }

    const isSelectedLocation = (location : ILocation) => {
        if(selectedLocation && selectedLocation.type === location.type && selectedLocation.id === location.id) {
            return true;
        }
        return false;
    }

    return (
        <Drawer anchor={'top'} open={open} onClose={onClose} PaperProps={{sx: {
            maxHeight: '400px',
            maxWidth: '400px',
            marginLeft: 'auto',
            marginRight: 'auto'
        }}}>
            <Grid container style={{placeContent:'center', margin: 0}}>
                <Grid container item xs={12} sm={12} md={12} style={{padding: 15, margin: 0}}>
                    { currentStep() > 0 && (
                        <Grid item>
                            <IconButton onClick={() => {
                                popLocation();
                            }}>
                                <TiChevronLeft></TiChevronLeft>
                            </IconButton>
                        </Grid>
                    )}
                    <Grid item>
                        <Typography style={{paddingLeft: currentStep() > 0 ? '0px' : '14px', lineHeight: '40px', height: '40px', verticalAlign:'center', textAlign: 'center'}} fontSize={'16px'} fontWeight={'bold'} component={'span'}>
                           {headingText}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <List>
                            {currentLocations.map(value => {
                                const isSelected = isSelectedLocation(value);
                                return (
                                    <ListItemButton key={value.id} disableRipple={isSelected} style={{backgroundColor: isSelected ? '#dde7db' : '', borderRadius: '10px'}} onClick={() => {
                                        if(isSelected) {
                                            setSelectedLocation(null)
                                        } else {
                                            setSelectedLocation(value);
                                        }
                                    }}>
                                        <ListItemText>
                                            {value.name}
                                        </ListItemText>
                                        {isSelected && value.children.length > 0 ?
                                            <Button endIcon={<ChevronRight></ChevronRight>} variant={'contained'} size={'small'} onClick={(evt) => {
                                                pushLocation(value)
                                                evt.stopPropagation()
                                            }}>{detailButtonText}</Button>
                                            : value.children.length === 0 ? <></> :
                                            <Chip label={`${value.children.length}`}></Chip>
                                        }
                                    </ListItemButton>
                                )
                            })}
                        </List>
                    </Grid>
                    <Button disabled={!selectedLocation} style={{position: 'sticky', bottom: '10px'}} variant={'contained'} fullWidth={true} onClick={() => {
                        if(selectedLocation) {
                            chooseLocation(selectedLocation);
                        }
                    }}>{buttonText}</Button>
                </Grid>
            </Grid>
        </Drawer>
    )
}