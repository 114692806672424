import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import "./i18n";
import {setDefaultOptions} from "date-fns";
import { de } from 'date-fns/locale'
import * as numeral from "numeral";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

setDefaultOptions({locale: de})


numeral.register('locale', 'de-custom', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'Tsd.',
        million: 'Mio.',
        billion: 'Bio.',
        trillion: 'Tri.'
    },
    ordinal: function (number) {
        return '.';
    },
    currency: {
        symbol: '€'
    }
});

numeral.locale('de-custom');

root.render(
  <React.StrictMode>
    <React.Suspense fallback="loading">
      <App />
    </React.Suspense>
  </React.StrictMode>
);
