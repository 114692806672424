import {Authenticated, Refine, useTranslate} from "@refinedev/core";
import {RefineKbar, RefineKbarProvider} from "@refinedev/kbar";

import {
    Breadcrumb,
    ErrorComponent, LightTheme,
    RefineSnackbarProvider,
    ThemedLayoutV2, ThemedSiderV2, ThemedTitleV2, useNotificationProvider,
} from "@refinedev/mui";

import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import routerBindings, {
    CatchAllNavigate,
    DocumentTitleHandler,
    NavigateToResource,
    UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import {useTranslation} from "react-i18next";
import {BrowserRouter, Outlet, Route, Routes} from "react-router-dom";
import {authProvider} from "./authProvider";
import {Header} from "./components";
import {ForgotPassword} from "./pages/forgotPassword";
import {Login} from "./pages/login";
import {Register} from "./pages/register";
import {createTheme, ThemeProvider} from "@mui/material";
import {dataProvider} from "./rest-data-provider";
import {UserList} from "./pages/users/list";
import {UserCreateEdit} from "./pages/users/createEdit";
import Typography from "@mui/material/Typography";
import {HumanaAccessControlProvider} from "./AccessControlProvider";
import {FaTruckLoading, FaUsers} from "react-icons/fa";
import {FaGear} from "react-icons/fa6";
import {UserShow} from "./pages/users/show";
import {RiDashboard3Fill} from "react-icons/ri";
import {DashboardSales} from "./pages/dashboard/dashboardSales";
import {LocationAware} from "./components/custom/LocationChooser/LocationContext";
import {AppLogo} from "./components/custom/applogo";
import {ExternalDataAdmin} from "./pages/external-data-admin";
import {ConfirmProvider} from "material-ui-confirm";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import { de as locale } from 'date-fns/locale';
import { deDE } from '@mui/x-data-grid/locales';
import {ExternalData} from "./pages/external-data";

function App() {
    const {t, i18n} = useTranslation();

    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };


    const overriddenLightTheme = createTheme({
        ...LightTheme,
        palette: {
            ...LightTheme.palette,
            primary: {
                main: "#3A6048",
                light: '#487556',
                dark: '#2c503c',
                contrastText: "#fff"
            },
            secondary: {
                main: "#3A6048",
                contrastText: "#fff"
            },
        },
    }, deDE);




    return (
        <ThemeProvider theme={overriddenLightTheme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                <ConfirmProvider>
                <BrowserRouter>
                {/*<GitHubBanner />*/}
                <RefineKbarProvider>
                    <CssBaseline/>
                    <GlobalStyles styles={{html: {WebkitFontSmoothing: "auto"}}}/>
                    <RefineSnackbarProvider>
                        <Refine
                            dataProvider={dataProvider(import.meta.env.VITE_API_BASE_URL)}
                            notificationProvider={useNotificationProvider}
                            routerProvider={routerBindings}
                            authProvider={authProvider}
                            i18nProvider={i18nProvider}
                            accessControlProvider={HumanaAccessControlProvider}
                            resources={[
                                {
                                    name: "dashboard",
                                    list: "/dashboard-sales",
                                    meta: {
                                        label: "Dashboard",
                                        icon: <RiDashboard3Fill></RiDashboard3Fill>
                                    }
                                },
                                {
                                    name: "external-data",
                                    list: "/external-data",
                                    meta: {
                                        label: t('external-data.external-data'),
                                        icon: <FaTruckLoading />
                                    },
                                },
                                {
                                    name: "users",
                                    list: "/users",
                                    create: "/users/create",
                                    edit: "/users/edit/:id",
                                    show: "/users/show/:id",
                                    meta: {
                                        canDelete: true,
                                        label: t('users.users'),
                                        parent: 'administration',
                                        icon: <FaUsers></FaUsers>
                                    },
                                },
                                {
                                    name: "external-data-admin",
                                    list: "/external-data-admin",
                                    meta: {
                                        label: t('external-data-admin.external-data-admin'),
                                        parent: 'administration',
                                        icon: <FaTruckLoading />
                                    },
                                },
                                {
                                    name: 'administration',
                                    meta: {
                                        icon: <FaGear></FaGear>
                                    }
                                },
                                {
                                    name: "dashboard",
                                    list: "/",
                                    meta: {
                                        hide: true,
                                    }
                                }
                            ]}
                            options={{
                                syncWithLocation: true,
                                warnWhenUnsavedChanges: true,
                                breadcrumb: <Breadcrumb hideIcons={true} />
                            }}
                        >
                            <LocationAware key={'location.context'}>
                                <Routes>
                                    <Route
                                        element={
                                            <Authenticated v3LegacyAuthProviderCompatible={true} fallback={<CatchAllNavigate to="/login"/>} key={'auth'}>
                                                <ThemedLayoutV2 Header={() => <Header sticky/>} Sider={() => {
                                                    return (
                                                        <ThemedSiderV2
                                                            Title={({ collapsed }) => (
                                                                <ThemedTitleV2  collapsed={collapsed}
                                                                                icon={AppLogo}
                                                                                text={<div style={{whiteSpace: 'nowrap'}}><Typography component={"label"} fontSize={18}>Humana</Typography> <Typography fontSize={18} component={'label'} fontWeight={'bold'}>Portal</Typography></div>} />
                                                            )}
                                                            render={({ items, logout, collapsed }) => {
                                                                return (
                                                                    <>
                                                                        {items}
                                                                        {/*{logout}*/}
                                                                    </>
                                                                );
                                                            }}
                                                        />
                                                    )}}
                                                >
                                                    <Outlet/>
                                                </ThemedLayoutV2>
                                            </Authenticated>
                                        }
                                    >
                                        <Route index path="/" element={<DashboardSales />} />
                                        <Route index path="/dashboard-sales" element={<DashboardSales />} />
                                        <Route path="/external-data">
                                            <Route index element={<ExternalData/>}></Route>
                                        </Route>
                                        <Route path="/users">
                                            <Route index element={<UserList/>}/>
                                            <Route path="create" element={<UserCreateEdit/>}/>
                                            <Route path="edit/:id" element={<UserCreateEdit/>}/>
                                            <Route path="show/:id" element={<UserShow/>}/>
                                        </Route>
                                        <Route path="/external-data-admin">
                                            <Route index element={<ExternalDataAdmin/>}></Route>
                                        </Route>
                                        <Route path="*" element={<ErrorComponent/>}/>
                                    </Route>
                                    <Route
                                        element={
                                            <Authenticated v3LegacyAuthProviderCompatible={true} fallback={<Outlet/>} key={'auth'}>
                                                <NavigateToResource/>
                                            </Authenticated>
                                        }
                                    >
                                        <Route path="/login" element={<Login/>}/>
                                        <Route path="/register" element={<Register/>}/>
                                        <Route path="/forgot-password" element={<ForgotPassword/>}/>
                                    </Route>
                                </Routes>
                            </LocationAware>

                            <RefineKbar/>
                            <UnsavedChangesNotifier/>
                            <DocumentTitleHandler/>
                        </Refine>
                    </RefineSnackbarProvider>
                </RefineKbarProvider>
            </BrowserRouter>
            </ConfirmProvider>
            </LocalizationProvider>
        </ThemeProvider>

    );
}

export default App;
