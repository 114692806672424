import React, {createContext, PropsWithChildren, useEffect, useState} from "react";
import {ILocation} from "../../../rest-data-provider/models";
import useLocalStorageState from "use-local-storage-state";

const locationContextDefault = {
    location: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setLocation: (location: ILocation) => {} // noop default callback
};
export const LocationContext= createContext<any>(locationContextDefault);


export const LocationAware : React.FC<React.PropsWithChildren> = (props: PropsWithChildren) => {

    const [location, setLocation] = useLocalStorageState<ILocation>('location', {
        serializer: {
            stringify: value => {
                const location2Serialize = structuredClone(value) as ILocation;


                location2Serialize.parent = null;
                location2Serialize.children = [];

                return JSON.stringify(location2Serialize);
            },
            parse: value => {
              return JSON.parse(value);
            }
        }

    })

    useEffect(() => {
        console.log(location)
    }, [location])


    return (
       <LocationContext.Provider value={[location, setLocation]}>
           {props.children }
       </LocationContext.Provider>
    )
}
