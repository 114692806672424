import {Card, CardContent, Grid} from "@mui/material";
import React, {ReactElement, useEffect, useState} from "react";
import "./BudgetCard.css"
import numeral from "numeral";
import Stack from "@mui/material/Stack";
import {HumanaUtils} from "../../utils/HumanaUtils";

interface DashboardCardProps {
    title: string,
    value: number,
    unit: string,
    icon?: ReactElement
    format: string,
    valuePrevious?: number
}
export const DashboardCard : React.FC<DashboardCardProps> = ({value, unit, icon, title, format, valuePrevious}) => {

    const [percentageDifferencePrevious, setPercentageDifferencePrevious] = useState<number>(0)
    const [differencePrevious, setDifferencePrevious] = useState<number>(0)


    useEffect(() => {


        if(valuePrevious) {
            setPercentageDifferencePrevious(
                (value - valuePrevious) / valuePrevious
            );
            setDifferencePrevious(value - valuePrevious);
        }


    }, [value, valuePrevious])

    return (
        <Card>
            <CardContent style={{padding: "5px 0px 5px 0px", minHeight: '126px', maxHeight: '126px', overflow: 'hidden'}}>
                <Grid container spacing={1} style={{padding: 0, paddingTop: 10, paddingBottom: 5}}>
                    <Grid item xs={12} style={{padding: 0, paddingLeft: 20}}>
                        <label  style={{fontWeight: 'bold'}}>{title}</label>
                    </Grid>
                </Grid>
                <Grid container style={{margin: 0, padding: 0, marginLeft: 0, minHeight: 50, overflow: 'hidden' }}>
                    <Grid item style={{width: '60px', display: 'flex', minHeight: 40, maxHeight:60, padding: 0, paddingLeft: 10, paddingRight: 10, margin: 0}}>
                        <div className={`title`} >
                            <>
                                {icon}
                            </>
                        </div>
                    </Grid>
                    <Grid item xs={'auto'}>
                        <div style={{fontSize: '20px', fontWeight: 'bold', lineHeight:'50px'}}>
                            {numeral(value).format(format)} {unit}
                        </div>
                    </Grid>
                </Grid>
                    <Grid container style={{margin: 0, padding: 0, paddingTop: 8, paddingBottom: 8, marginLeft: 0, paddingLeft: 10, maxHeight: 60, overflow: 'hidden' }}>
                        <Grid item>
                            <Stack direction={"row"}>
                                <div
                                    style={{borderRadius: '5px', padding: 0, fontSize: 13, display: 'inline-block'}}>
                                    {valuePrevious !== null && valuePrevious !== 0 && (
                                        <label>
                                            Vgl. Vorjahr:
                                            {unit !== '%' && (
                                                <>
                                                    &nbsp;<label
                                                    className={HumanaUtils.getClassNameForPercentageDifference(percentageDifferencePrevious)}>{numeral(differencePrevious).format(differencePrevious > 0 ? '+0,0 a' : '0,0 a')} {unit}
                                                    &nbsp;({numeral(percentageDifferencePrevious).format(percentageDifferencePrevious > 0 ? '+0 %' : '0 %')})</label>
                                                </>
                                            )}
                                            {unit === '%' && (
                                                <>
                                                    &nbsp;<label
                                                    className={HumanaUtils.getClassNameForPercentageDifference(percentageDifferencePrevious)}>{numeral(percentageDifferencePrevious).format(percentageDifferencePrevious > 0 ? '+0 %' : '0 %')}</label>
                                                </>

                                            )}
                                        </label>
                                    )}
                                    {valuePrevious === 0 && (
                                        <label>(Kein Vorjahreswert)</label>
                                    )}
                                </div>
                            </Stack>
                        </Grid>
                    </Grid>


                {/*<Grid container spacing={1} style={{minHeight: '60px', display: 'flex',  placeItems: 'center' }}>*/}
                {/*    <Grid item xs={titleGridLength} md={2} style={{display: 'flex', placeContent: 'center', placeItems: 'center', flexDirection: 'column'}}>*/}
                {/*        <div className={'title'}>*/}
                {/*                {icon}*/}
                {/*                <p style={{padding: 0, margin: 0, fontWeight: 'bold', marginTop: '3px', textAlign: 'center'}}>{title}</p>*/}
                {/*        </div>*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={12 - titleGridLength} md={10} style={{display: 'flex', placeContent: 'center', placeItems: 'center', flexDirection: 'column'}}>*/}

                {/*    </Grid>*/}
                {/*</Grid>*/}
            </CardContent>
        </Card>
    )
}