import {
    GridCellParams,
    GridFilterInputValueProps, GridRenderCellParams, GridRenderEditCellParams, useGridApiContext
} from "@mui/x-data-grid";
import {DatePicker, DateTimePicker, DateTimeValidationError} from "@mui/x-date-pickers";
import {Button, InputBase, InputBaseProps, TextField, TextFieldProps, Theme} from "@mui/material";
import styled from "@emotion/styled";
import React, {useEffect, useRef, useState} from "react";
import {NumericFormat} from "react-number-format";
import {ApiError} from "../error-handling/ApiError";
import numeral from "numeral";
import {WorkTimeDTO} from "../model/WorkTimeDTO";
import {BaseExternalData} from "../model/BaseExternalData";
import {TbArrowsSplit} from "react-icons/tb";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {CombinedExternalDataDTO} from "../model/model";


const GridEditDateInput = styled(InputBase)({
    fontSize: 'inherit',
    padding: '0 9px',
});

function WrappedGridEditDateInput(props: TextFieldProps) {
    const { InputProps, focused, ...other } = props;
    return (
        <GridEditDateInput fullWidth {...InputProps} {...(other as InputBaseProps)} />
    );
}
interface AdditionalDateFieldProps {
    minDate?: Date|undefined
    maxDate?: Date|undefined
    onError?: (error: DateTimeValidationError) => void
}
export function GridEditDateCell ({
                              id,
                              field,
                              value,
                              colDef,
                            maxDate,
                            minDate,
                            onError
                          }: GridRenderEditCellParams<any, Date | null, string> & AdditionalDateFieldProps) {
    const apiRef = useGridApiContext();

    const Component = colDef.type === 'dateTime' ? DateTimePicker : DatePicker;

    const handleChange = (newValue: unknown) => {
        console.log('Edit Day', id, field, newValue);
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };


    return (
        <Component
            onError={onError}
            value={value}
            autoFocus
            minDate={minDate}
            maxDate={maxDate}
            onChange={handleChange}
            slots={{ textField: WrappedGridEditDateInput }}
        />
    );
}

function GridFilterDateInput(
    props: GridFilterInputValueProps & { showTime?: boolean },
) {
    const { item, showTime, applyValue, apiRef } = props;

    const Component = showTime ? DateTimePicker : DatePicker;

    const handleFilterChange = (newValue: unknown) => {
        applyValue({ ...item, value: newValue });
    };

    return (
        <Component
            value={item.value ? new Date(item.value) : null}
            autoFocus
            label={apiRef.current.getLocaleText('filterPanelInputLabel')}
            slotProps={{
                textField: {
                    variant: 'standard',
                },
                inputAdornment: {
                    sx: {
                        '& .MuiButtonBase-root': {
                            marginRight: -1,
                        },
                    },
                },
            }}
            onChange={handleFilterChange}
        />
    );
}

interface CustomEditNumberCellRendererProps {
    params: GridRenderEditCellParams<BaseExternalData>
    decimalScale: number
}
export const CustomEditNumberCellRenderer : React.FC<CustomEditNumberCellRendererProps> = ({params, decimalScale}) => {

    const [value, setValue] = useState(0);
    const textfieldRef = useRef<HTMLInputElement>();

    useEffect(() => {
        setValue(params.value)
    }, [params.value]);

    useEffect(() => {
        console.log('HAS FOCUS', params.hasFocus)
        if(params.hasFocus) {
            textfieldRef.current!.focus();
        }
    }, [params.hasFocus]);
    return <NumericFormat customInput={InputBase}
                          inputRef={textfieldRef} sx={{fontSize:'14px', padding: '0px 10px', textAlign:'right', input: {textAlign:'right'}}}
                          id={params.id + "-duration"} onFocus={event => event.target.select()}  value={value}
                          decimalSeparator={','} decimalScale={decimalScale} onValueChange={(values, sourceInfo) => {
        if(values.floatValue) {
            setValue(values.floatValue)
            params.api.setEditCellValue({id: params.id, field: params.field, value: values.floatValue, debounceMs: 200});
        }
    }} allowNegative={false} />;
};

interface OptionalCategoryEditCellRenderProps {
    params : GridRenderCellParams,
    initialData: CombinedExternalDataDTO,
    theme: Theme
}
export const OptionalCategoryEditCellRender : React.FC<OptionalCategoryEditCellRenderProps> = ({params, initialData, theme}) => {

    const selectRef = useRef<HTMLInputElement>()

    useEffect(() => {
        if (params.hasFocus && selectRef.current) {
            selectRef.current.focus();
        }
    }, [params.hasFocus]);

    if (params.row.origin.needsCategory) {
        return <>
            <Select inputRef={selectRef} fullWidth>
                {initialData.categories.map(category => {
                    return (
                        <MenuItem value={category.id}>{category.name}</MenuItem>
                    )
                })}
            </Select>
        </>
    } else {
        return <TextField  InputProps={{
            readOnly: true,
        }} inputRef={selectRef} value={'nicht benötigt'}></TextField>
    }
}

export const getCellClassName = (params : GridCellParams<any>, errors: Map<string, ApiError[]>) =>  {
    const error = errors.get(`${params.id}`);
    if(error && error.find(value => value.field === params.field)) {
        return 'cell-error'
    }
    return '';
}

export const formatDuration = (durationInMinutes : number)  => {
    const hours = Math.floor(durationInMinutes / 60)
    const minutes = ((durationInMinutes % 60) / 60)
    return numeral(hours + minutes).format('0.00');
}

