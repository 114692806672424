import React, {useEffect, useState} from "react";
import {DeliveryDTO} from "../model/DeliveryDTO";
import {Chip, Grid, InputBase, InputLabel, ListItem, makeStyles, TextField, useTheme} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import {Delete} from "@mui/icons-material";
import {IGenericCategory} from "../model/model";
import FormControl from "@mui/material/FormControl";
import {NumberFormatBase, NumericFormat} from "react-number-format";
import {ca} from "date-fns/locale";
import {useCustomMutation} from "@refinedev/core";
interface SplitDeliveryLineProps {
    delivery : DeliveryDTO
    isLast: boolean
    categories: IGenericCategory[]
    onChange:(delivery : DeliveryDTO) => void
    onDelete:(delivery: DeliveryDTO) => void
    sequentialNumber: number
}

export const SplitDeliveryLine : React.FC<SplitDeliveryLineProps> = ({delivery, onChange,
                                                                         isLast, categories,
                                                                         onDelete, sequentialNumber}) => {

    const [amount, setAmount] = useState<number|null>(0)
    const [categoryId, setCategoryId] = useState<number|string|null>(null)
    const theme = useTheme();



    useEffect(() => {
        setAmount(delivery.amount)
        if(delivery.category) {
            setCategoryId(delivery.category?.id)
        } else {
            setCategoryId(null)
        }
    }, [delivery]);

    useEffect(() => {
        delivery.amount = amount ? amount : 0;
        const cat =  categories.find((c) => c.id === categoryId);
        if(cat) {
            delivery.category = cat;
        }
        onChange(delivery);
    }, [amount, categoryId]);

    return (
        <ListItem key={delivery.uuid}>
            <Grid container spacing={1}>
                <Grid item style={{lineHeight: '40px'}} xs={1}>
                    <div style={{fontSize: '20px', width: '30px', height: '52px',
                        lineHeight:'52px', marginTop:'2px', textAlign: 'center', borderRadius: '5px',
                        marginRight: '10px', backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText}}>{sequentialNumber}</div>
                </Grid>
                <Grid item xs={4}>
                    {/*<TextField value={amount} onChange={event => {*/}
                    {/*    setAmount(Number.parseFloat(event.target.value))*/}
                    {/*}} label={'Menge in kg'} placeholder={'Menge in kg'} fullWidth size={'medium'}></TextField>*/}
                    <NumericFormat customInput={TextField} label={'Menge in kg'}
                                   sx={{fontSize:'14px', padding: '0px 10px', input: {textAlign:'right'}, 'label': {left: '10px'}}}
                                   id={delivery.uuid + "-duration"} onFocus={event => event.target.select()}  value={amount}
                                   decimalSeparator={','} decimalScale={2} onValueChange={(values, sourceInfo) => {
                        if(values.floatValue) {
                            setAmount(values.floatValue)
                        } else {
                            setAmount(null)
                        }
                    }} allowNegative={false} />
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth size={'medium'}>
                        <InputLabel>Kategorie</InputLabel>
                        <Select value={categoryId} label={'Kategorie'} inputProps={{
                        }} fullWidth onChange={event => {
                            setCategoryId(event.target.value);
                        }}>
                            {categories.filter(value => !value.disabled).map(value => {
                                return <MenuItem value={value.id}>{value.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={1} style={{lineHeight: '50px'}}>
                        <IconButton disabled={sequentialNumber == 1} onClick={() => {
                            onDelete(delivery);
                        }}><Delete></Delete></IconButton>
                </Grid>
            </Grid>
        </ListItem>
    )
}