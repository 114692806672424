import {
    CircularProgress, Fab,Link, useTheme
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useContext, useEffect, useState} from "react";
import {useCustom} from "@refinedev/core";
import {CalendarMonth} from "@mui/icons-material";
import {LocationContext} from "../../components/custom/LocationChooser/LocationContext";
import {reportDefaultRequestDTO, ReportDefaultResponseDTO} from "../../rest-data-provider/customDTOs";
import {MainDashboard} from "./MainDashboard";
import {SimpleDateChooser} from "../../components/custom/DateChooser/SimpleDateChooser";
import {SelectedDateRange} from "../../rest-data-provider/models";
import useLocalStorageState from "use-local-storage-state";
import Select from "@mui/material/Select";

export const DashboardSales : React.FC = () => {

    const [dateChooserOpen, setDateChooserOpen] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState<SelectedDateRange|null>(null);

    const theme = useTheme();
    const [selectedLocation] = useContext(LocationContext);

    const reportQuery = useCustom<ReportDefaultResponseDTO>({url: 'report/default', method: 'post', config: {
            payload: {
                dateRange: selectedDateRange?.dateRange,
                location : selectedLocation
            } as reportDefaultRequestDTO
        }, queryOptions: {
            enabled: !!(selectedDateRange && selectedLocation),
        }})

    const [loadingStatus, setLoadingStatus] = useState<'loading'|'location'|'data'>('loading')

    useEffect(() => {

        if(!selectedDateRange || !selectedLocation) {
            setLoadingStatus('location')
        } else if(reportQuery.isLoading || reportQuery.isFetching || !reportQuery.data) {
            setLoadingStatus('loading')
        } else {
            setLoadingStatus('data')
        }

    }, [reportQuery, reportQuery.data, reportQuery.isLoading, reportQuery.isFetching, selectedDateRange, selectedLocation]);

    return (
        <div style={{height: '100%', position: 'relative'}}>
            {loadingStatus === 'loading' && (
                <div style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    placeContent: 'center',
                    placeItems: 'center'
                }}>
                    <CircularProgress size={70}></CircularProgress>
                    <Typography variant={"subtitle1"} component={"label"} style={{marginTop: 10}}>Lade
                        Daten...</Typography>
                </div>
            )}
            {loadingStatus === 'data' && (
                <MainDashboard data={reportQuery!.data!.data}></MainDashboard>
            )}

            {loadingStatus === 'location' &&
            (
            <div style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                placeContent: 'center',
                placeItems: 'center'
            }}>
                <span>Bitte <Link onClick={() => {
                    console.log('test')
                }}><b>Location</b></Link> und <Link onClick={() => {
                    setDateChooserOpen(true)
                }}><b> Zeitraum </b></Link> auswählen</span>
            </div>
            )}

            <SimpleDateChooser didSelectRange={dateRange => {
                setSelectedDateRange(dateRange);
                setDateChooserOpen(false)
            }} maxYear={new Date().getFullYear()} open={dateChooserOpen} onClose={() => {
                setDateChooserOpen(false);
            }}></SimpleDateChooser>
            <Fab color={'primary'} variant="extended" sx={{
                position: 'fixed', bottom: 20, right: 20, "&:hover": {
                    // backgroundColor: 'red'
                }
            }} onClick={() => {
                setDateChooserOpen(true)
            }}>
                {selectedDateRange ? selectedDateRange?.title : '-'}
                {/*{selectedDateRange?.from.toLocaleDateString()} - {selectedDateRange?.to.toLocaleDateString()}*/}
                <CalendarMonth sx={{ml: 1}}/>
            </Fab>
        </div>
    );
}