import React, {useEffect, useState} from "react";
import {
    GridColDef,
    GridRowModes,
    GridRowModesModel,
    GridValueGetterParams, GridValueSetterParams,
    useGridApiRef,
} from "@mui/x-data-grid";
import {ExternalDataProps, IGenericCategory, IRestock} from "./model/model";
import {useCustomMutation} from "@refinedev/core";
import './external-data-admin.css'
import {useConfirm} from "material-ui-confirm";
import {
    actionsColumType,
    createdAtColumnType,
    dateColumType, departmentTypeColumn,
    iconTypeColumn,
    updatedAtColumnType,
    updatedByColumnType
} from "./external-data-grid/GridCustomColumns";
import {useTheme} from '@mui/material/styles';
import {
    CustomEditNumberCellRenderer,
    formatDuration,
    getCellClassName
} from "./external-data-grid/GridComponents";
import {useApiErrorHandling} from "./error-handling/ApiErrorHook";
import {WorkTimeDTO} from "./model/WorkTimeDTO";
import {ExternalDataGrid} from "./external-data-grid/ExternalDataGrid";
import {BaseExternalData} from "./model/BaseExternalData";
import {RestockDTO} from "./model/RestockDTO";
import {validate_async} from "email-validator";

export const GenericRestock : React.FC<ExternalDataProps> = ({requestParameters, onUpdatedSummary, currentUser, initialData, apiUrl}) => {

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>();
    const [rows, setRows] = useState<RestockDTO[]>([]);
    const [nextNewRestock, setNextNewRestock] = useState<RestockDTO>();
    const [summaryText, setSummaryText] = useState<string>('')

    const apiRef = useGridApiRef()
    const theme = useTheme();

    const {errorHandlers, errors, setErrors} = useApiErrorHandling()
    const confirm = useConfirm();
    const mutation = useCustomMutation<BaseExternalData>({});

    useEffect(() => {
        if(initialData.restock) {
            setRows(initialData.restock);
            let currentRestock : RestockDTO|null = null;
            if(nextNewRestock) {
                nextNewRestock.day = requestParameters.calenderWeek!.to
                nextNewRestock.department = requestParameters.shop!.departments[0]
                currentRestock = nextNewRestock.clone();
                setNextNewRestock(currentRestock);
                apiRef.current.setEditCellValue(
                    {id: nextNewRestock.generateRowId(requestParameters.shop), field:'day', value: requestParameters.calenderWeek!.to}
                );
                apiRef.current.setEditCellValue(
                    {id: nextNewRestock.generateRowId(requestParameters.shop), field:'department', value: requestParameters.shop!.departments[0].id}
                );
                apiRef.current.setEditCellValue(
                    {id: nextNewRestock.generateRowId(requestParameters.shop), field:'count', value: '0'}
                )
                apiRef.current.setEditCellValue(
                    {id: nextNewRestock.generateRowId(requestParameters.shop), field:'category', value: initialData.categories.filter(value => !value.disabled)[0].id}
                )
                setErrors(new Map());

                setRows([...initialData.restock, currentRestock]);
                setRowModesModel(
                    {[`new-${currentRestock.uuid}`]: { mode: GridRowModes.Edit, fieldToFocus: 'count' }}
                );
            } else {
                currentRestock = RestockDTO.createDummy(requestParameters.shop!.departments[0], requestParameters.calenderWeek!.to,null, currentUser)
                setNextNewRestock(currentRestock);
                setRows([...initialData.restock, currentRestock]);
                setRowModesModel(
                    {[`new-${currentRestock.uuid}`]: { mode: GridRowModes.Edit, fieldToFocus: 'count' }}
                );
            }
            setTimeout(() => {
                apiRef.current.scrollToIndexes({
                    rowIndex: apiRef.current.getRowsCount()-1
                })
                if(currentRestock) {
                    apiRef.current.setCellFocus(currentRestock.generateRowId(requestParameters.shop), 'count')
                }

            }, 300)
        }
    }, [initialData, requestParameters]);

    useEffect(() => {
        // alert('yes')
        const summary = RestockDTO.generateSummary(rows)
        onUpdatedSummary(summary)
        setSummaryText(summary);

        if(nextNewRestock && nextNewRestock.uuid && rowModesModel) {
            if(!rowModesModel[nextNewRestock.uuid] || rowModesModel[nextNewRestock.uuid].mode !== GridRowModes.Edit) {
                console.log('no', nextNewRestock.uuid)
                setRowModesModel(
                    {
                        ...rowModesModel,
                        [`new-${nextNewRestock.uuid}`]: {mode: GridRowModes.Edit, fieldToFocus: 'count'}
                    }
                );
            }
        }
    }, [rows]);


    const columns = React.useMemo<GridColDef<WorkTimeDTO>[]> (
        () => [
            {
                field: 'icon',
                ...iconTypeColumn(theme)
            },
            {
                field: 'day',
                headerName: 'Tag',
                width: 150,
                ...dateColumType(requestParameters.calenderWeek?.from, requestParameters.calenderWeek?.to, errorHandlers),
                sortable: true,
                sortingOrder: ["asc"],
                hideSortIcons: true,
                editable: true,
                cellClassName: (params) => getCellClassName(params, errors),
            },
            {
                field: 'count',
                headerName: 'Stk.',
                width: 80,
                editable: true,
                sortable: false,
                align: 'right',
                headerAlign: 'right',
                cellClassName: params => getCellClassName(params, errors),
                renderEditCell: params => {
                    return <CustomEditNumberCellRenderer params={params} decimalScale={0}  />
                }
            },
            {
                ...departmentTypeColumn(requestParameters.shop?.departments, requestParameters.departments, 'department')
            },
            {
                field: 'category',
                headerName: 'Kategorie',
                width: 200,
                editable: true,
                sortable: false,
                cellClassName: params => getCellClassName(params, errors),
                type:'singleSelect',
                valueOptions: params => {
                    return initialData.categories.filter(value => {
                        return !value.disabled
                    }).filter(value => {
                        const department = params.row?.department;
                        if(department?.departmentNumber == 4) { // Vintage
                            return value.vintageOptions == 1 || value.vintageOptions == 3
                        } else { // Nicht Vintage
                            return value.vintageOptions == 2 || value.vintageOptions == 3
                        }
                    });
                },
                getOptionValue: (value: any) => value.id,
                getOptionLabel: (value: IGenericCategory) => `${value.group} - ${value.name}`,
                valueFormatter: (params) => {
                    const category = initialData.categories.find(dep => dep.id === params.value);
                    return category?.name ? category.name : '-'
                },
                valueGetter: (params: GridValueGetterParams<any, any >) => {
                    console.log('Params Value', params.value)
                    if(params.value) {
                        return params.value.id
                    }
                    return null;
                },
                valueSetter: (params: GridValueSetterParams<any, any>) => {
                    const category = initialData.categories.find(dep => dep.id === params.value);
                    if (category) {
                        params.row.category = category;
                    }
                    return params.row;
                }

            },
            // {
            //     field: 'workTimeMinutes',
            //     headerName: 'Arbeitszeit in Std.',
            //     width: 150,
            //     editable: true,
            //     sortable: false,
            //     align: 'right',
            //     cellClassName: params => getCellClassName(params, errors),
            //     valueSetter:params => {
            //         let val = params.value
            //         if (typeof val === 'string') {
            //             val = val.replaceAll(',', '.')
            //         }
            //         params.row.workTimeMinutes = Math.round((+val) * 60)
            //         return params.row;
            //     },
            //     valueGetter: params => {
            //        return formatDuration(params.row.workTimeMinutes);
            //     },
            //     renderEditCell:params => {
            //         return <CustomWorkTimeCellRenderer {...params}  />
            //     }
            // },

            {
                field: 'createdAt',
                ...createdAtColumnType
            },
            {
                field: 'updatedAt',
                ...updatedAtColumnType
            },
            {
                field: 'updatedBy',
                ...updatedByColumnType
            },
            {
                field: 'actions',
                ...actionsColumType(rowModesModel, errorHandlers, confirm, setRowModesModel, rows, setRows, apiUrl, mutation)
            }
        ], [errors, requestParameters, rows, rowModesModel]
    );

    return (
        <>
            {
                <ExternalDataGrid<RestockDTO, IRestock> title={'Einhängevorgänge'} summary={summaryText} requestParameters={requestParameters}
                                  columns={columns} rows={rows} setRows={rows => {
                                      console.log('set the fucking rows', rows)
                                      setRows(rows as RestockDTO[])
                }}
                                  errorHandler={errorHandlers} setRowModesModel={rowModesModel => {
                                    setRowModesModel(rowModesModel)
                                }} rowModesModel={rowModesModel} apiRef={apiRef} cloneMethod={RestockDTO.clone}
                              apiUrl={apiUrl}
                                                        initializeNewDummyObject={(newRow : BaseExternalData) => {
                    if(requestParameters && requestParameters.shop && requestParameters.shop.departments) {
                        const nextNewRestock = RestockDTO.createDummy(requestParameters.shop.departments[0], newRow.day,null, currentUser);
                        console.log('new Dummy!!!!', nextNewRestock)
                        setNextNewRestock(nextNewRestock);
                        console.log('yes Before', nextNewRestock.uuid)
                        apiRef.current.setCellFocus(nextNewRestock.generateRowId(requestParameters.shop), 'count')
                        return nextNewRestock
                    }

                }}

                />
            }

        </>
    )
}