import {ICalenderWeek, IDepartment, IShop, IUser} from "../../../rest-data-provider/models";
import {format, parseISO} from "date-fns";
import {id} from "date-fns/locale";
import {Work} from "@mui/icons-material";
import {v4 as uuidv4} from "uuid";
import {formatDuration} from "../external-data-grid/GridComponents";
import {WorkTimeDTO} from "./WorkTimeDTO";
import {RestockDTO} from "./RestockDTO";
import {BaseExternalData} from "./BaseExternalData";
import {DeliveryDTO} from "./DeliveryDTO";

export interface ExternalDataProps {
    requestParameters: ExternalDataRequest,
    initialData: CombinedExternalDataDTO,
    onUpdatedSummary: (summary:string) => void,
    currentUser: IUser
    apiUrl: string
}

export class ExternalDataRequest {
    public fromDate: string|null
    public toDate: string|null
    public shop: IShop|null
    public departments: IDepartment[]
    public calenderWeek: ICalenderWeek|null

    constructor(calendarWeek: ICalenderWeek|null, shop: IShop|null, departments: IDepartment[]) {
        this.fromDate = calendarWeek?.from ? format(calendarWeek.from, 'yyyy-MM-dd') : null;
        this.toDate = calendarWeek?.to ? format(calendarWeek.to, 'yyyy-MM-dd') : null;
        this.shop = shop ? shop : null;
        this.calenderWeek = calendarWeek;
        this.departments = departments;
    }

    public clone():ExternalDataRequest  {
        return new ExternalDataRequest(this.calenderWeek, this.shop, this.departments);
    }

    public isValid(): boolean {
        return !!this.fromDate && !!this.toDate && !!this.shop && !!this.departments;
    }
}

export class CombinedExternalDataDTO {
    public categories: IGenericCategory[]
    public origins: IDeliveryOrigin[]
    public workTime: WorkTimeDTO[]
    public restock: RestockDTO[]
    public delivery: DeliveryDTO[]

    constructor(categories: IGenericCategory[] = [], origins: IDeliveryOrigin[] = [], workTime: WorkTimeDTO[] = [], restock: RestockDTO[] = [], delivery: DeliveryDTO[] = []) {
        this.categories = categories;
        this.workTime = workTime;
        this.restock = restock;
        this.origins = origins;
        this.delivery = delivery;
    }
}

export interface ICombinedExternalData {
    categories: IGenericCategory[]
    origins: IDeliveryOrigin[]
    workTime: IWorkTime[]
    restock: IRestock[]
    delivery: IDeliveryOrigin[]
}

export interface IDelivery {
    id: number|undefined,
    day: string|undefined,
    amount: number,
    origin: IDeliveryOrigin,
    category: IGenericCategory|null,
    wasSplit?: boolean,
    shop: IShop,
    createdAt?: string|undefined,
    updatedAt?: string|undefined,
    updatedBy?: IUser|undefined,
    uuid?: string|undefined
}

export interface IRestock {
    id: number|undefined,
    day: string|undefined,
    count: number,
    category: IGenericCategory|null,
    department: IDepartment,
    createdAt?: string|undefined,
    updatedAt?: string|undefined,
    updatedBy?: IUser|undefined,
    uuid?: string|undefined
}

export type VintageOptions = 1|2|3;
export interface IGenericCategory {
    id: number,
    name: string,
    group: string,
    sorting: number,
    disabled:boolean,
    vintageOptions: VintageOptions
}

export interface IDeliveryOrigin {
    id: number|undefined,
    name: string,
    sorting: number,
    disabled: boolean
    needsCategory: boolean
}

export interface IWorkTime {
    id: number|undefined,
    day: string|undefined,
    workTimeMinutes: number,
    department: IDepartment,
    createdAt?: string|undefined,
    updatedAt?: string|undefined,
    updatedBy?: IUser|undefined,
    uuid?: string|undefined
}