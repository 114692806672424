import {Card, CardActions, CardContent, Grid} from "@mui/material";
import "./BudgetCard.css"
import {ReactElement, useEffect, useState} from "react";
import {GiFinishLine} from "react-icons/gi";
import Stack from "@mui/material/Stack";
import numeral from 'numeral';
import {HumanaUtils} from "../../utils/HumanaUtils";
import Joyride from "react-joyride";

interface BudgetCardProps {
    title: string,
    value: number,
    targetValue: number,
    valuePrevious: number,
    unit: string,
    icon?: ReactElement,
    format: string,
}
export const BudgetCard : React.FC<BudgetCardProps> = ({value, valuePrevious, targetValue, unit, icon, title, format}) => {

    const [percentage, setPercentage] = useState<number>(0);
    const [percentageDifferencePrevious, setPercentageDifferencePrevious] = useState<number>(0)
    const [differencePrevious, setDifferencePrevious] = useState<number>(0)


    useEffect(() => {
        let pc = (value / targetValue * 100);
        if(isNaN(pc)) {
            pc = 0;
        }
        setPercentage(pc);

        setPercentageDifferencePrevious(
            (value - valuePrevious) / valuePrevious
        );

        setDifferencePrevious(value - valuePrevious);
    }, [value, targetValue, valuePrevious])


    const data = [
        {
            "id": "temp.",
            "ranges": [
               130
            ],
            "measures": [
                130 - percentage
            ],
            "markers": [
                130 - 100
            ]
        }
    ]



    const getPercentageWeighted = (percentage : number) => {
        const p = percentage * 0.8
        if(p > 100) {
            return 100;
        }
        return p
    }

    return (
        <Card>
            <CardContent style={{padding: "5px 0px 5px 0px"}}>
                <Grid container spacing={1} style={{padding: 0, paddingTop: 10, paddingBottom: 5}}>
                    <Grid item xs={12} style={{padding: 0, paddingLeft: 20}}>
                        <label style={{fontWeight: 'bold'}}>{title}</label>
                    </Grid>
                </Grid>
                <Grid container style={{margin: 0, padding: 0, marginLeft: 0, maxHeight: 60, overflow: 'visible' }}>
                    <Grid item style={{width: '60px', display: 'flex', minHeight: 40, maxHeight:60, padding: 0, paddingLeft: 10, paddingRight: 10, margin: 0}}>
                        <div className={`title ${HumanaUtils.getClassNameForPercentage(percentage)}`} >
                            <>
                                {icon}
                            </>
                        </div>
                    </Grid>

                    {/*<Joyride steps={[{content: 'test', target:'.title', disableBeacon: true}]}></Joyride>*/}
                    <Grid item xs style={{padding: 0, paddingTop: 5, paddingBottom: 5}}>
                        <div>
                            <div style={{
                                maxHeight: '40px',
                                height: '40px',
                                overflow: "hidden",
                                borderRadius: '6px',
                                border: '1px solid darkgrey',
                                position: 'relative'
                            }}>
                                <div style={{
                                    height: '100%',
                                    width: getPercentageWeighted(percentage) + '%',
                                    position: 'absolute',
                                    display: 'flex',
                                    placeContent: 'center'
                                }} className={HumanaUtils.getClassNameForPercentage(percentage) + '-background'}>
                                    {percentage >= 45 && (
                                        <label
                                            className={HumanaUtils.getClassNameForPercentage(percentage)}
                                            style={{
                                            lineHeight: '40px',
                                            fontWeight: 'bold',
                                            fontSize: 18,
                                            paddingLeft: 10
                                        }}>
                                            {percentage?.toLocaleString('de',
                                                {maximumFractionDigits: 0})}%
                                        </label>
                                    )}
                                </div>
                                <div style={{
                                    backgroundColor: '#efefef',
                                    height: '100%',
                                    left: getPercentageWeighted(percentage) + '%',
                                    width: 100 - getPercentageWeighted(percentage) + '%',
                                    position: 'absolute'
                                }}>
                                    {percentage < 45 && (
                                        <label
                                            className={HumanaUtils.getClassNameForPercentage(percentage)}
                                            style={{
                                            lineHeight: '40px',
                                            fontWeight: 'bold',
                                            fontSize: 18,
                                            paddingLeft: 10,
                                        }}>
                                            {percentage?.toLocaleString('de',
                                                {maximumFractionDigits: 0})}%
                                        </label>
                                    )}
                                </div>
                                <div style={{
                                    backgroundColor: 'transparent',
                                    height: '100%',
                                    left: '80%',
                                    width: '0px',
                                    borderLeft: '1.5px dotted black',
                                    position: 'absolute'
                                }}></div>
                            </div>
                        </div>

                    </Grid>
                    <Grid item
                          style={{
                              width: '120px',
                              display: 'flex',
                              placeContent: 'center',
                              placeItems: 'center',
                              flexDirection: 'column',
                              overflow: 'visible',
                              position: 'relative',
                          }}>
                        <label style={{
                            color: '#a6a6a6',
                            fontWeight: 'bold',
                            fontSize: '12px',
                            position: 'absolute',
                            top: -15
                        }}>Ist </label>
                        <label style={{
                            color: '#a6a6a6',
                            fontWeight: 'bold',
                            fontSize: '12px',
                            position: 'absolute',
                            top: 48
                        }}>Budget </label>
                        <Grid container direction={'column'}
                              style={{placeItems: 'center', placeContent: 'center', overflow: 'visible'}}>
                            <Grid item className={HumanaUtils.getClassNameForPercentage(percentage)}
                                  style={{fontSize: 13, fontWeight: 'bold', position: 'relative', overflow: 'visible'}}>
                                {/*{value?.toLocaleString('de', {maximumFractionDigits: 0})} {unit}*/}

                                {numeral(value).format(format)} {unit}
                            </Grid>
                            <Grid item style={{
                                borderTop: '1px solid black',
                                fontSize: 13,
                                fontWeight: 'bold',
                                paddingLeft: 5,
                                paddingRight: 5
                            }}>
                                {/*<label style={{color: '#a6a6a6', fontWeight: 'normal'}}>B: </label>*/}
                                {numeral(targetValue).format(format)} {unit}

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container style={{
                    margin: 0,
                    padding: 0,
                    paddingTop: 8,
                    paddingBottom: 8, marginLeft: 0, paddingLeft: 60, maxHeight: 60, overflow: 'hidden'
                }}>
                    <Grid item>
                        <Stack direction={"row"}>
                            <div
                                style={{borderRadius: '5px', padding: 0, fontSize: 13, display: 'inline-block'}}>

                                {valuePrevious !== null && valuePrevious !== 0 && (
                                    <label>
                                        Vgl. Vorjahr:
                                        &nbsp;<label
                                        className={HumanaUtils.getClassNameForPercentageDifference(percentageDifferencePrevious)}>{numeral(differencePrevious).format(differencePrevious > 0 ? '+0,0 a' : '0,0 a')} {unit}
                                        &nbsp;({numeral(percentageDifferencePrevious).format(percentageDifferencePrevious > 0 ? '+0 %' : '0 %')})</label>
                                    </label>
                                )}
                                {valuePrevious === 0 && (
                                    <label>(Kein Vorjahreswert)</label>
                                )}
                            </div>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
            {/*<CardActions style={{}}>*/}
            {/*    <Grid item xs={12}>*/}
            {/*        <Stack direction={"row"}>*/}
            {/*            <div*/}
            {/*                style={{borderRadius: '5px', padding: 0, paddingLeft: '50px', fontSize: 13, display: 'inline-block'}}>*/}
            {/*                {valuePrevious && (*/}
            {/*                    <b>*/}
            {/*                        Unterschied Vorjahr:*/}
            {/*                        &nbsp;<label*/}
            {/*                        className={getClassNameForPercentageDifference(percentageDifferncePrevious)}>{numeral(differencePrevious).format(differencePrevious > 0 ? '+0,0 a' : '0,0 a')} {unit}*/}
            {/*                        &nbsp;({numeral(percentageDifferncePrevious).format(percentageDifferncePrevious > 0 ? '+0 %' : '0 %')})</label>*/}
            {/*                    </b>*/}
            {/*                )}*/}
            {/*            </div>*/}
            {/*        </Stack>*/}
            {/*    </Grid>*/}
            {/*</CardActions>*/}
        </Card>
    )
}