import {DateRangeType, IDateRange, SelectedDateRange} from "../../../rest-data-provider/models";
import {
    Button, CircularProgress,
    Drawer,
    Grid,
} from "@mui/material";
import {FaCheck} from "react-icons/fa";
import {useEffect, useState} from "react";
import {DateChooserProps} from "./DateChooser";
import {useList} from "@refinedev/core";
import {WeekMappingDTO} from "../../../rest-data-provider/customDTOs";

export const SimpleDateChooser : React.FC<DateChooserProps> = ({didSelectRange, maxYear, onClose, open}) => {

    const [dateChooserType, setDateChooserType] = useState<DateRangeType>('week_current')
    const [weekMappings, setWeeksMapping] = useState<WeekMappingDTO[]>([])

    useEffect(() => {
        didSelectRange(new SelectedDateRange(dateChooserType, null, weekMappings))
    }, [dateChooserType]);

    const {data, isLoading, isError} = useList<WeekMappingDTO>({
        resource: 'report/weekMapping'
    });

    useEffect(() => {
        if(data?.data) {
            setWeeksMapping(data?.data);
        }
    }, [data]);

    return (
        <Drawer anchor={'bottom'} open={open} onClose={onClose} PaperProps={{sx: {
                // maxHeight: '400px',
                maxWidth: '400px',
                minHeight: '150px',
                marginLeft: 'auto',
                marginRight: 'auto'
            }}}>
            <Grid container style={{placeContent:'center', margin: 0}}>

                {weekMappings.length == 0 && (
                    <>
                        <CircularProgress variant={"indeterminate"} style={{marginTop: '60px'}}></CircularProgress>
                    </>
                )}
                {weekMappings.length > 0 && (
                    <>
                        <Grid container item xs={12} sm={12} md={12} style={{padding: 15, paddingBottom: 0, margin: 0}}>
                            <Button fullWidth variant={dateChooserType == 'week_current' ? 'contained' : 'outlined'} startIcon={dateChooserType == 'week_current' ? <FaCheck size={18} /> : null} onClick={event => {
                                setDateChooserType('week_current');
                            }}>Aktuelle Woche</Button>
                        </Grid>
                        <Grid container item xs={12} sm={12} md={12} style={{padding: 15, paddingTop: 10, paddingBottom: 0, margin: 0}}>
                            <Button fullWidth variant={dateChooserType == 'week_last' ? 'contained' : 'outlined'} startIcon={dateChooserType == 'week_last' ? <FaCheck size={18} /> : null} onClick={event => {
                                setDateChooserType('week_last');
                            }}>Letzte Woche</Button>
                        </Grid>
                        <Grid container item xs={12} sm={12} md={12} style={{padding: 15, paddingTop: 10, paddingBottom: 0, margin: 0}}>
                            <Button fullWidth variant={dateChooserType == 'month_current' ? 'contained' : 'outlined'} startIcon={dateChooserType == 'month_current' ? <FaCheck size={18} /> : null} onClick={event => {
                                setDateChooserType('month_current');
                            }}>Aktueller Monat</Button>
                        </Grid>
                        <Grid container item xs={12} sm={12} md={12} style={{padding: 15, paddingTop: 10, paddingBottom: 0, margin: 0}}>
                            <Button fullWidth variant={dateChooserType == 'month_last' ? 'contained' : 'outlined'} startIcon={dateChooserType == 'month_last' ? <FaCheck size={18} /> : null} onClick={event => {
                                setDateChooserType('month_last');
                            }}>Letzter Monat</Button>
                        </Grid>
                        <Grid container item xs={12} sm={12} md={12} style={{padding: 15, paddingTop: 10, paddingBottom: 0, margin: 0}}>
                            <Button fullWidth variant={dateChooserType == 'year_current' ? 'contained' : 'outlined'} startIcon={dateChooserType == 'year_current' ? <FaCheck size={18} /> : null} onClick={event => {
                                setDateChooserType('year_current');
                            }}>Aktuelles Jahr</Button>
                        </Grid>
                        <Grid container item xs={12} sm={12} md={12} style={{padding: 15, paddingTop: 10, margin: 0}}>
                            <Button fullWidth variant={dateChooserType == 'year_last' ? 'contained' : 'outlined'} startIcon={dateChooserType == 'year_last' ? <FaCheck size={18} /> : null} onClick={event => {
                                setDateChooserType('year_last');
                            }}>Letztes Jahr</Button>
                        </Grid>
                    </>
                )}
            </Grid>
        </Drawer>
    )
}