import React, {useState} from "react";
import {WeekChooser} from "../../components/custom/DateChooser/WeekChooser";
import {ICalenderWeek} from "../../rest-data-provider/models";
import {Grid, Paper} from "@mui/material";

export const ExternalData : React.FC = () => {

    const [selectedWeek, setSelectedWeek] = useState<ICalenderWeek>()

    return (
        <>
            <Paper style={{paddingTop: '10px', paddingBottom: '10px', margin: 0}}>
                <WeekChooser persistentSelection={false} usageContext={'external-data'} compactMode={true} didSelectWeek={selectedWeek => setSelectedWeek(selectedWeek)} />
            </Paper>
            <div style={{backgroundColor: 'white', marginTop: '10px', padding: '10px', position:'fixed', left: '0px', right:'0px', bottom: '0px'}}>
                <Grid container>
                    <Grid item xs={4} style={{backgroundColor: 'red', display: 'flex', placeContent: 'center', flexDirection: 'column', placeItems: 'center'}}>
                        <label>Test</label>
                    </Grid>
                    <Grid item xs={4}>
                        Test
                    </Grid>
                    <Grid item xs={4}>
                        Test
                    </Grid>
                </Grid>
            </div>
        </>
    )
}