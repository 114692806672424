import React, {useEffect, useState} from "react";
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    ListItemButton,
    ListItemText,
    ListSubheader,
    useTheme
} from "@mui/material";
import {ICalenderWeek, IDepartment, IShop, IUnit, IUser} from "../../rest-data-provider/models";
import {useCustom, useGetIdentity, useList} from "@refinedev/core";
import {format, isSameYear} from "date-fns";
import {SearchField} from "../../components/custom/SearchField";
import List from "@mui/material/List";
import {CombinedExternalDataDTO, ExternalDataRequest, ICombinedExternalData} from "./model/model";
import {WeekChooser} from "../../components/custom/DateChooser/WeekChooser";
import {ExternalDataTabDebounced} from "./components/ExternalDataTab";
import {ExternalDataTabContainer, ExternalDataType} from "./components/ExternalDataTabContainer";
import {WorkTimeDTO} from "./model/WorkTimeDTO";
import {GenericRestock} from "./GenericRestock";
import {GenericWorkTime} from "./GenericWorkTime";
import {RestockDTO} from "./model/RestockDTO";
import {GenericDelivery} from "./GenericDelivery";
import {DeliveryDTO} from "./model/DeliveryDTO";

export const ExternalDataAdmin : React.FC = () => {

    const [units, setUnits] = useState<IUnit[]>([]);
    const [departments, setDepartments] = useState<IDepartment[]>([])
    const {data} = useList<IUnit>({resource: 'locations'});
    if (data && units.length === 0) {
        const departments : IDepartment[] = [];
        const units : IUnit[] = [];
        for (const unit of data.data) {
            let shops = unit.shops;

            shops = shops.sort((a, b) => a.costCenter - b.costCenter);
            unit.shops = shops;
            units.push(unit);
            for (const shop of shops) {
                departments.push(...shop.departments);
            }
        }
        console.log('departments', departments);
        setDepartments(departments)
        setUnits(units);
        
    }
    const [shopFilter, setShopFilter] = useState<string>('');
    const [formattedDate, setFormattedDate] = useState<string|null>();

    const [selectedWeek, setSelectedWeek] = useState<ICalenderWeek|null>(null)
    const [selectedShop, setSelectedShop] = useState<IShop|null>(null);
    const [selectedDataType, setSelectedDataType] = useState<ExternalDataType>('worktime');
    const [initialDataDTO, setInitialDataDTO] = useState<CombinedExternalDataDTO>(new CombinedExternalDataDTO([], []));

    const [externalDataRequestParams, setExternalDataRequestParams] = useState<ExternalDataRequest|null>()

    const {data: currentUser} = useGetIdentity<IUser>();

    const [summaryWorktime, setSummaryWorktime] = useState<string>('-');
    const [summaryRestock, setSummaryRestock] = useState<string>('-');
    const [summaryDelivery, setSummaryDelivery] = useState<string>('-');


    const {data: initalData, isFetching, refetch: refetchExternalData } = useCustom<ICombinedExternalData>({
        url: `external-data/initial`,
        method: "get",
        config: {
            query: externalDataRequestParams ? {
                fromDate: externalDataRequestParams.fromDate,
                toDate: externalDataRequestParams.toDate,
                shopId: externalDataRequestParams.shop?.id
            } : null,
        },
        queryOptions: {
            enabled: !!externalDataRequestParams && externalDataRequestParams.isValid()
        }
    })

    useEffect(() => {
        if(initalData) {
            refetchExternalData();
        }
    }, [selectedDataType]);


    useEffect(() => {
        const initialDataDTO = new CombinedExternalDataDTO()
        if(initalData) {
            const workTime = (initalData.data.workTime.map(value => {
                return WorkTimeDTO.fromResponse(value);
            }));
            setSummaryWorktime(WorkTimeDTO.generateSummary(workTime))
            initialDataDTO.workTime = workTime;

            const restock = initalData.data.restock.map(value => {
                return RestockDTO.fromResponse(value);
            });
            setSummaryRestock(RestockDTO.generateSummary(restock))
            initialDataDTO.restock = restock;

            const delivery = initalData.data.delivery.map(value => {
                return DeliveryDTO.fromResponse(value);
            });
            setSummaryDelivery(DeliveryDTO.generateSummary(delivery))
            initialDataDTO.delivery = delivery;

            initialDataDTO.categories = initalData.data.categories;
            initialDataDTO.origins = initalData.data.origins;
        } else {
            initialDataDTO.workTime = [];
            initialDataDTO.restock = []
        }
        setInitialDataDTO(initialDataDTO);
    }, [initalData])


    const theme = useTheme();

    useEffect(() => {
        if(selectedWeek) {
            let dateString = ''
            if(isSameYear(selectedWeek.from, selectedWeek.to)) {
                dateString = `${format(selectedWeek.from, 'dd.MM.')} - ${format(selectedWeek.to, 'dd.MM.yyyy')}`
            } else {
                dateString = `${format(selectedWeek.from, 'dd.MM.yyyy')} - ${format(selectedWeek.to, 'dd.MM.yyyy')}`
            }
            setFormattedDate(dateString)
        } else {
            setFormattedDate(null)
        }

        if(selectedWeek && selectedShop && departments.length>0) {
            setExternalDataRequestParams(new ExternalDataRequest(selectedWeek, selectedShop, departments));
        } else {
            setExternalDataRequestParams(null);
        }


    }, [selectedWeek, selectedShop]);

    useEffect(() => {
        setSelectedShop(null)
    }, [shopFilter]);

    const filteredShops = (unit : IUnit,  searchTerm: string) => {
        const fShops : IShop[] = [];
        for (const shop of unit.shops) {
            if(shop.name.toLowerCase().includes(searchTerm.toLocaleLowerCase()) || shop.shortName.toLowerCase().includes(searchTerm.toLowerCase())) {
                fShops.push(shop);
            }
        }
        return fShops;
    }

    const highlightedText = (text:string, highlight:string) => {
        // Split on highlight term and include term into parts, ignore case
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return <span> { parts.map((part, i) =>
            <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? {backgroundColor: 'yellow', paddingTop: '2px', paddingBottom: "2px"} : {} }>
                { part }
            </span>)
        } </span>;
    }



    return (
            <Grid container spacing={2} style={{overflow: 'hidden', padding: 1, height: '100%',}}>
                <Grid item style={{width: '300px', height:'0',  minHeight:'100%', display:'flex', flexDirection:'column'}}>
                    <Card style={{padding: 10, marginBottom: 20}}>
                        <CardHeader subheader={<span style={{color: '#676565', padding: 0, fontSize: 18}}><b>Kalenderwoche</b></span>}></CardHeader>
                        <CardContent style={{padding: 0, marginBottom: 10}}>
                            <WeekChooser didSelectWeek={selectedWeek => setSelectedWeek(selectedWeek)} />
                        </CardContent>
                    </Card>
                    <Card style={{padding: 10, flex:1, display:'flex', flexDirection:'column'}}>
                        <CardHeader subheader={<span style={{color: '#676565', padding: 0, fontSize: 18, flex:'0'}}><b>Shopauswahl</b></span>}></CardHeader>
                        <SearchField searchTermChanged={searchTerm => {
                            setShopFilter(searchTerm)
                        }}></SearchField>

                            <List dense subheader={<li />} sx={{
                                width: '100%',
                                maxWidth: 360,
                                backgroundColor: 'background.paper',
                                position: 'relative',
                                overflowY: 'scroll',
                                '& ul': { padding: 0 },
                                flex:'1'
                            }}>
                                {units.filter(unit => {
                                    return filteredShops(unit, shopFilter).length > 0
                                }).map((unit) => (
                                    <li key={`unit-${unit.id}`}>
                                        <ul>
                                            <ListSubheader>{unit.name}</ListSubheader>
                                            { filteredShops(unit, shopFilter).map((shop) => (
                                                <ListItemButton selected={shop.id === selectedShop?.id} sx={{
                                                    borderRadius: '10px',
                                                    borderWidth: '2px',
                                                    borderStyle: 'solid',
                                                    borderColor: 'white',
                                                    "&.Mui-selected": {
                                                        borderColor: "primary.main",
                                                        color: "primary.main",
                                                        borderWidth: '2px',
                                                        borderStyle: 'solid',
                                                    }
                                                }} onClick={event => {
                                                    setSelectedShop(shop);
                                                }} key={`shop-${shop.id}`}>
                                                    <ListItemText  primary={<>{shop.costCenter} - <b>{highlightedText(shop.name, shopFilter)}</b></>}/>
                                                </ListItemButton>
                                            ))}
                                        </ul>
                                    </li>
                                ))}
                            </List>
                    </Card>

                </Grid>
                <Grid item container xs style={{placeContent: 'start', overflow: 'hidden'}}>

                    {(!selectedShop || !selectedWeek) && (
                        <Grid item xs={12} style={{ minWidth: 0, placeContent: 'start', overflow: 'hidden', padding: 1, height: '100%'}}>
                            <Card style={{overflow:'hidden', minHeight: '920px', height:'100%'}}>
                                <CardContent style={{display: 'flex', placeContent: 'center', placeItems:'center', height: '100%'}}>
                                    <h1>Bitte <b>Zeitraum</b> und <b>Shop</b> auswählen</h1>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                    {externalDataRequestParams && externalDataRequestParams.isValid() && initalData && currentUser && (
                        <>

                            <Grid item xs={12} style={{overflow:'hidden', minWidth: 0, placeContent: 'start', padding: 1, height: '100%'}}>
                                <Card style={{overflow:'hidden', padding: 10, height:'100%'}}>
                                    {/*<CardHeader subheader={<span style={{backgroundColor: theme.palette.primary.main , color: '#676565', padding: 0, fontSize: 18}}><b>{selectedShop?.name}</b> ({formattedDate})</span>}></CardHeader>*/}

                                    <CardContent style={{minWidth: 0, height: '0', minHeight:'100%', display:'flex', flexDirection:'column', maxHeight:'100%', overflow:'auto' }}>
                                        <Grid item container xs={12} style={{paddingBottom: '30px', flex:'0'}}>
                                            <Grid item>
                                                <ExternalDataTabContainer didChangeTab={type => {
                                                    setSelectedDataType(type);
                                                }}>
                                                    <ExternalDataTabDebounced shop={selectedShop} type={'shop'} subtitle={formattedDate}></ExternalDataTabDebounced>
                                                    <ExternalDataTabDebounced selectable={true} type={'worktime'} subtitle={summaryWorktime}></ExternalDataTabDebounced>
                                                    <ExternalDataTabDebounced selectable={true} type={'restock'} subtitle={summaryRestock}></ExternalDataTabDebounced>
                                                    <ExternalDataTabDebounced selectable={true} type={'delivery'} subtitle={summaryDelivery}></ExternalDataTabDebounced>
                                                </ExternalDataTabContainer>
                                            </Grid>
                                        </Grid>
                                        {selectedDataType === 'worktime' &&  (
                                            <GenericWorkTime requestParameters={externalDataRequestParams}
                                                             currentUser={currentUser}
                                                             initialData={initialDataDTO}
                                                             apiUrl={'decentral/worktime'}
                                                             onUpdatedSummary={summary => {
                                                            setSummaryWorktime(summary);
                                                        }}
                                                        // onUpdatedWorkTime={updatedWorkTime => {
                                                        //     initialDataDTO.workTime = updatedWorkTime;
                                                        //     console.error(updatedWorkTime);
                                                        // }}
                                            />
                                        )}
                                        {selectedDataType === 'restock' && (
                                           <GenericRestock requestParameters={externalDataRequestParams} initialData={initialDataDTO} onUpdatedSummary={summary => {
                                               setSummaryRestock(summary);
                                           }} apiUrl={'decentral/restock'} currentUser={currentUser}></GenericRestock>
                                        )}
                                        {selectedDataType === 'delivery' &&  (
                                            <GenericDelivery requestParameters={externalDataRequestParams} initialData={initialDataDTO} onUpdatedSummary={summary => {
                                                setSummaryDelivery(summary);
                                            }} apiUrl={'decentral/delivery'} currentUser={currentUser}></GenericDelivery>
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                        </>

                    )}

                </Grid>
            </Grid>

    )
}
