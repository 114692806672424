import React, {useEffect, useState} from "react";
import {
    endOfISOWeek, format,
    getISOWeek,
    getISOWeeksInYear,
    getYear,
    setISOWeek, startOfDay,
    startOfISOWeek
} from "date-fns";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import useLocalStorageState from "use-local-storage-state";
import {Button, Grid, InputLabel} from "@mui/material";
import {MdNavigateBefore, MdNavigateNext} from "react-icons/md";
import {ICalenderWeek} from "../../../rest-data-provider/models";
import FormControl from "@mui/material/FormControl";


interface IWeekChooser {
    didSelectWeek: (selectedWeek : ICalenderWeek) => void
    compactMode?: boolean
    persistentSelection?: boolean
    usageContext?: string
}

export const WeekChooser : React.FC<IWeekChooser> = ({didSelectWeek, compactMode = false, persistentSelection = true, usageContext = 'default'}) => {

    const [years, setYears] = useState<number[]>([])
    const [weeks, setWeeks] = useState<number[]>([])
    const [selectedYear, setSelectedYear] = useLocalStorageState<number>(`ext-data.selected-year-${usageContext}`, {
        defaultValue: -1,

    })
    const [selectedWeek, setSelectedWeek] = useLocalStorageState<number>(`ext-data.selected-week-${usageContext}`, {
        defaultValue: -1
    })
    const [formattedDate, setFormattedDate] = useState('')
    const [startDate, setStartDate] = useState<Date|null>(null)
    const [endDate, setEndDate] = useState<Date|null>(null)

    useEffect(() => {
        const currentYear = getYear(new Date())
        const currentWeek = getISOWeek(new Date());
        const lYears = [];
        if(selectedYear < 0 || !persistentSelection) {
            setSelectedYear(currentYear);
        }
        if(selectedWeek < 0 || !persistentSelection) {
            setSelectedWeek(currentWeek);
        }

        for (let i = 0; i<= 10; i++) {
            lYears.push(currentYear - i);
        }
        setYears(lYears);
    }, []);

    useEffect(() => {
        const weeksInCurrentYear = getISOWeeksInYear(new Date(selectedYear, 0, 1))
        const lWeeks:number[] = []
        for (let i = 1; i<= weeksInCurrentYear; i++) {
            lWeeks.push(i);
        }
        setWeeks(lWeeks);
        if(selectedWeek > lWeeks.length) {
            setSelectedWeek(lWeeks.length)
        }

        //TODO: Ausgewählte Woche ist > als max Anzahl Wochen in dem Jahr
    }, [selectedYear]);

    useEffect(() => {

        if(weeks.length > 0 && years.length > 0) {
            const weekDate = setISOWeek(new Date(selectedYear, 1, 1), selectedWeek);
            const startOfWeekDate = startOfISOWeek(weekDate);
            setStartDate(startOfWeekDate);
            const endOfWeekDate = startOfDay(endOfISOWeek(weekDate));
            setEndDate(endOfWeekDate);

            if(endOfWeekDate && startOfWeekDate) {
                didSelectWeek({
                    realTo: endOfWeekDate,
                    to: endOfWeekDate,
                    from: startOfWeekDate,
                    realFrom: startOfWeekDate,
                    calenderWeek: selectedWeek,
                    type:"week_freeform"
                })
            }
        }
    }, [selectedWeek, selectedYear, years, weeks]);

    const stepMonth = (step : number) => {
        const newWeek = selectedWeek + step;
        if(newWeek < 1) {
            setSelectedWeek(53);
            setSelectedYear(selectedYear - 1);
        } else if(newWeek > weeks.length) {
            setSelectedWeek(1)
            setSelectedYear(selectedYear + 1)
        } else {
            setSelectedWeek(newWeek);
        }
    }

    return (
        <>
            <Grid container spacing={1} >
                <Grid item xs={2} style={{display:'flex', placeContent: 'center', padding: 0, paddingLeft: '16px'}}>
                    {!compactMode && (
                        <Button variant={"text"} style={{paddingLeft: 0, paddingRight: 0, height: '100%'}} sx={{maxWidth: '40px', minWidth: '40px', paddingLeft: 0, paddingRight: 0}} onClick={() => {
                            stepMonth(-1)
                        }}>
                            <MdNavigateBefore size={40} />
                        </Button>
                    )}
                    {compactMode && (
                        <Button size={'small'} variant={'text'} sx={{marginTop: '7px'}} onClick={() => {
                            stepMonth(-1)
                        }}>
                            <MdNavigateBefore size={34} />
                        </Button>
                    )}
                </Grid>
                <Grid item container xs={8} >
                    <Grid item container xs={20} columns={20} spacing={1} style={{display: 'flex', justifyContent: 'start', padding: 0}}>
                        <Grid item xs={compactMode ? 6 : 20}>
                            {years.length > 0 && selectedYear > 0  && (
                                <FormControl fullWidth>
                                    <InputLabel id="year-label">Jahr</InputLabel>
                                    <Select labelId={'year-label'} label={'Jahr'} fullWidth size={'small'} value={selectedYear} onChange={event => {
                                        setSelectedYear(event.target.value as number);
                                    }}>
                                        {years.map(value => {
                                            return (
                                                <MenuItem key={`year-${value}`} value={value}><b>{compactMode ? `${value - 2000}` : value}</b></MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                        <Grid item xs={compactMode ? 14 : 20}>
                            {weeks.length > 0 && selectedWeek > 0 && (
                                <FormControl fullWidth>
                                    <InputLabel id="year-label">Kalenderwoche</InputLabel>

                                    <Select fullWidth size={'small'} label={'Kalenderwoche'} sx={{minWidth: '100px'}} value={selectedWeek} onChange={event => {
                                        setSelectedWeek(event.target.value as number);
                                    }}>
                                        {weeks.map(value => {
                                            const yearDate = new Date(selectedYear, 2, 1);
                                            const weekDate = startOfISOWeek(setISOWeek(yearDate, value));
                                            return (
                                                <MenuItem key={`year-${value}`} value={value}><b>{value.toString().padStart(2, '0')}</b><span style={{fontSize:'12px', lineHeight: '20px'}}>&nbsp;(ab {format(weekDate, 'dd.MM.yyyy')})</span></MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2} style={{display: 'flex', placeContent: 'center', placeItems: "center", padding: 0}}>
                    {!compactMode && (
                        <Button  variant={'text'} style={{ height: '100%', paddingLeft: 0, paddingRight: 0}} sx={{maxWidth: '40px', minWidth: '40px', paddingLeft: 0, paddingRight: 0}} onClick={() => {
                            stepMonth(1)
                        }}>
                            <MdNavigateNext size={40} />
                        </Button>
                    )}
                    {compactMode && (
                        <Button size={'small'} variant={'text'} sx={{marginTop: '7px', marginLeft: '3px'}} onClick={() => {
                            stepMonth(1)
                        }}>
                            <MdNavigateNext size={34} />
                        </Button>
                    )}
                </Grid>
            </Grid>

        </>
    )
}