import {ILocation} from "../../../rest-data-provider/models";
import React, {Children, createContext, useEffect, useState} from "react";
import {Grid} from "@mui/material";


export type ExternalDataType = 'worktime' | 'delivery' | 'restock' | 'shop'

const externalDataTabContextDefault = {
    selectedTab: null,
    setSelectedTab: (location: ILocation) => {return} // noop default callback
};
export const ExternalDataTabContext= createContext<any>(externalDataTabContextDefault);

interface ExternalDataTabContainerProps {
    didChangeTab:(type: ExternalDataType) => void;
}
export const ExternalDataTabContainer : React.FC<React.PropsWithChildren & ExternalDataTabContainerProps> = ({didChangeTab, children}, context) => {

    const [selectedTab, setSelectedTab] = useState<ExternalDataType>('worktime')

    useEffect(() => {
        if(selectedTab) {
            didChangeTab(selectedTab);
        }
    }, [selectedTab]);

    return (
        <ExternalDataTabContext.Provider value={[selectedTab,setSelectedTab ]} >
            <Grid container spacing={1}>
                {children && Children.map(children, child => {
                    return (
                        <Grid item xs={"auto"}>
                            {child}
                        </Grid>
                    )
                })}

            </Grid>
        </ExternalDataTabContext.Provider>
    )
}