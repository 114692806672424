import React, {ReactNode} from "react";
import {DataGrid, deDE, GridColDef, GridRowParams} from "@mui/x-data-grid";
import {Grid} from "@mui/material";
import {SearchField} from "./SearchField";


interface HumanaDataGridProps {
    onSearchTermChanged?: (searchTerm: string) => void
    dataGridProps: any
    onRowClick?: (params: GridRowParams) => void
    onCreate?: () => void
    columns: GridColDef[]
    title?: string|undefined|null
    showSearch?: boolean,
    additionalHeaderComponents?: ReactNode,
    showBreadCrumb?: boolean,
    isLoading: boolean
}

export const HumanaDataGrid: React.FC<HumanaDataGridProps>  = ({   dataGridProps,
                                                                   title,
                                                                   showSearch,
                                                                   isLoading,
                                                                   columns,
                                                                   onSearchTermChanged,
                                                                   onRowClick
                                                               }) => {
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <SearchField searchTermChanged={searchTerm => {
                        if(onSearchTermChanged) {

                            onSearchTermChanged(searchTerm);
                        }
                    }}></SearchField>
                </Grid>
            </Grid>
            <DataGrid loading={isLoading} columns={columns} {...dataGridProps}
                      autoHeight={true}
                      localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                      onRowClick={onRowClick}

            />
        </>
    );

}