import {ApiError} from "./ApiError";
import {useState} from "react";


export type ErrorHandler = {addError: (key: string, apiErrors: ApiError[]) => void, deleteError: (key: string) => void, errors: Map<string, ApiError[]>};
export function useApiErrorHandling() : {errors: Map<string, ApiError[]>, errorHandlers: ErrorHandler, setErrors: (errors : Map<string, ApiError[]>) => void} {

    const [errors, setErrors] = useState<Map<string, ApiError[]>>(new Map())

    const errorHandlers : ErrorHandler = {
        addError: (key: string, apiErrors: ApiError[])  => {
            errors.set(key, apiErrors);
            setErrorsClone(errors);
        },
        deleteError: (key: string) => {
            errors.delete(key);
            setErrorsClone(errors);
        },
        errors: errors
    }

    const setErrorsClone = (errors : Map<string, ApiError[]>) => {
        setErrors(new Map(errors.entries()));
    }

    return {errors, errorHandlers, setErrors: setErrorsClone};
}