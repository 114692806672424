import React, {ReactNode, useContext, useEffect, useState} from "react";
import {Card, CardActionArea, CardHeader, useTheme} from "@mui/material";
import {ExternalDataTabContext, ExternalDataType} from "./ExternalDataTabContainer";
import {FaShop, FaTruckArrowRight} from "react-icons/fa6";
import {IoTime, IoTimeOutline} from "react-icons/io5";
import {TbHanger} from "react-icons/tb";
import {IShop} from "../../../rest-data-provider/models";
import debounceRender from "react-debounce-render";



interface ExternalDataTabProps {
    type: ExternalDataType;
    subtitle?: string|null;
    selectable?: boolean;
    shop?: IShop|null;
}


const ExternalDataTab: React.FC<ExternalDataTabProps> = ({type, subtitle= '', selectable = false, shop }) => {

    const theme = useTheme();
    const [title, setTitle] = useState<string>('')
    const [isSelected, setIsSelected] = useState<boolean>(false)
    const [icon, setIcon] = useState<ReactNode>(<></>)
    const [color, setColor] = useState('grey')
    const [border, setBorder] = useState('1px solid lightgray');
    const [subtitleColor, setSubtitleColor] = useState('lightgrey')

    const [selectedType, setSelectedType] = useContext(ExternalDataTabContext);

    useEffect(() => {
        setIsSelected(selectedType === type)
    }, [selectedType]);

    useEffect(() => {
        switch (type) {
            case 'shop': setTitle(shop ? shop.name : '-'); setIcon(<FaShop fontSize={'28px'}></FaShop>); break;
            case "delivery": setTitle('Anlieferungen'); setIcon(<FaTruckArrowRight fontSize={'28px'}></FaTruckArrowRight>); break;
            case 'worktime': setTitle('Arbeitszeit'); setIcon(<IoTime fontSize={'28px'}></IoTime>); break;
            case "restock": setTitle('Einhängevorgänge'); setIcon(<TbHanger fontSize={'28px'}></TbHanger>); break;
        }
    }, [type, shop]);

    useEffect(() => {
        if(shop) {
            setColor('white')
            setBorder('')
            setSubtitleColor('white')
            return;
        }
        if(isSelected) {
            setColor(theme.palette.primary.main)
            setBorder(`1px solid ${theme.palette.primary.main}`)
            setSubtitleColor(theme.palette.primary.main)
            return;
        }
        setColor('grey')
        setBorder(`1px solid lightgray`)
        setSubtitleColor('darkgrey')
    }, [isSelected, shop]);

    return <>
        <Card sx={{color: color, outline: border, backgroundColor: shop ? `${theme.palette.primary.main}` : ''}}  elevation={isSelected ? 4 : 0}>
            <CardActionArea disabled={!selectable} onClick={() => setSelectedType(type)}>
                <CardHeader avatar={icon} sx={{padding: '10px 15px'}} titleTypographyProps={{fontSize: '18px', fontWeight:isSelected ? 'bold' : 'normal' , paddingBottom:'2px'}} title={title}
                            subheader={subtitle} subheaderTypographyProps={{fontSize: '12px', lineHeight: '1', color:subtitleColor}}></CardHeader>
            </CardActionArea>
        </Card>
    </>
}
export const ExternalDataTabDebounced = debounceRender(ExternalDataTab, 100);