import {
  useShow,
  IResourceComponentsProps,
  useTranslate, useList,
} from "@refinedev/core";
import {
  Show,
  NumberField,
  EmailField,
  TextFieldComponent as TextField,
  BooleanField, EditButton,
} from "@refinedev/mui";
import {Typography, Stack, Button, Box} from "@mui/material";
import React, {useState} from "react";
import {DateField} from "../../components/fields/date";
import {HumanaUtils} from "../../utils/HumanaUtils";
import {IUnit, IUser} from "../../rest-data-provider/models";
import {FaLock, FaLockOpen} from "react-icons/fa";


interface UserShowProps {
  userId? : number|null|undefined;
}

export const UserShow: React.FC<UserShowProps> = ({userId}) => {
  const translate = useTranslate();
  const { queryResult } = useShow({resource: 'users', id: userId ? userId : -1});
  const { data, isLoading } = queryResult;

  const [units, setUnits] = useState<IUnit[]>([])
  const unitResponse = useList<IUnit>({resource: 'locations'});
  if (unitResponse.data && units.length === 0) {
    setUnits(unitResponse.data.data);
  }

  if(userId) {
    const record = data?.data;

    return (
         <>

           <Stack gap={1} sx={{p: 3}}>
             <h2>{translate('users.titles.show')}</h2>
             <Typography variant="body1" fontWeight="bold">
               {translate("users.fields.id")}
             </Typography>
             <NumberField value={record?.id ?? ""} />
             <Typography variant="body1" fontWeight="bold">
               {translate("users.fields.email")}
             </Typography>
             <EmailField value={record?.email} />
             <Typography variant="body1" fontWeight="bold">
               {translate("users.fields.firstName")}
             </Typography>
             <TextField value={record?.firstName} />
             <Typography variant="body1" fontWeight="bold">
               {translate("users.fields.lastName")}
             </Typography>
             <TextField value={record?.lastName} />
             <Typography variant="body1" fontWeight="bold">
               {translate("users.fields.phoneNumber")}
             </Typography>
             <TextField value={record?.phoneNumber} />
             <Typography variant="body1" fontWeight="bold">
               {translate("users.fields.locked")}
             </Typography>
             {record?.active ? <FaLockOpen /> : <FaLock />}
             <Typography variant="body1" fontWeight="bold">
               {translate("users.fields.role")}
             </Typography>
             <TextField value={record?.role?.displayName} />
             {units.length > 0 && record && (
                 <TextField value={HumanaUtils.formatOrgUnitForUser(record as IUser, units)} />
             )}
             <Typography variant="body1" fontWeight="bold">
               {translate("users.fields.updatedAt")}
             </Typography>
             <DateField value={record?.updatedAt} format={'LL'}/>
             <Typography variant="body1" fontWeight="bold">
               {translate("users.fields.lastLogin")}
             </Typography>
             <DateField value={record?.lastLogin} format={'LLL'} />
             {/*<Button startIcon={<MdModeEdit />} variant={'contained'} sx={{position: 'sticky', bottom: '10px', marginTop: '20px', width: '100%'}}>Benutzer editieren</Button>*/}
             <Box sx={{position: 'sticky', bottom: '10px', marginTop: '20px', width: '100%'}}>
               <EditButton resource={'users'} recordItemId={userId} fullWidth variant={'contained'} ></EditButton>
             </Box>
           </Stack>

         </>
    )} else {
      return (<></>);
    }
  };
