import React, {useCallback, useEffect, useState} from "react";
import {
    DataGrid,
    GridColDef,
    GridRowEditStopParams,
    GridRowEditStopReasons,
    GridRowModes,
    GridRowModesModel,
    GridRowParams,
    GridSortDirection,
    MuiEvent,
    useGridApiRef,
} from "@mui/x-data-grid";
import {ExternalDataProps, IWorkTime} from "./model/model";
import {useCustomMutation} from "@refinedev/core";
import {addDays, format,} from "date-fns";
import './external-data-admin.css'
import {useConfirm} from "material-ui-confirm";
import {Grid,} from "@mui/material";
import {
    actionsColumType,
    createdAtColumnType,
    dateColumType, departmentTypeColumn,
    iconTypeColumn,
    updatedAtColumnType,
    updatedByColumnType
} from "./external-data-grid/GridCustomColumns";
import {ApiError} from "./error-handling/ApiError";
import {useTheme} from '@mui/material/styles';
import {CustomEditNumberCellRenderer, formatDuration, getCellClassName} from "./external-data-grid/GridComponents";
import {useApiErrorHandling} from "./error-handling/ApiErrorHook";
import {WorkTimeDTO} from "./model/WorkTimeDTO";
import {ExternalDataGrid} from "./external-data-grid/ExternalDataGrid";
import {BaseExternalData} from "./model/BaseExternalData";
import {UseMutateAsyncFunction} from "@tanstack/react-query/build/lib/types";

export const GenericWorkTime : React.FC<ExternalDataProps> = ({requestParameters, onUpdatedSummary, currentUser, initialData, apiUrl}) => {

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>();
    const [rows, setRows] = useState<WorkTimeDTO[]>([]);
    const [nextNewWorkTime, setNextNewWorkTime] = useState<WorkTimeDTO>();
    const [summaryText, setSummaryText] = useState<string>('')

    const apiRef = useGridApiRef()
    const theme = useTheme();

    const {errorHandlers, errors, setErrors} = useApiErrorHandling()
    const confirm = useConfirm();
    const mutate = useCustomMutation<BaseExternalData>({});


    useEffect(() => {
        if(initialData.workTime) {
            setRows(initialData.workTime);
            let currentWorkTime : WorkTimeDTO|null = null;
            if(nextNewWorkTime) {
                // console.log('ficken 4000')
                nextNewWorkTime.day = requestParameters.calenderWeek!.to
                nextNewWorkTime.department = requestParameters.shop!.departments[0]
                currentWorkTime = nextNewWorkTime.clone();
                setNextNewWorkTime(currentWorkTime);
                apiRef.current.setEditCellValue(
                    {id: nextNewWorkTime.generateRowId(requestParameters.shop), field:'day', value: requestParameters.calenderWeek!.to}
                );
                apiRef.current.setEditCellValue(
                    {id: nextNewWorkTime.generateRowId(requestParameters.shop), field:'department', value: requestParameters.shop!.departments[0].id}
                );
                apiRef.current.setEditCellValue(
                    {id: nextNewWorkTime.generateRowId(requestParameters.shop), field:'workTimeMinutes', value: '0.00'}
                )
                setErrors(new Map());

                setRows([...initialData.workTime, currentWorkTime]);
                setRowModesModel(
                    {[`new-${currentWorkTime.uuid}`]: { mode: GridRowModes.Edit, fieldToFocus: 'workTimeMinutes' }}
                );
            } else {
                currentWorkTime = WorkTimeDTO.createDummy(requestParameters.shop!.departments[0], requestParameters.calenderWeek!.to, currentUser)
                setNextNewWorkTime(currentWorkTime);
                setRows([...initialData.workTime, currentWorkTime]);
                setRowModesModel(
                    {[`new-${currentWorkTime.uuid}`]: { mode: GridRowModes.Edit, fieldToFocus: 'workTimeMinutes' }}
                );
            }
            setTimeout(() => {
                apiRef.current.scrollToIndexes({
                    rowIndex: apiRef.current.getRowsCount()-1
                })
                if(currentWorkTime) {
                    apiRef.current.setCellFocus(currentWorkTime.generateRowId(requestParameters.shop), 'workTimeMinutes')
                }

            }, 300)
        }
    }, [initialData, requestParameters]);

    useEffect(() => {
        // alert('yes')
        const summary = WorkTimeDTO.generateSummary(rows)
        onUpdatedSummary(summary)
        setSummaryText(summary);

        if(nextNewWorkTime && nextNewWorkTime.uuid && rowModesModel) {
            if(!rowModesModel[nextNewWorkTime.uuid] || rowModesModel[nextNewWorkTime.uuid].mode !== GridRowModes.Edit) {
                console.log('no', nextNewWorkTime.uuid)
                setRowModesModel(
                    {
                        ...rowModesModel,
                        [`new-${nextNewWorkTime.uuid}`]: {mode: GridRowModes.Edit, fieldToFocus: 'workTimeMinutes'}
                    }
                );
            }
        }
    }, [rows]);


    const columns = React.useMemo<GridColDef<WorkTimeDTO>[]> (
        () => [
            {
                field: 'icon',
                ...iconTypeColumn(theme)
            },
            {
                field: 'day',
                headerName: 'Tag',
                width: 130,
                ...dateColumType(requestParameters.calenderWeek?.from, requestParameters.calenderWeek?.to, errorHandlers),
                sortable: true,
                sortingOrder: ["asc"],
                hideSortIcons: true,
                editable: true,
                cellClassName: (params) => getCellClassName(params, errors),
            },
            {
                field: 'workTimeMinutes',
                headerName: 'Std.',
                width: 80,
                editable: true,
                sortable: false,
                align: 'right',
                headerAlign: 'right',
                cellClassName: params => getCellClassName(params, errors),
                valueSetter:params => {
                    let val = params.value
                    if (typeof val === 'string') {
                        val = val.replaceAll(',', '.')
                    }
                    params.row.workTimeMinutes = Math.round((+val) * 60)
                    return params.row;
                },
                valueGetter: params => {
                   return formatDuration(params.row.workTimeMinutes);
                },
                renderEditCell:params => {
                    return <CustomEditNumberCellRenderer params={params} decimalScale={2}  />
                }
            },
            {
                ...departmentTypeColumn(requestParameters.shop?.departments, requestParameters.departments, 'department')
            },
            // {
            //     field: 'createdAt',
            //     ...createdAtColumnType
            // },
            {
                field: 'updatedAt',
                ...updatedAtColumnType
            },
            {
                field: 'updatedBy',
                ...updatedByColumnType
            },
            {
                field: 'actions',
                ...actionsColumType(rowModesModel, errorHandlers, confirm, setRowModesModel, rows, setRows,apiUrl, mutate)
            }
        ], [errors, requestParameters, rows, rowModesModel]
    );

    return (
        <>
            {
                <ExternalDataGrid<WorkTimeDTO, IWorkTime> title={'Arbeitszeit'} summary={summaryText} requestParameters={requestParameters}
                                  columns={columns} rows={rows} setRows={rows => {
                                      console.log('set the fucking rows', rows)
                                      setRows(rows as WorkTimeDTO[])
                }}
                                  errorHandler={errorHandlers} setRowModesModel={rowModesModel => {
                                    setRowModesModel(rowModesModel)
                                }} rowModesModel={rowModesModel} apiRef={apiRef} cloneMethod={WorkTimeDTO.clone}
                              apiUrl={apiUrl} initializeNewDummyObject={(newRow : BaseExternalData) => {
                    let day = newRow.day;
                    if(day) {
                        day = addDays(day, 1)
                    }
                    if(requestParameters && requestParameters.shop && requestParameters.shop.departments) {
                        const nextNewWorkTime = WorkTimeDTO.createDummy(requestParameters.shop.departments[0], day, currentUser);
                        console.log('new Dummy!!!!', nextNewWorkTime)
                        setNextNewWorkTime(nextNewWorkTime);
                        console.log('yes Before', nextNewWorkTime.uuid)
                        apiRef.current.setCellFocus(nextNewWorkTime.generateRowId(requestParameters.shop), 'workTimeMinutes')
                        return nextNewWorkTime
                    }

                }}

                />
            }

        </>
    )
}