import React, {useEffect, useState} from "react";
import {ExternalDataProps, IDelivery, IDeliveryOrigin, IGenericCategory, IRestock} from "./model/model";
import {ExternalDataGrid} from "./external-data-grid/ExternalDataGrid";
import {
    GridColDef, GridRowModes,
    GridRowModesModel,
    GridValueGetterParams,
    GridValueSetterParams,
    useGridApiRef
} from "@mui/x-data-grid";
import {RestockDTO} from "./model/RestockDTO";
import {useTheme} from "@mui/material/styles";
import {useApiErrorHandling} from "./error-handling/ApiErrorHook";
import {useConfirm} from "material-ui-confirm";
import {useCustomMutation} from "@refinedev/core";
import {DeliveryDTO} from "./model/DeliveryDTO";
import {WorkTimeDTO} from "./model/WorkTimeDTO";
import {
    actionsColumType,
    categoryColumn,
    createdAtColumnType,
    dateColumType,
    departmentTypeColumn,
    iconTypeColumn, updatedAtColumnType, updatedByColumnType
} from "./external-data-grid/GridCustomColumns";
import {
    CustomEditNumberCellRenderer,
    getCellClassName,
    OptionalCategoryEditCellRender
} from "./external-data-grid/GridComponents";
import {BaseExternalData} from "./model/BaseExternalData";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Button} from "@mui/material";
import {TbArrowsSplit} from "react-icons/tb";
import {SplitDeliveryModal} from "./components/SplitDeliveryModal";

export const GenericDelivery : React.FC<ExternalDataProps> = ({requestParameters, onUpdatedSummary, currentUser, initialData, apiUrl}) => {

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>();
    const [rows, setRows] = useState<DeliveryDTO[]>([]);
    const [nextNewDelivery, setNextNewDelivery] = useState<DeliveryDTO>();
    const [summaryText, setSummaryText] = useState<string>('')

    const apiRef = useGridApiRef()
    const theme = useTheme();

    const {errorHandlers, errors, setErrors} = useApiErrorHandling()
    const confirm = useConfirm();
    const mutation = useCustomMutation<BaseExternalData>({});

    const [deliveryToSplit, setDeliveryToSplit] = useState<DeliveryDTO|null>(null)

    useEffect(() => {
        if(initialData.restock && requestParameters.shop) {
            setRows(initialData.delivery);
            let currentDelivery : DeliveryDTO|null = null;
            if(nextNewDelivery) {

                //Reset everything

                nextNewDelivery.day = requestParameters.calenderWeek!.to
                nextNewDelivery.shop = requestParameters.shop

                currentDelivery = nextNewDelivery.clone();
                setNextNewDelivery(currentDelivery);
                apiRef.current.setEditCellValue(
                    {id: nextNewDelivery.generateRowId(requestParameters.shop), field:'day', value: requestParameters.calenderWeek!.to}
                );
                // apiRef.current.setEditCellValue(
                //     {id: nextNewDelivery.generateRowId(requestParameters.shop), field:'department', value: requestParameters.shop!.departments[0].id}
                // );
                apiRef.current.setEditCellValue(
                    {id: nextNewDelivery.generateRowId(requestParameters.shop), field:'amount', value: '0'}
                )
                apiRef.current.setEditCellValue(
                    {id: nextNewDelivery.generateRowId(requestParameters.shop), field:'category', value: ''}
                )
                apiRef.current.setEditCellValue(
                    {id: nextNewDelivery.generateRowId(requestParameters.shop), field:'origin', value: initialData.origins.filter(value => !value.disabled)[0].id}
                )
                setErrors(new Map());

                setRows([...initialData.delivery, currentDelivery]);
                setRowModesModel(
                    {[`new-${currentDelivery.uuid}`]: { mode: GridRowModes.Edit, fieldToFocus: 'amount' }}
                );
            } else {
                currentDelivery = DeliveryDTO.createDummy(requestParameters.calenderWeek!.to,null,
                    initialData.origins.filter(value => !value.disabled)[0], requestParameters.shop, currentUser)
                setNextNewDelivery(currentDelivery);
                setRows([...initialData.delivery, currentDelivery]);
                setRowModesModel(
                    {[`new-${currentDelivery.uuid}`]: { mode: GridRowModes.Edit, fieldToFocus: 'amount' }}
                );
            }
            setTimeout(() => {
                apiRef.current.scrollToIndexes({
                    rowIndex: apiRef.current.getRowsCount()-1
                })
                if(currentDelivery) {
                    apiRef.current.setCellFocus(currentDelivery.generateRowId(requestParameters.shop), 'amount')
                }

            }, 300)
        }
    }, [initialData, requestParameters]);

    useEffect(() => {
        const summary = DeliveryDTO.generateSummary(rows)
        onUpdatedSummary(summary)
        setSummaryText(summary);

        if(nextNewDelivery && nextNewDelivery.uuid && rowModesModel) {
            if(!rowModesModel[nextNewDelivery.uuid] || rowModesModel[nextNewDelivery.uuid].mode !== GridRowModes.Edit) {
                console.log('no', nextNewDelivery.uuid)
                setRowModesModel(
                    {
                        ...rowModesModel,
                        [`new-${nextNewDelivery.uuid}`]: {mode: GridRowModes.Edit, fieldToFocus: 'amount'}
                    }
                );
            }
        }
    }, [rows]);

    const columns = React.useMemo<GridColDef<DeliveryDTO>[]> (
        () => [
            {
                field: 'icon',
                ...iconTypeColumn(theme)
            },
            {
                field: 'day',
                headerName: 'Tag',
                width: 150,
                ...dateColumType(requestParameters.calenderWeek?.from, requestParameters.calenderWeek?.to, errorHandlers),
                sortable: true,
                sortingOrder: ["asc"],
                hideSortIcons: true,
                editable: true,
                cellClassName: (params) => getCellClassName(params, errors),
            },
            {
                field: 'amount',
                headerName: 'Menge in kg',
                width: 120,
                editable: true,
                sortable: false,
                align: 'right',
                cellClassName: params => getCellClassName(params, errors),
                renderEditCell: params => {
                    return <CustomEditNumberCellRenderer params={params} decimalScale={2}  />
                }
            },
            {
                field: 'origin',
                headerName: 'Herkunft',
                width: 200,
                editable: true,
                sortable: false,
                cellClassName: params => getCellClassName(params, errors),
                type:'singleSelect',
                valueOptions: initialData.origins.filter(value => !value.disabled),
                getOptionValue: (value: any) => value.id,
                getOptionLabel: (value: IDeliveryOrigin) => `${value.name}`,
                valueFormatter: (params) => {
                    const origin = initialData.origins.find(dep => dep.id === params.value);
                    return origin?.name ? origin.name : '-'
                },
                valueGetter: (params: GridValueGetterParams<any, any >) => {
                    console.log('Params Value', params.value)
                    return params.value.id
                },
                valueSetter: (params: GridValueSetterParams<any, any>) => {
                    const origin = initialData.origins.find(dep => dep.id === params.value);
                    if (origin) {
                        params.row.origin = origin;
                    }
                    return params.row;
                }
            },
            // {
            //     ...categoryColumn(errorHandlers, initialData.categories),
            // },
            {
                field: 'category',
                headerName: 'Kategorie',
                width: 200,
                valueGetter: (params: GridValueGetterParams<any, any >) => {
                    console.log('Params Value', params.value)
                    if(params.value) {
                        return params.value.id
                    }
                    return null
                },
                editable: true,
                renderCell:params => {
                    if(params.row.origin.needsCategory) {
                        if(params.row.category) {
                            return params.row.category.name
                        } else {
                            return <Button onClick={() => {
                                setDeliveryToSplit(params.row);
                            }} startIcon={<TbArrowsSplit />} color={'warning'} variant={'contained'} fullWidth >Aufteilen</Button>
                        }

                    } else  {
                        return <span style={{color: "#999", width: '100%',}}>nicht benötigt</span>
                    }

                },

                renderEditCell: params => {
                    return (
                        <OptionalCategoryEditCellRender params={params} initialData={initialData} theme={theme}></OptionalCategoryEditCellRender>
                    )
                    // if (params.row.origin.needsCategory) {
                    //     return <>
                    //         <Select fullWidth>
                    //             {initialData.categories.map(category => {
                    //                 return (
                    //                     <MenuItem value={category.id}>{category.name}</MenuItem>
                    //                 )
                    //             })}
                    //         </Select>
                    //     </>
                    // } else {
                    //     return <span style={{color: theme.palette.text.primary, width:'100%', textAlign:'center'}}>nicht benötigt</span>
                    // }
                }
            },
            {
                field: 'createdAt',
                ...createdAtColumnType
            },
            {
                field: 'updatedAt',
                ...updatedAtColumnType
            },
            {
                field: 'updatedBy',
                ...updatedByColumnType
            },
            {
                field: 'actions',
                ...actionsColumType(rowModesModel, errorHandlers, confirm, setRowModesModel, rows, setRows, apiUrl ,mutation)
            }
        ], [errors, requestParameters, rows, rowModesModel]
    );

    return <>
        {deliveryToSplit && (
            <SplitDeliveryModal categories={initialData.categories} deliveryToSplit={deliveryToSplit} didCancel={() => {
                setDeliveryToSplit(null);
            }} didSplit={(deliveryToSplit, splitDeliveries) => {
                const newRows = [...rows];
                // newRows = newRows.splice(rows.length-2, 0, ...splitDeliveries);
                newRows.push(...splitDeliveries);
                setRows(newRows);
                setRows(newRows.filter(row => row.id !== deliveryToSplit.id));
                setDeliveryToSplit(null);
            }}></SplitDeliveryModal>
        )}
        <ExternalDataGrid summary={summaryText} requestParameters={requestParameters}
                          columns={columns} rows={rows} setRows={rows => setRows(rows as DeliveryDTO[])}
                          errorHandler={errorHandlers} rowModesModel={rowModesModel} setRowModesModel={setRowModesModel}
                          apiRef={apiRef} cloneMethod={DeliveryDTO.clone}
                          initializeNewDummyObject={newRow => {
                              if(requestParameters && requestParameters.shop) {
                                  const nextNewDeliveryDTO = DeliveryDTO.createDummy(newRow.day,null ,initialData.origins[0], requestParameters.shop, currentUser);
                                  console.log('new Dummy!!!!', nextNewDeliveryDTO)
                                  setNextNewDelivery(nextNewDeliveryDTO);
                                  console.log('yes Before', nextNewDeliveryDTO.uuid)
                                  apiRef.current.setCellFocus(nextNewDeliveryDTO.generateRowId(requestParameters.shop), 'amount')
                                  return nextNewDeliveryDTO
                              }
                          }} apiUrl={apiUrl} title={'Anlieferung'}></ExternalDataGrid>
    </>

}