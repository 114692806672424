import {Card, CardContent, Grid} from "@mui/material";
import numeral from "numeral";
import Stack from "@mui/material/Stack";
import {HumanaUtils} from "../../utils/HumanaUtils";
import React, {ReactElement, useEffect, useState} from "react";
import "./BudgetCard.css"
import { ResponsiveBar } from '@nivo/bar';
import {ReportDefaultResponseDTO} from "../../rest-data-provider/customDTOs";
import {dark} from "@mui/material/styles/createPalette";


interface WeekCharCardProps {
    apiData: ReportDefaultResponseDTO
    format: string
    unit: string
}
export const WeekCharCard : React.FC<WeekCharCardProps> = ({apiData, format, unit}) => {

    const [weekdaySum, setWeekdaySum] = useState<any[]>([])

    useEffect(() => {
        if(apiData) {
            if(apiData.currentStatistics.weekly) {
                const weekly = apiData.currentStatistics.weekly

                const weekdays = [];

                for (const [key, value] of Object.entries(weekly)) {
                    // console.log(`${key}: ${value}`);
                    const hourly : any = value;
                    const hours : any = {}
                    hours.weekday = key;
                    for (const [key, value] of Object.entries(hourly)) {

                        hours[key] = (value as any).sum;
                    }
                    weekdays.push(hours)
                }
                // console.log(weekdays);
                setWeekdaySum(weekdays)
            }
        }
    }, [apiData])

    return (
        <Card>
            <CardContent style={{padding: "5px 0px 5px 0px"}}>
                <Grid container spacing={1} style={{padding: 0, paddingTop: 10, paddingBottom: 5}}>
                    <Grid item xs={12} style={{padding: 0, paddingLeft: 20}}>
                        <label  style={{fontWeight: 'bold'}}>Verlauf nach Wochentag und Uhrzeit</label>
                    </Grid>
                    <Grid item style={{height: '400px'}} xs={12}>
                        <ResponsiveBar
                            data={weekdaySum}
                            keys={[
                                '<10',
                                '10-11',
                                '11-13',
                                '13-15',
                                '15-17',
                                '17-19',
                                '19-20',
                                '>20'
                            ]}
                            indexBy="weekday"
                            margin={{ top: 20, right: 20, bottom: 50, left: 120}}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            colors={['#C5CCD3', '#EBC7C7','#D1A4AE', '#A79ABE', '#89A9C2', '#89B5A8', '#C2DEB1', '#FED594']}
                            colorBy={"id"}
                            axisTop={null}
                            axisRight={null}
                            valueFormat={value => {
                                return numeral(value).format(format);
                            }}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Wochentag',
                                legendPosition: 'middle',
                                legendOffset: 30,
                                truncateTickAt: 0,
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                //legend: `Umsatz in ${unit}`,
                                //legendPosition: 'middle',
                                legendOffset: -65,
                                truncateTickAt: 0,
                                format: value => {
                                    return numeral(value).format(format) + "€"
                                }
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        1.5
                                    ]
                                ]
                            }}
                            label={datum => {
                                // console.log(datum)
                                if(datum.data && datum.value) {
                                    const sum: number = Object.values(datum.data).map((a:any) => !isNaN(a) ? a : 0).reduce(function(a:number, b:number)
                                    {
                                        return a + b;
                                    });
                                    return `${((datum.value / sum) * 100).toFixed(0)}%`
                                }
                                return '';

                            }}
                            legends={[
                                {
                                    dataFrom: 'keys',
                                    anchor: 'left',
                                    direction: 'column',
                                    justify: false,
                                    translateX: -107,
                                    translateY: 0,
                                    itemsSpacing: 0,
                                    itemWidth: 55,
                                    itemHeight: 28,
                                    padding: 0,
                                    symbolSpacing: 3,
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 0.85,
                                    symbolSize: 15,
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                            role="application"
                        />
                    </Grid>
                </Grid>

            </CardContent>
        </Card>
    )

}