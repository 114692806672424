import Container from "@mui/material/Container";
import {Alert, CircularProgress, Grid, Link} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {LoginFormTypes, useApiUrl, useLogin} from "@refinedev/core";
import {axiosInstance} from "../../rest-data-provider/utils";
import {useEffect, useState} from "react";
import {validate} from "email-validator";
import {GenerateOTPRequestDTO} from "./generateOTPRequestDTO";
import {LoadingButton} from "@mui/lab";
import {useCountdown} from "usehooks-ts";
import {MuiOtpInput} from "mui-one-time-password-input";
import './login.css'
import {TOKEN_KEY} from "../../authProvider";
import useLocalStorageState from "use-local-storage-state";
import {format, parseISO} from "date-fns";
import { de } from 'date-fns/locale';

export const Login = () => {
    const apiURL = useApiUrl();
    const {mutate} = useLogin<LoginFormTypes>();

    const [email, setEmail] = useLocalStorageState('userEmail', {
        defaultValue: null
    });
    const [error, setError] = useState<string|undefined>()
    const [loading, setLoading] = useState<boolean>(false)
    const [didSentOTP, setDidSentOTP] = useState<boolean>(false);
    const [otp, setOtp] = useState<string>('')
    const [buildDateFormatted, setBuildDateFormatted] = useState('');



    const [timestampSentOtp, setTimestampSentOtp] = useLocalStorageState('timestampSendOtp', {
        defaultValue: 0
    });

    useEffect(() => {
        if(timestampSentOtp) {
            const currentTS = Date.now();
            const timeDiff = currentTS - timestampSentOtp;
            if(timeDiff < (1000 * 60 * 2)) {
                setDidSentOTP(true)
                startCountdown();
            }
        }
        const buildDate = parseISO(BUILD_DATE);
        setBuildDateFormatted(format(buildDate, 'dd.MM.yyyy HH:mm', {locale: de}))
    }, []);


    const [count, { startCountdown, stopCountdown, resetCountdown }] =
        useCountdown({
            countStart: 20,
            intervalMs: 1000,
            countStop: 0,
        })

    const generateOTP = () => {
        setLoading(true)
        requestOTP().finally(() => {
            setTimestampSentOtp(Date.now())
            setLoading(false)
        });
    }

    const requestOTP = async () => {
        try {
            if(email && validate(email)) {
                setError(undefined)
                localStorage.removeItem(TOKEN_KEY);
                delete axiosInstance.defaults.headers.common['Authorization'];
                const response = await axiosInstance.post(apiURL + '/auth/generateOTP', {
                    email: email
                } as GenerateOTPRequestDTO);
                console.log(response)
                setDidSentOTP(true);
                startCountdown();
            } else {
                setError('Die E-Mail-Adresse ist nicht gültig');
            }
        } catch (e:any) {
            console.log(e)
            if(e.statusCode === 401) {
                setError('Die E-Mail-Adresse konnte nicht zugeordnet werden')
            }
        }
    }

    const handleLogin = () => {
        mutate({
            email: email!,
            password: otp
        }, {onSuccess: () => {
            setEmail(null);
            setDidSentOTP(false);
            setOtp('');
            resetCountdown();
            setTimestampSentOtp(0);
        }});

    }

    const handleChangeEmail = (evt:any) => {
        const email = evt.target.value;
        setError(undefined);
        setEmail(email);
    }

    return (
        <Container style={{height: '100vh'}}>
            <Grid container style={{height: '100%', display:'flex', placeItems: 'center', placeContent: 'center'}}>
                <Grid item>
                    <Box style={{
                        borderRadius: 10,
                        padding: '20px',
                        backgroundColor: 'white',
                        maxWidth: '400px',
                        border: '1px solid #004f1e',
                        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px'
                    }}>
                        {/*<img src='images/humana/kassendb_logo.png' style={{width: '100%', height: '200px', objectFit: 'contain'}} />*/}
                        {/*<h1 style={{fontSize: '44px', textAlign: 'center'}}><label style={{color: '#004f1e'}}>KassenDB</label> <label style={{color: 'darkgreen', fontWeight: 'lighter'}}>2.0</label></h1>*/}
                        <h1 style={{
                            fontSize: '40px',
                            margin: '0px',
                            fontWeight: 'bold',
                            paddingRight: '60px',
                            color: '#004f1e',
                            textAlign: 'center'
                        }}>Humana</h1>
                        <h1 style={{
                            fontSize: '60px',
                            color: 'darkgreen',
                            margin: -30,
                            paddingLeft: '50px',
                            paddingTop: 10,
                            textAlign: 'center',
                            paddingBottom: '20px'
                        }}>Portal</h1>
                        {!didSentOTP && (
                            <>
                                <p>Bitte gib deine E-Mail-Adresse ein, um dich an der KassenDB einzuloggen</p>
                                <TextField disabled={loading} onChange={handleChangeEmail} fullWidth={true}
                                           type={"email"} placeholder={'E-Mail-Adresse'} value={email}
                                           error={!!error} helperText={error} onKeyDown={e => {
                                    if (e.key == 'Enter') {
                                        generateOTP()
                                    }
                                }}
                                />
                                <LoadingButton loading={loading} disabled={!email} onClick={() => {
                                    generateOTP();
                                }} fullWidth style={{marginTop: '20px'}} variant={"contained"}>Zugangscode
                                    anfordern</LoadingButton>
                            </>)}
                        {didSentOTP && (
                            <>
                                <Alert severity="info">Wir haben einen Zugangscode an die für <b>{email}</b> geschickt.
                                    Bitte gib diesen Code hier ein.</Alert>
                                <MuiOtpInput gap={1} TextFieldsProps={{
                                    type: 'tel',
                                    inputProps: {className: 'humana-otp'},
                                    inputMode: 'numeric',
                                }} className={'humana-otp'} value={otp} onChange={(value => {
                                    setOtp(value);
                                })} onComplete={value => {
                                    setOtp(value);
                                }} style={{marginTop: '50px', marginBottom: '50px'}} length={6} autoFocus></MuiOtpInput>
                                <LoadingButton onClick={handleLogin} disabled={otp.length < 6} variant={"contained"}
                                               fullWidth loading={loading}>Zugangscode bestätigen</LoadingButton>
                                {count > 0 && (
                                    <Grid container style={{placeItems: 'center', placeContent: 'center'}}>
                                        <Grid item xs={3}>
                                            <CircularProgress variant={'determinate'}
                                                              value={count * 5}></CircularProgress>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <p>Du hast dich vertippt? Warte <b>{count}</b> Sekunden damit du die Adresse
                                                erneut eingeben kannst.</p>
                                        </Grid>
                                    </Grid>


                                )}
                                {count <= 0 && (
                                    <p>Du hast dich vertippt? Klicke hier um die <Link onClick={() => {
                                        setEmail(null);
                                        setDidSentOTP(false);
                                        setOtp('');
                                        resetCountdown();
                                        setTimestampSentOtp(0);
                                    }}>E-Mail-Adresse erneut einzugeben</Link>.</p>
                                )}
                            </>
                        )}
                    </Box>
                    <div style={{marginTop: '8px', color: '#004f1e', textAlign: 'center', fontSize: "small"}}>Version
                        vom <b>{buildDateFormatted}</b></div>
                </Grid>
            </Grid>
        </Container>
        // <AuthPage type={"login"}></AuthPage>
    );
    //   return <AuthPage type="login" />;
};
