import {Create, Edit, useAutocomplete} from "@refinedev/mui";
import {
    Box,
    TextField,
    Autocomplete,
    Checkbox,
    FormControlLabel,
    InputLabel,
    OutlinedInput,
    FormGroup,
    FormLabel,
    FormHelperText,
    Grid,
    Alert,
    Card,
    alpha,
    decomposeColor, useTheme, Switch, Radio, RadioGroup, CardActionArea,
} from "@mui/material";
import {useForm} from "@refinedev/react-hook-form";
import {IResourceComponentsProps, useList, useParsed, useTranslate} from "@refinedev/core";
import {Controller, FieldValue, FieldValues, useWatch} from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React, {useEffect, useLayoutEffect, useMemo, useState} from "react";
import {IDepartment, IRole, IShop, IUnit, IUser, IUserCreate} from "../../rest-data-provider/models";
import {HumanaRoles, HumanaUtils} from "../../utils/HumanaUtils";
import Stack from "@mui/material/Stack";
import {FaUser, FaUserLock} from "react-icons/fa";
import Typography from "@mui/material/Typography";
import {ImUser} from "react-icons/im";

export const UserCreateEdit: React.FC<IResourceComponentsProps> = () => {

    const [roles, setRoles] = useState<IRole[]>([]);
    const [units, setUnits] = useState<IUnit[]>([])
    const [shopsForUnit, setShopsForUnit] = useState<IShop[]>([])
    const [departmentsForShop, setDepartmentsForShop] = useState<IDepartment[]>([]);

    const [showUnitSelection, setShowUnitSelection] = useState(false);
    const [showShopSelection, setShowShopSelection] = useState(false);
    const [showDepartmentSelection, setShowDepartmentSelection] = useState(false);

    const {id} = useParsed();

    const translate = useTranslate();
    const {
        saveButtonProps,
        refineCore: {formLoading, queryResult, onFinish},
        register,
        control,
        formState: {errors},
        watch,
        setValue,
        setError,
        handleSubmit

    } = useForm<IUserCreate>(
        {
            refineCoreProps: {
                onMutationError: (error, variables, context) => {
                    HumanaUtils.extractServerSideErrors(error, setError);
                },
                queryOptions: {select: (data) => {

                    if (typeof data.data.role === 'object') {
                        const roleObj : IRole = data.data.role
                        data.data.role = roleObj.name;
                    }
                    return data;
                }},

            },
            defaultValues: {unit: -1, shop: -1, department: -1, active: true, role: ''},
        }
    );

    const onFinishHandler = (values : FieldValues) => {

        if (values.role === HumanaRoles.ROLE_SHOP_MANAGER) {
            values.department = -1;
            if(!shopsForUnit.find(value => value.id === values.shop)) {
                values.shop = -1;
            }
        } else if(values.role === HumanaRoles.ROLE_UNIT_MANAGER) {
            values.shop = -1;
            values.department = -1;
        } else if(values.role === HumanaRoles.ROLE_DEPARTMENT_MANAGER) {
            if(!shopsForUnit.find(value => value.id === values.shop)) {
                values.shop = -1;
                values.department = -1;
            }
            if(!departmentsForShop.find(value => value.id === values.department)) {
                values.department = -1;
            }
        } else {
            values.unit = -1;
            values.shop = -1;
            values.department = -1;
        }
        return onFinish({...values});
    }

    const selectedUnit = watch('unit');
    const selectedShop = watch('shop');
    const role = watch('role');

    saveButtonProps.onClick = handleSubmit(onFinishHandler);

    const theme = useTheme();

    const rolesResponse = useList<IRole>({resource: 'roles'});
    if (rolesResponse.data && roles.length === 0) {
        setRoles(rolesResponse.data.data);
    }

    const unitResponse = useList<IUnit>({resource: 'locations'});
    if (unitResponse.data && units.length === 0) {
        setUnits(unitResponse.data.data);
    }

    useEffect(() => {
        const unit = units.find(value => value.id === selectedUnit);
        if(unit) {
            setShopsForUnit(unit.shops);
        } else {
            setShopsForUnit([])
        }
    }, [selectedUnit, showShopSelection]);

    useEffect(() => {
        const shop = shopsForUnit.find(value => value.id === selectedShop);
        if(shop) {
            setDepartmentsForShop(shop.departments);
        } else {
            setDepartmentsForShop([])
        }
    }, [selectedShop, showDepartmentSelection]);


    useEffect(() => {
        if(role === HumanaRoles.ROLE_ADMIN || role === HumanaRoles.ROLE_SALES || role === HumanaRoles.ROLE_MANAGEMENT) {
            setShowUnitSelection(false);
            setShowShopSelection(false);
            setShowDepartmentSelection(false);
        }
        if(role === HumanaRoles.ROLE_UNIT_MANAGER) {
            setShowUnitSelection(true);
            setShowShopSelection(false);
            setShowDepartmentSelection(false);
        }
        if(role === HumanaRoles.ROLE_SHOP_MANAGER) {
            setShowUnitSelection(true);
            setShowShopSelection(true);
            setShowDepartmentSelection(false);
        }
        if(role === HumanaRoles.ROLE_DEPARTMENT_MANAGER) {
            setShowUnitSelection(true);
            setShowShopSelection(true);
            setShowDepartmentSelection(true);
        }
    }, [role]);

    const generateForm = () => {
        return (
            <Box
                component="form"
                sx={{display: "flex", flexDirection: "column"}}
                autoComplete="off"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            {...register("firstName")}
                            error={!!(errors as any)?.firstName}
                            helperText={(errors as any)?.firstName?.message}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label={translate("users.fields.firstName")}
                            name="firstName"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            {...register("lastName")}
                            error={!!(errors as any)?.lastName}
                            helperText={(errors as any)?.lastName?.message}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label={translate("users.fields.lastName")}
                            name="lastName"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            {...register("email")}
                            error={!!(errors as any)?.email}
                            helperText={(errors as any)?.email?.message}
                            fullWidth
                            InputLabelProps={{ shrink: true}}
                            type="email"
                            label={translate("users.fields.email")}
                            name="email"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            {...register("phoneNumber")}
                            error={!!(errors as any)?.phoneNumber}
                            helperText={(errors as any)?.phoneNumber?.message}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="tel"
                            label={translate("users.fields.phoneNumber")}
                            name="phoneNumber"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {roles.length > 0 && (
                            <Controller
                                control={control}
                                name="role"
                                render={({field, fieldState}) => (
                                    <Box>
                                        <FormControl sx={{m: 1}} component="fieldset" variant="standard" error={!!(errors as any)?.role}>
                                            <FormLabel component="label">Zugewiesene Rolle</FormLabel>
                                            <RadioGroup name={'role'} value={field.value}>
                                                <Stack direction={"row"} flexWrap={"wrap"}>
                                                    {roles.map(value => {
                                                        return (
                                                            <FormControlLabel
                                                                key={`roles-${value.name}`}
                                                                control={<Radio value={value.name}
                                                                    onChange={event => {
                                                                        if (event.target.checked && (!field.value || field.value.name !== value.name)) {
                                                                            field.onChange(value.name)
                                                                        }
                                                                    }}/>}
                                                                label={value.displayName}
                                                            />
                                                        )
                                                    })}
                                                </Stack>
                                            </RadioGroup>
                                            <FormHelperText>{(errors as any)?.role?.message}</FormHelperText>
                                        </FormControl>
                                    </Box>
                                )}
                            />
                        )}

                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Box>
                            {units.length > 0 && (
                                <Controller
                                    control={control}
                                    name="unit"
                                    defaultValue={[] as any}
                                    render={({field, fieldState}) => {

                                        let selectedValue = field.value;
                                        if (!units.find(value => selectedValue === value.id)) {
                                            selectedValue = -1;
                                        }
                                        return(
                                            <FormControl disabled={!showUnitSelection} fullWidth error={!!(errors as any)?.unit}>
                                                <InputLabel shrink={true}
                                                            id="select-unit-label">{translate("users.fields.unit")}</InputLabel>
                                                <Select
                                                    labelId="select-unit-label"
                                                    id="select-unit"
                                                    notched={true}
                                                    value={selectedValue}
                                                    label={translate('users.fields.unit')}
                                                    onChange={event => {
                                                        field.onChange(event.target.value);
                                                    }}
                                                >
                                                    <MenuItem key={'unit-x'} value={-1}>Bitte Gebiet auswählen</MenuItem>
                                                    {units.map(value => {
                                                        return (
                                                            <MenuItem key={`unit-${value.id}`}
                                                                      value={value.id}>{value.name}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                                <FormHelperText>{(errors as any)?.unit?.message}</FormHelperText>
                                            </FormControl>
                                        )
                                    }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {units.length > 0 && (
                            <Controller
                                control={control}
                                name="shop"
                                defaultValue={[] as any}
                                render={({field, fieldState}) => {
                                    let selectedValue = field.value;
                                    if (!shopsForUnit.find(value => selectedValue === value.id)) {
                                        selectedValue = -1;
                                    }
                                    return (
                                        <FormControl disabled={!showShopSelection} fullWidth error={!!(errors as any)?.shop}>
                                            <InputLabel shrink={true}
                                                        id="select-shop-label">{translate("users.fields.shop")}</InputLabel>
                                            <Select
                                                labelId="select-shop-label"
                                                id="select-shop"
                                                notched={true}
                                                value={selectedValue}
                                                label={translate('users.fields.shop')}
                                                onChange={event => {
                                                    field.onChange(event.target.value);
                                                }}
                                            >
                                                <MenuItem key={`shop-x`}
                                                          value={-1} >Bitte Filiale auswählen</MenuItem>
                                                {shopsForUnit.map((value : IShop)=> {
                                                    return (
                                                        <MenuItem key={`shop-${value.id}`}
                                                                  value={value.id}>{value.name} (<b>{value.shortName}</b>)</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                            <FormHelperText>{(errors as any)?.shop?.message}</FormHelperText>
                                        </FormControl>
                                    )
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {units.length > 0 && (
                            <Controller
                                control={control}
                                name="department"
                                defaultValue={[] as any}
                                render={({field, fieldState}) =>
                                {
                                    let selectedValue = field.value;
                                    if (!departmentsForShop.find(value => selectedValue === value.id)) {
                                        selectedValue = -1;
                                    }
                                    return (
                                    <FormControl disabled={!showDepartmentSelection} fullWidth error={!!(errors as any)?.department}>
                                        <InputLabel shrink={true} id="select-department-label">{translate("users.fields.department")}</InputLabel>
                                        <Select
                                            labelId="select-department-label"
                                            id="select-department"
                                            notched={true}
                                            value={selectedValue}
                                            label={translate('users.fields.department')}
                                            onChange={event => {
                                                field.onChange(event.target.value);
                                            }}
                                        >
                                            <MenuItem key={`department-x`} value={-1} >Bitte Abteilung auswählen</MenuItem>
                                            {departmentsForShop.map((value : IDepartment)=> {
                                                return (
                                                    <MenuItem key={`department-${value.id}`}
                                                              value={value.id}>{value.name} (<b>{value.departmentNumber}</b>)</MenuItem>
                                                )
                                            })}
                                        </Select>
                                        <FormHelperText>{(errors as any)?.department?.message}</FormHelperText>
                                    </FormControl>
                                )}}
                            />
                        )}
                    </Grid>

                    <Grid xs={12} item>
                        <Controller
                            control={control}
                            name="active"
                            // eslint-disable-next-line
                            render={({field}) => (
                                <Card elevation={0} sx={{p:0}}>
                                    <CardActionArea  sx={{backgroundColor: alpha(field.value ? theme.palette.info.main : theme.palette.error.main, 0.2), p: 1, paddingLeft: 2, border: '1px solid',
                                        borderColor: field.value ? theme.palette.info.light : theme.palette.error.main, display: 'flex', align: 'center'}} onClick={() => {
                                        field.onChange(!field.value);
                                    }}>
                                        {field.value && (
                                            <FaUser color={theme.palette.info.main} style={{padding: 0, fontSize: 22, paddingTop: 0}}></FaUser>
                                        )}
                                        {!field.value && (
                                            <FaUserLock color={theme.palette.error.main} style={{padding: 0, fontSize: 28, paddingTop: 0}}></FaUserLock>
                                        )}
                                        <Typography style={{paddingLeft: '10px', lineHeight: '36px'}}>
                                            {field.value ? 'Der Benutzer ist aktiv und kann sich jederzeit im Portal anmelden.' : "Der Benutzer ist gesperrt und kann sich nicht im Portal anmelden"}
                                        </Typography>
                                        <div style={{marginLeft: 'auto'}}>
                                            <Switch onChange={event => {
                                                field.onChange(event.target.checked)
                                            }} checked={field.value} color={'info'} />
                                        </div>
                                    </CardActionArea>
                                </Card>
                            )}
                        />
                    </Grid>
                </Grid>
                {/*
                    DatePicker component is not included in "@refinedev/mui" package.
                    To use a <DatePicker> component, you can follow the official documentation for Material UI.
                    Docs: https://mui.com/x/react-date-pickers/date-picker/#basic-usage
                */}
            </Box>
        )
    }

    return (
        <>
            {id && (
                <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
                    {generateForm()}
                </Edit>
            )}
            {!id && (
                <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
                    {generateForm()}
                </Create>
            )}
        </>


    );
};
