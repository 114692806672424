import React, { ReactElement, JSXElementConstructor } from "react";
import {IUser, IShop, IDepartment} from "../../../rest-data-provider/models";
import {BaseExternalData} from "./BaseExternalData";
import {IDelivery, IDeliveryOrigin, IGenericCategory, IRestock} from "./model";
import {v4 as uuidv4} from "uuid";
import {format, parseISO} from "date-fns";

export class DeliveryDTO extends BaseExternalData {
    public id: number|undefined
    public day: Date|null
    public amount:number
    public category:IGenericCategory|null
    public origin: IDeliveryOrigin
    public wasSplit: boolean
    public shop: IShop
    public createdAt: string|null
    public updatedAt: string|null
    public updatedBy: IUser|null
    public uuid?: string|undefined

    constructor(id: number | undefined, day: Date | null, amount=0, category: IGenericCategory|null, origin: IDeliveryOrigin, wasSplit:boolean, shop: IShop, createdAt: string | null,
                updatedAt: string | null, updatedBy: IUser | null, uuid?: string) {
        super()
        this.id = id;
        this.day = day;
        this.amount =  amount;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.updatedBy = updatedBy;
        this.uuid = uuid;
        this.category = category;
        this.origin = origin;
        this.wasSplit = wasSplit;
        this.shop = shop;
    }

    public static clone(baseExternalData: BaseExternalData): BaseExternalData {
        const delivery = baseExternalData as DeliveryDTO;
        return new DeliveryDTO(
            delivery.id,
            delivery.day,
            delivery.amount,
            delivery.category,
            delivery.origin,
            delivery.wasSplit,
            delivery.shop,
            delivery.createdAt,
            delivery.updatedAt,
            delivery.updatedBy,
            delivery.uuid,
        )
    }

    public clone():DeliveryDTO  {
        return new DeliveryDTO(
            this.id,
            this.day,
            this.amount,
            this.category,
            this.origin,
            this.wasSplit,
            this.shop,
            this.createdAt,
            this.updatedAt,
            this.updatedBy,
            this.uuid,
        )
    }

    public cloneWithNewUuid(): DeliveryDTO {
        const clone =  this.clone();
        clone.uuid = uuidv4();
        return clone;
    }

    public static generateSummary (restockList : DeliveryDTO[])   {
        console.log('rows', restockList);
        const sum = restockList.map(value => {
            return value.amount;
        }).reduce((previousValue, currentValue) => {
            return previousValue + currentValue;
        }, 0);
        return (sum + " kg")
    }

    public generateRowId(shop: IShop|undefined|null = null):string {
        if(this.uuid) {
            return `new-${this.uuid}`
        }
        if(shop) {
            return `${shop.id}-${this.id}`;
        }
        return `${this.id}`;
    }

    static createDummy(dateTo : Date|null, category:IGenericCategory|null, origin: IDeliveryOrigin, shop: IShop, currentUser : IUser): DeliveryDTO {
        return new DeliveryDTO(undefined, dateTo, 0, category , origin, false, shop, null,null, currentUser, uuidv4())
    }

    static fromResponse(response:any) {
        return new DeliveryDTO(
            response.id,
            parseISO(response.day),
            response.amount,
            response.category,
            response.origin,
            response.wasSplit,
            response.shop,
            response.createdAt,
            response.updatedAt,
            response.updatedBy,
        )
    }

    public toRequest() : IDelivery {
        return {
            id: this.id,
            day: this.day ? format(this.day, 'yyyy-MM-dd') : undefined,
            amount: this.amount,
            category: this.category,
            shop: this.shop,
            origin: this.origin,
        }
    }

    public equals(other: {id: number|undefined, uuid?: string|undefined }) : boolean {
        if(this.id && this.id === other.id) {
            return true;
        }
        if(this.uuid && this.uuid === other.uuid) {
            return true;
        }
        return false;
    }

    public updateFromResponse(response:any) {
        this.id = response.id
        this.day = parseISO(response.day);
        this.amount = response.amount;
        this.category = response.category;
        this.origin = response.origin;
        this.shop =  response.shop;
        this.createdAt = response.createdAt;
        this.updatedAt = response.updatedAt;
        this.updatedBy = response.updatedBy;
    }

    generateDeleteConfirmation(): ReactElement {
        if(!this.day) {
            return <span>Error</span>;
        }
        return <span>Soll der Eintrag vom <b>{format(this.day, "dd.MM.yyyy")}</b> wirklich gelöscht werden?</span>;
    }



}