import {IDepartment, IShop, IUser} from "../../../rest-data-provider/models";
import {formatDuration} from "../external-data-grid/GridComponents";
import {v4 as uuidv4} from "uuid";
import {format, parseISO} from "date-fns";
import {IWorkTime} from "./model";
import {BaseExternalData} from "./BaseExternalData";
import {id} from "date-fns/locale";
import React, {ReactElement} from "react";

export class WorkTimeDTO extends BaseExternalData {
    public id: number|undefined
    public day: Date|null
    public workTimeMinutes: number
    public department: IDepartment
    public createdAt: string|null
    public updatedAt: string|null
    public updatedBy: IUser|null
    public uuid?: string|undefined


    constructor(id: number | undefined, day: Date | null, workTimeMinutes: number, department: IDepartment, createdAt: string | null, updatedAt: string | null, updatedBy: IUser | null, uuid?: string) {
        super();
        this.id = id;
        this.day = day;
        this.workTimeMinutes = workTimeMinutes;
        this.department =  department;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.updatedBy = updatedBy;
        this.uuid = uuid;
    }

    public static clone(baseExternalData: BaseExternalData): BaseExternalData {
        const workTimeDTO = baseExternalData as WorkTimeDTO;
        // console.error('in clone', workTimeDTO.department)
        return new WorkTimeDTO(
            workTimeDTO.id,
            workTimeDTO.day,
            workTimeDTO.workTimeMinutes,
            workTimeDTO.department,
            workTimeDTO.createdAt,
            workTimeDTO.updatedAt,
            workTimeDTO.updatedBy,
            workTimeDTO.uuid,
        )
    }

    public clone():WorkTimeDTO  {

        return new WorkTimeDTO(
            this.id,
            this.day,
            this.workTimeMinutes,
            this.department,
            this.createdAt,
            this.updatedAt,
            this.updatedBy,
            this.uuid,
        )
    }

    public static generateSummary (workTimeList : WorkTimeDTO[])   {
        console.log('rows', workTimeList);
        const sum = workTimeList.map(value => {
            return value.workTimeMinutes;
        }).reduce((previousValue, currentValue) => {
            return previousValue + currentValue;
        }, 0);
        return (formatDuration(sum) + " Std.")
    }

    public generateRowId(shop: IShop|undefined|null = null):string {
        if(this.uuid) {
            return `new-${this.uuid}`
        }
        if(shop) {
            return `${shop.id}-${this.id}`;
        }
        return `${this.id}`;
    }

    static createDummy(department : IDepartment, dateTo : Date|null, currentUser : IUser): WorkTimeDTO {
        return new WorkTimeDTO(undefined, dateTo, 0, department, null, null, currentUser, uuidv4())
    }

    static fromResponse(response:any) {
        return new WorkTimeDTO(
            response.id,
            parseISO(response.day),
            response.workTimeMinutes,
            response.department,
            response.createdAt,
            response.updatedAt,
            response.updatedBy,
        )
    }

    public toRequest() : IWorkTime {
        return {
            id: this.id,
            day: this.day ? format(this.day, 'yyyy-MM-dd') : undefined,
            workTimeMinutes: this.workTimeMinutes,
            department: this.department,
        }
    }

    public equals(otherWorktime: {id: number|undefined, uuid?: string|undefined }) : boolean {
        if(this.id && this.id === otherWorktime.id) {
            return true;
        }
        if(this.uuid && this.uuid === otherWorktime.uuid) {
            return true;
        }
        return false;
    }

    public updateFromResponse(response:any) {
        this.id = response.id
        this.day = parseISO(response.day);
        this.workTimeMinutes = response.workTimeMinutes;
        this.department =  response.department;
        this.createdAt = response.createdAt;
        this.updatedAt = response.updatedAt;
        this.updatedBy = response.updatedBy;
    }

    generateDeleteConfirmation(): ReactElement {
        if(!this.day) {
            return <span>Error</span>;
        }
        return <span>Soll der Eintrag vom <b>{format(this.day, "dd.MM.yyyy")}</b> für die Abteilung <b>{this.department.name}</b> wirklich gelöscht werden?</span>;
    }
}