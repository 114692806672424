import React, {useEffect, useState} from "react";
import {
    Alert, Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid, useTheme
} from "@mui/material";
import {DeliveryDTO} from "../model/DeliveryDTO";
import {format} from "date-fns";
import List from "@mui/material/List";
import {SplitDeliveryLine} from "./SplitDeliveryLine";
import {IDelivery, IGenericCategory} from "../model/model";
import {TbArrowsSplit} from "react-icons/tb";
import {on} from "process";
import {useCustomMutation} from "@refinedev/core";


interface SplitDeliveryModalProps {
    deliveryToSplit: DeliveryDTO
    didCancel:() => void
    categories: IGenericCategory[]
    didSplit:(deliveryToSplit: DeliveryDTO, splitDeliveries : DeliveryDTO[]) => void
}
export const SplitDeliveryModal : React.FC<SplitDeliveryModalProps> = ({deliveryToSplit, didCancel, categories, didSplit})  => {

    const [open, setOpen] = useState(false)
    const [amountDifference, setAmountDifference] = useState(0);
    const [splitDeliveries, setSplitDeliveries] = useState<DeliveryDTO[]>([])
    const theme = useTheme();
    const [valid, setValid] = useState(false)

    const {mutateAsync} = useCustomMutation<IDelivery[]>()



    const onChange = (delivery: DeliveryDTO|null) => {
        let amount = 0;
        let valid = true;
        for (const splitDelivery of splitDeliveries) {
            if(splitDelivery.category) {
                amount += splitDelivery.amount
                if(splitDelivery.amount == 0 || !splitDelivery.category) {
                    valid = false
                }
            }
        }
        const amountDif = deliveryToSplit.amount - amount;

        if(amountDif > 0 && splitDeliveries.length > 0 && splitDeliveries[splitDeliveries.length-1].amount > 0 && splitDeliveries[splitDeliveries.length-1].category) {
            const clone = deliveryToSplit.cloneWithNewUuid()
            clone.amount = 0;
            setSplitDeliveries([...splitDeliveries, clone])
        }
        setValid(valid);
        setAmountDifference(amountDif);
    }

    useEffect(() => {
        // onChange(null);
    }, [splitDeliveries]);

    const onDelete = (delivery: DeliveryDTO) => {
        // alert('DElete amount: ' + delivery.amount);

        console.log(delivery.uuid);
        console.log(splitDeliveries.map(value => value.uuid));

        const remainingDeliveries = splitDeliveries.filter(value => value.uuid != delivery.uuid);
        console.log('delete', remainingDeliveries);
        // setSplitDeliveries(splitDeliveries.filter(value => value.uuid != delivery.uuid));
        setSplitDeliveries(remainingDeliveries);
    }

    useEffect(() => {
        if(deliveryToSplit) {
            setOpen(true);
            const clone = deliveryToSplit.cloneWithNewUuid()
            clone.amount = 0;
            setSplitDeliveries([clone])
        }
        setAmountDifference(amountDifference);
    }, [deliveryToSplit]);

    const save = async () => {
        const deliveriesToSend = splitDeliveries.filter(value => {
            if(value.category && value.amount && value.amount > 0) {
                return true;
            }
            return false;
        })

        const result= await mutateAsync({
            url: `decentral/delivery/${deliveryToSplit.id}/split`,
            method: 'post',
            values: deliveriesToSend.map(value => {
                return value.toRequest();
            })
        });
        const dtos = result.data.map(value => {
            return DeliveryDTO.fromResponse(value);
        })
        didSplit(deliveryToSplit, dtos)
    }

    return (
        <Dialog fullWidth={true} open={open} onClose={didCancel}>
            <DialogTitle><b>Anlieferung aufteilen</b></DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={4}>
                        <span style={{display:'block'}}>Herkunft</span>
                        <span><b>{deliveryToSplit.origin.name}</b></span>
                    </Grid>
                    <Grid item xs={4} style={{textAlign:'left'}}>
                        <span style={{display: 'block'}}>Menge</span>
                        <span><b>{deliveryToSplit.amount} kg</b></span>
                    </Grid>
                    <Grid item xs={4} style={{textAlign:'right'}}>
                        <span style={{display: 'block'}}>Lieferdatum</span>
                        <span><b>{deliveryToSplit.day ? format(deliveryToSplit!.day, 'dd.MM.yyyy') : '-'}</b></span>
                    </Grid>
                </Grid>
                <Divider flexItem style={{borderColor: theme.palette.text.primary, marginTop: 10, marginBottom: 0, borderWidth: '1px', borderStyle: 'dashed'}}></Divider>
                <List sx={{marginTop: '20px', marginBottom: '20px'}}>
                    {splitDeliveries.map((deliveryDTO: DeliveryDTO, index) => {
                        return (
                            <SplitDeliveryLine delivery={deliveryDTO} isLast={splitDeliveries.length === (index+1)}
                                               categories={categories} onChange={onChange} onDelete={onDelete} sequentialNumber={index+1} />
                        )
                    })}
                </List>
                <Divider flexItem style={{borderColor: theme.palette.text.primary, marginTop: 0, marginBottom: 15, borderWidth: '1px', borderStyle: 'dashed'}}></Divider>
                {amountDifference != 0 && (
                    <Alert severity={amountDifference > 0 ? 'warning' : 'error'} sx={{'.MuiAlert-message': { width: '100%', fontSize: '16px'}}}>
                        <Grid container style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', width:'100%'}}>
                            <Grid item xs={9}>
                                Differenz zur Gesamtmenge der Lieferung:
                            </Grid>
                            <Grid item xs={3} style={{textAlign: 'right'}}>
                                <span><b>{amountDifference.toFixed(2)} kg</b></span>
                            </Grid>
                        </Grid>
                    </Alert>
                )}
                {amountDifference == 0 && (
                    <Button disabled={!valid} size={'large'} fullWidth variant={'contained'} color={'primary'} startIcon={<TbArrowsSplit />} onClick={save}>Lieferung aufteilen</Button>
                )}
            </DialogContent>
        </Dialog>
    )
}