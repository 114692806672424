import React, {useCallback, useState} from "react";
import {WorkTimeDTO} from "../model/WorkTimeDTO";
import {
    DataGrid, GridApi, GridColDef,
    GridRowEditStopParams,
    GridRowEditStopReasons, GridRowModesModel, GridRowsProp,
    GridSortDirection,
    MuiEvent,
    useGridApiRef
} from "@mui/x-data-grid";
import {Grid, useTheme} from "@mui/material";
import {ExternalDataProps, ExternalDataRequest, IWorkTime} from "../model/model";
import {ErrorHandler} from "../error-handling/ApiErrorHook";
import {GridApiCommunity} from "@mui/x-data-grid/internals";
import {BaseExternalData} from "../model/BaseExternalData";
import {ApiError} from "../error-handling/ApiError";
import {BaseRecord, useCustomMutation} from "@refinedev/core";


export interface ExternalDataGridProps<T extends BaseExternalData> {
    summary: string
    requestParameters: ExternalDataRequest
    // processRowUpdate: (newRow: T, oldROw: T) => Promise<T>|T
    columns: GridColDef<any>[]
    readonly rows: GridRowsProp<any>,
    setRows: (rows: GridRowsProp<T>) => void
    errorHandler: ErrorHandler,
    rowModesModel: GridRowModesModel|undefined
    setRowModesModel: (rowModesModel : GridRowModesModel) => void
    apiRef: React.MutableRefObject<GridApiCommunity>
    cloneMethod:(objectToClone: BaseExternalData) => BaseExternalData
    initializeNewDummyObject: (newRow: BaseExternalData) => T|undefined,
    apiUrl: string
    title: string
}
export function ExternalDataGrid<T extends BaseExternalData, R extends BaseRecord, > ({summary, requestParameters,
                                                                 columns, rows, errorHandler, rowModesModel,
                                                                 setRowModesModel, apiRef, cloneMethod,
                                                                  setRows, initializeNewDummyObject, apiUrl, title
                                                            }: ExternalDataGridProps<T>) {
    const {mutateAsync} = useCustomMutation<R>({});
    const {deleteError, errors, addError} = errorHandler;
    const theme = useTheme();
    // const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>();
    const dataGridInitialState = React.useMemo(() => {
        return {
            sorting: {
                sortModel: [{ field: 'day', sort: 'asc' as GridSortDirection }],
            },
            columns: {
                columnVisibilityModel: {
                    // Hide columns status and traderName, the other columns will remain visible
                    createdAt: false,
                    updatedAt: false,
                },
            },
        };
    }, []);


    const processRowUpdate = useCallback(async (
                    newRow: BaseExternalData, oldRow: BaseExternalData) => {
        // newRow = new WorkTimeDTO(newRow.id, newRow.day, newRow.workTimeMinutes, newRow.department,
        //     newRow.createdAt, newRow.updatedAt, newRow.updatedBy, newRow.uuid);
        newRow = cloneMethod(newRow);
        const errorId = newRow.id ? `${requestParameters.shop?.id}-${newRow.id}` : `new-${newRow.uuid}`;
        const url = newRow.id ? `${apiUrl}/${oldRow.id}` : apiUrl;
        const method = newRow.id ? 'put' : 'post';

        const result = await mutateAsync( {
            url: url,
            method: method,
            values: newRow.toRequest()
        }, {
            onError: (error) => {
                const apiErrors = error.response.data.violations.map((value: any) => {
                    return new ApiError(value.fieldName, value.message);
                });
                addError(errorId, apiErrors);
            },
            onSuccess: (data) => {
                deleteError(errorId)
                const newRows = rows.map(value => {
                    if(value.equals({id: data.data.id, uuid: newRow.uuid})) {
                        value.updateFromResponse(data.data);
                    }
                    return value;
                })
                if(method === 'post') {

                     const newDummy = initializeNewDummyObject(newRow);
                     newRows.push(newDummy);
                }
                setRows(newRows);
            }
        })
        newRow.updateFromResponse(result.data)
        return newRow;
    },[mutateAsync, errors, rows]);

    const dataGridCustomFooter = React.useMemo(() => {
        return (
            <Grid container>
                <Grid item xs={12} style={{padding: 10, paddingLeft: '30px', display: 'flex', placeContent: 'start', borderTop:'1px solid lightgrey'}}>
                    <label style={{fontSize: '18px'}}>{title} Gesamt: <b>{summary}</b></label>
                </Grid>
            </Grid>
        )
    }, [summary])
    return (
        <>
            {
                <DataGrid
                    slotProps={{
                        baseSelect: {
                            sx: {
                                fontSize: '14px',
                                '.MuiOutlinedInput-notchedOutline': {borderStyle: 'none', borderRadius: '0px'},
                                '&.MuiOutlinedInput-root.Mui-focused': {
                                    boxShadow: 'inset 0 0 2px #000',
                                    borderRadius: 0
                                }
                            }
                        }
                    }}
                    apiRef={apiRef}
                    initialState={dataGridInitialState}
                    slots={{
                        footer: props => dataGridCustomFooter,
                    }}
                    onRowModesModelChange={rowModesModel => {
                        setRowModesModel(rowModesModel);
                    }}
                    getRowId={row => {
                        if(!row.generateRowId) {
                            row = cloneMethod(row);
                        }
                        return row.generateRowId(requestParameters.shop);
                    }}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={(error) => {
                        console.log('Errors I', error)
                    }}
                    getRowClassName={params => {
                        if(!(params.row as T).id) {
                            return 'row-new';
                        }
                        return '';
                    }}
                    disableRowSelectionOnClick={true}
                    rowModesModel={rowModesModel} showColumnVerticalBorder={true} showCellVerticalBorder={true} density={'standard'}
                    editMode={'row'} autoHeight={false} sx={{ '--DataGrid-overlayHeight': '300px', minWidth: 0, maxHeight:'100%',minHeight:'0px'}}
                    columns={columns} rows={rows}
                    onRowEditStop={(params: GridRowEditStopParams, event: MuiEvent) => {
                        if(params.reason === GridRowEditStopReasons.escapeKeyDown && !params.row.id) {
                            event.defaultMuiPrevented = true
                            return;
                        }
                        // if(params.reason === 'enterKeyDown') {
                        //     for (const column of params.columns) {
                        //         if(column.field === params.field) {
                        //             if(column.type === 'singleSelect') {
                        //                 event.defaultMuiPrevented = true
                        //                 return;
                        //             }
                        //         }
                        //     }
                        // }
                        if (params.reason === GridRowEditStopReasons.escapeKeyDown) {
                            deleteError(`${params.id}`);
                        }
                        if(params.reason === GridRowEditStopReasons.rowFocusOut) {
                            event.defaultMuiPrevented = true
                        }
                    }}
                />
            }
        </>
    )
}