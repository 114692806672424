import {AccessControlProvider, CanParams, CanReturnType, useGetIdentity} from "@refinedev/core";
import {authProvider} from "./authProvider";
import {IRole} from "./rest-data-provider/models";

export class userData {
    static role : IRole|null = null;
}

export const HumanaAccessControlProvider : AccessControlProvider = {
    can: async ({ resource, action, params}: CanParams): Promise<CanReturnType> => {

        if(authProvider && authProvider.getIdentity && userData.role === null) {
            const user:any = await authProvider.getIdentity();
            if(user === null) {
                return {can: false};
            }
            userData.role = user.role;
        }

        if(userData.role) {
            console.log(`Role: ${JSON.stringify(userData.role)} / Resource: ${resource}`)
            if(resource === 'users' || resource === 'administration'
                || resource === 'external-data-admin' || resource === 'external-data') {
                return {
                    can: userData.role.name === 'admin',
                };
            }
        }

        if(resource === 'dashboard') {
            return {
                can: true
            }
        }

        return { can: false };
    },
};