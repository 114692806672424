import {IDepartment, IShop, IUser} from "../../../rest-data-provider/models";
import {formatDuration} from "../external-data-grid/GridComponents";
import {v4 as uuidv4} from "uuid";
import {format, parseISO} from "date-fns";
import {IGenericCategory, IRestock, IWorkTime} from "./model";
import {BaseExternalData} from "./BaseExternalData";
import React, {ReactElement} from "react";

export class RestockDTO extends BaseExternalData {
    public id: number|undefined
    public day: Date|null
    public count:number
    public category:IGenericCategory|null
    public department: IDepartment
    public createdAt: string|null
    public updatedAt: string|null
    public updatedBy: IUser|null
    public uuid?: string|undefined

    constructor(id: number | undefined, day: Date | null, count=0, category: IGenericCategory|null, department: IDepartment, createdAt: string | null,
                updatedAt: string | null, updatedBy: IUser | null, uuid?: string) {
        super()
        this.id = id;
        this.day = day;
        this.department =  department;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.updatedBy = updatedBy;
        this.uuid = uuid;
        this.count = count;
        this.category = category;
    }

    public static clone(baseExternalData: BaseExternalData): BaseExternalData {
        const restockDTO = baseExternalData as RestockDTO;
        return new RestockDTO(
            restockDTO.id,
            restockDTO.day,
            restockDTO.count,
            restockDTO.category,
            restockDTO.department,
            restockDTO.createdAt,
            restockDTO.updatedAt,
            restockDTO.updatedBy,
            restockDTO.uuid,
        )
    }



    public clone():RestockDTO  {
        return new RestockDTO(
            this.id,
            this.day,
            this.count,
            this.category,
            this.department,
            this.createdAt,
            this.updatedAt,
            this.updatedBy,
            this.uuid,
        )
    }

    public static generateSummary (restockList : RestockDTO[])   {
        console.log('rows', restockList);
        const sum = restockList.map(value => {
            return value.count;
        }).reduce((previousValue, currentValue) => {
            return previousValue + currentValue;
        }, 0);
        return (sum + " Stk.")
    }

    public generateRowId(shop: IShop|undefined|null = null):string {
        if(this.uuid) {
            return `new-${this.uuid}`
        }
        if(shop) {
            return `${shop.id}-${this.id}`;
        }
        return `${this.id}`;
    }

    static createDummy(department : IDepartment, dateTo : Date|null, category:IGenericCategory|null, currentUser : IUser): RestockDTO {
        return new RestockDTO(undefined, dateTo, 0, category ,department, null, null, currentUser, uuidv4())
    }

    static fromResponse(response:any) {
        return new RestockDTO(
            response.id,
            parseISO(response.day),
            response.count,
            response.category,
            response.department,
            response.createdAt,
            response.updatedAt,
            response.updatedBy,
        )
    }

    public toRequest() : IRestock {
        return {
            id: this.id,
            day: this.day ? format(this.day, 'yyyy-MM-dd') : undefined,
            count: this.count,
            category: this.category,
            department: this.department,
        }
    }

    public equals(other: {id: number|undefined, uuid?: string|undefined }) : boolean {
        if(this.id && this.id === other.id) {
            return true;
        }
        if(this.uuid && this.uuid === other.uuid) {
            return true;
        }
        return false;
    }

    public updateFromResponse(response:any) {
        this.id = response.id
        this.day = parseISO(response.day);
        this.count = response.count;
        this.category = response.category;
        this.department =  response.department;
        this.createdAt = response.createdAt;
        this.updatedAt = response.updatedAt;
        this.updatedBy = response.updatedBy;
    }

    generateDeleteConfirmation(): ReactElement {
        if(!this.day) {
            return <span>Error</span>;
        }
        return <span>Soll der Eintrag vom <b>{format(this.day, "dd.MM.yyyy")}</b> für die Abteilung <b>{this.department.name}</b> wirklich gelöscht werden?</span>;
    }

}