import DarkModeOutlined from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlined from "@mui/icons-material/LightModeOutlined";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {useGetIdentity, useGetLocale, useLogout, useParsed, useSetLocale} from "@refinedev/core";
import { HamburgerMenu, RefineThemedLayoutV2HeaderProps } from "@refinedev/mui";
import i18n from "i18next";
import React, {useContext, useState} from "react";
import { ColorModeContext } from "../../contexts/color-mode";
import {Button, Fab, Grid, InputAdornment, ListItemIcon, ListItemText, Menu, TextField, useTheme} from "@mui/material";
import {Add, ContentCut, Logout} from "@mui/icons-material";
import {LocationChooser} from "../custom/LocationChooser/LocationChooser";
import {FaMapLocationDot} from "react-icons/fa6";
import {TbSelector} from "react-icons/tb";
import {ILocation} from "../../rest-data-provider/models";
import {LocationContext} from "../custom/LocationChooser/LocationContext";

type IUser = {
  id: number;
  name: string;
  avatar: string;
};

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({
  sticky = true,
}) => {
  const { mode, setMode } = useContext(ColorModeContext);
  const { data: user } = useGetIdentity<IUser>();
  // const [selectedLocation, setSelectedLocation] = useState<ILocation|null>(null)
  const [openLocationChooser, setOpenLocationChooser] = useState<boolean>(false);

  const [location, setLocation] = useContext(LocationContext);

  console.log(location?.name)

  const {resource} = useParsed();


  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const changeLanguage = useSetLocale();
  const locale = useGetLocale();
  const currentLocale = locale();
  const theme = useTheme();

  const { mutate: logout } = useLogout();

  const stringToColor = (string: string) =>  {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  return (
    <AppBar position={sticky ? "sticky" : "relative"}>
      <Toolbar>
        <Stack direction="row" width="100%" alignItems="center">
          <HamburgerMenu />
          <Stack
            direction="row"
            width="100%"
            justifyContent="flex-end"
            alignItems="center"
            gap="16px"
          >
              {resource?.name == 'dashboard' && !openLocationChooser &&(
                  <Grid container style={{width: '100%', display: 'flex', placeContent: 'center'}}>
                      <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
                          <TextField fullWidth={true} focused={false} variant={'outlined'}   size={'small'}
                                     InputProps={{readOnly: true,
                                         startAdornment: (
                                             <InputAdornment position={'start'} style={{backgroundColor: 'white'}}>
                                                 <FaMapLocationDot size={20} style={{color: theme.palette.primary.main}}></FaMapLocationDot>
                                             </InputAdornment>
                                         ),
                                         endAdornment: (
                                             <InputAdornment position="end" style={{padding: 0}}>
                                                 <IconButton edge="end" color="primary">
                                                     <TbSelector size={20} color={'gray'} />
                                                 </IconButton>
                                             </InputAdornment>
                                         ),
                                         sx: {
                                             backgroundColor: 'white'
                                         },
                                     }}
                                     value={location ? location.shortNameGlobalContext : '-' }
                                     sx={{input: {cursor: 'default'}}}
                                     onClick={() => {
                                         setOpenLocationChooser(true)
                                     }}
                          ></TextField>
                      </Grid>
                  </Grid>
              )}
            {(user?.avatar || user?.name) && (
              <Stack
                direction="row"
                gap="16px"
                alignItems="center"
                justifyContent="center"
              >
                {/*{user?.name && (*/}
                {/*  <Typography*/}
                {/*    sx={{*/}
                {/*      display: {*/}
                {/*        xs: "none",*/}
                {/*        sm: "inline-block",*/}
                {/*      },*/}
                {/*    }}*/}
                {/*    variant="subtitle2"*/}
                {/*  >*/}
                {/*    {user?.name}*/}
                {/*  </Typography>*/}
                {/*)}*/}
                <IconButton onClick={(evt) => {
                  handleClick(evt)
                }}>
                  <Avatar {...stringAvatar(user?.name)} />
                </IconButton>


                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                >
                  <MenuItem onClick={() => {
                    handleCloseMenu();
                    logout();
                  }}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                  </MenuItem>
                  {/*<MenuItem disabled={true}>*/}
                  {/*  <ListItemText>Version: {BUILD_DATE}</ListItemText>*/}
                  {/*</MenuItem>*/}
                </Menu>

              </Stack>
            )}
          </Stack>
        </Stack>
          <LocationChooser open={openLocationChooser} onClose={() => {
              setOpenLocationChooser(false);
          }} didSelectLocation={(location) => {
              setLocation(location)
              setOpenLocationChooser(false)
          }} ></LocationChooser>
      </Toolbar>
    </AppBar>
  );
};
