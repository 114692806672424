import {CrudFilters, HttpError} from "@refinedev/core";
import {UseFormSetError} from "react-hook-form";
import {IDepartment, ILocation, IShop, IUnit, IUser} from "../rest-data-provider/models";

export class HumanaUtils {

    static dataGridSearch(data: string) {
        const filters: CrudFilters = [];
        filters.push(
            {
                field: "q",
                operator: "eq",
                value: data,
            },
        );
        return filters;
    }

    static extractServerSideErrors(error: HttpError, setError: UseFormSetError<any>) {
        if(error) {
            console.log(error.response.data.violations)
            for (const violation of error.response.data.violations) {
                console.log(violation)
                setError(violation.fieldName,{message: violation.message})
            }
        }
    }

    static formatOrgUnitForUser = (user: IUser, units: IUnit[]) : string => {
        switch (user.role.name) {
            case HumanaRoles.ROLE_UNIT_MANAGER: return `(${HumanaUtils.formatUnit(user.unit, units)})`
            case HumanaRoles.ROLE_SHOP_MANAGER: return `(${HumanaUtils.formatShop(user.unit, user.shop, units)})`
            case HumanaRoles.ROLE_DEPARTMENT_MANAGER: return `(${HumanaUtils.formatDepartment(user.unit, user.shop, user.department, units)})`
        }
        return '';
    }

    static formatUnit(id: number, units : IUnit[]) {
        return units.find(value => value.id === id)?.name
    }

    static formatShop = (unitId: number, shopId: number, units: IUnit[]) : string => {
        const unit = units.find(value => value.id === unitId);
        const shop = unit?.shops.find(value => value.id === shopId);
        return `${shop?.shortName}`
    }

    static formatDepartment = (unitId: number, shopId: number, departmentId: number, units: IUnit[]) : string => {
        const unit = units.find(value => value.id === unitId);
        const shop = unit?.shops.find(value => value.id === shopId);
        const department = shop?.departments.find(value => value.id === departmentId);
        return `${shop?.shortName} - ${department?.name}`
    }

    static transformUnitsToLocations = (units : IUnit[]) : ILocation[]  => {
        const locations: ILocation[] = [];
        for (const unit of units) {
            const location: ILocation = {
                id: unit.id,
                name: unit.name,
                shortName: unit.shortName,
                shortNameGlobalContext: unit.name,
                parent: null,
                type: 'unit',
                children: [],
            };
            location.children = HumanaUtils.transformShopsToLocations(location, unit.shops)
            locations.push(location)
        }
        return locations;
    }

    private static transformShopsToLocations  = (parent: ILocation, shops : IShop[]) : ILocation[] => {
        const locations: ILocation[] = [];
        for (const shop of shops) {
            const location: ILocation = {
                id: shop.id,
                name: shop.name,
                shortName: shop.shortName,
                shortNameGlobalContext: `${parent.shortName} > ${shop.shortName}`,
                parent: parent,
                type: 'shop',
                children: []
            };
            location.children = HumanaUtils.transformDepartmentsToLocations(location, shop.departments)
            locations.push(location)
        }
        return locations;
    }

    private static transformDepartmentsToLocations  = (parent: ILocation, departments : IDepartment[]) : ILocation[] => {
        const locations: ILocation[] = [];
        for (const department of departments) {
            const location: ILocation = {
                id: department.id,
                name: department.name,
                shortName: `${department.name}`,
                shortNameGlobalContext: `${parent.shortName} > ${department.name}`,
                parent: parent,
                type: 'department',
                children: []
            };
            locations.push(location)
        }
        return locations;
    }

    static getClassNameForPercentage = (percentage : number) => {
        if(percentage < 50) {
            return 'problem';
        }
        if(percentage < 95) {
            return 'warning';
        }
        if(percentage < 105) {
            return 'ok'
        }
        return 'perfect'
    }

    static getClassNameForPercentageDifference = (percentage : number) => {
        console.log(percentage)
        if(percentage < -0.20) {
            return 'problem';
        }
        if(percentage < -0.05) {
            return 'warning';
        }
        if(percentage < 0.05) {
            return 'ok';
        }
        if(percentage >= 0.05) {
            return 'perfect'
        }
    }

    static filterNaNValue = (value: any) => {
        if (isNaN(value)) {
            return 0
        }
        if(value === Infinity) {
            return 0;
        }
        if (!value) {
            return 0;
        }
        return value;
    }
}

export class HumanaRoles {
    public static readonly ROLE_ADMIN = 'admin'
    public static readonly ROLE_MANAGEMENT = 'management'
    public static readonly ROLE_UNIT_MANAGER = 'unit_manager'
    public static readonly ROLE_SHOP_MANAGER = 'shop_manager'
    public static readonly ROLE_DEPARTMENT_MANAGER = 'department_manager'
    public static readonly ROLE_SALES = 'sales'
}