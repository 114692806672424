import {
    startOfYear,
    endOfYear,
    startOfWeek,
    endOfWeek,
    subYears,
    subWeeks,
    startOfMonth,
    endOfMonth,
    subMonths, getMonth, parse, getYear, getISOWeeksInYear
} from "date-fns";
import {title} from "process";
import {type} from "node:os";
import {WeekMappingDTO} from "./customDTOs";

export interface IBaseUser {
    id: number,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    email: string
    active: boolean
    unit: number,
    shop: number,
    department: number,
}

export interface IUser extends IBaseUser {
    role: IRole
    lastLogin: Date
}
export interface IUserCreate extends IBaseUser{
    role: string
}

export interface IRole {
    id: number,
    name: string,
    displayName: string,
    description: string
}

export interface IUnit {
    id: number,
    name: string,
    shortName: string,
    sort: number
    shops: IShop[]
}

export interface IShop {
    id: number,
    name: string,
    unit: number,
    costCenter: number,
    shortName: string
    departments: IDepartment[]
}

export interface IDepartment {
    id: number,
    name: string,
    departmentNumber: number,
    constCentre: number
}

export interface ILocation {
    id: number,
    name: string,
    shortName: string,
    shortNameGlobalContext: string,
    parent: ILocation|null,
    type: 'unit' | 'shop' | 'department'
    children: ILocation[]
}

export type DateRangeType = 'week_current'|'week_last'|'month_current'|'month_last'|'year_current'|'year_last'|'freeform'|'week_freeform'
export interface IDateRange {
    type: DateRangeType,
    from: Date,
    to: Date
    realFrom: Date,
    realTo: Date
}

export interface ICalenderWeek extends IDateRange{
    calenderWeek: number
}

export class SelectedDateRange {
    private readonly _dateRange: IDateRange|null = null;
    private _title : string | null = '-';
    public type : DateRangeType = 'week_current'
    private _weekMappings : WeekMappingDTO[]

    constructor(type: DateRangeType, dateRange: IDateRange| null, weekMappings: WeekMappingDTO[]) {
        this._title  = title;
        this.type = type;
        this._dateRange = dateRange
        this._weekMappings = weekMappings;
    }

    get title(): string | null {
        if(this.type == 'freeform') {
            return `${this.dateRange.from.toLocaleDateString()} - ${this.dateRange.to.toLocaleDateString()}`;
        }
        if(this.type == 'week_freeform') {
            return `${this.dateRange.from.toLocaleDateString()} - ${this.dateRange.to.toLocaleDateString()}`;
        }
        switch (this.type) {
            case "week_last": return 'Letzte Woche'
            case 'week_current': return 'Aktuelle Woche'
            case 'year_current': return 'Aktuelles Jahr'
            case 'year_last': return 'Letztes Jahr'
            case 'month_current': return  'Aktueller Monat'
            case 'month_last': return 'Letzter Monat'
        }
    }

    get dateRange(): IDateRange {
        if(this._dateRange != null) {
            return this._dateRange;
        }
        if(this._dateRange == null) {
            switch (this.type) {
                case "year_current":
                case "year_last": return this.rangeForYear(this.type)
                case "week_last": return {
                    from: subWeeks(startOfWeek(new Date(), {weekStartsOn: 1}), 1),
                    realFrom: subWeeks(startOfWeek(new Date(), {weekStartsOn: 1}), 1),
                    to: subWeeks(endOfWeek(new Date(), {weekStartsOn: 1}), 1),
                    realTo: subWeeks(endOfWeek(new Date(), {weekStartsOn: 1}), 1),
                    type: 'week_last'
                }
                default: return (this.rangeOfMappedMonth(this.type, this._weekMappings))
            }
        }
        return {
            from: startOfWeek(new Date(), {weekStartsOn: 1}),
            realFrom: startOfWeek(new Date(), {weekStartsOn: 1}),
            to: endOfWeek(new Date(), {weekStartsOn: 1}),
            realTo: endOfWeek(new Date(), {weekStartsOn: 1}),
            type:'week_current'
        }
    }

    private rangeForYear(type: DateRangeType) : IDateRange {
        let soy = startOfYear(new Date());
        let eoy = endOfYear(new Date());
        if(type === 'year_last') {
            soy = startOfYear(subYears(new Date(), 1));
            eoy = endOfYear(subYears(new Date(), 1));
        }
        const year = getYear(soy);
        const numberOfWeeks = getISOWeeksInYear(soy);

        const dateStart = parse(`${1}`,'I', soy)
        const dateEnd = parse(`${numberOfWeeks}`,'I', eoy)

        return {
            type: type,
            from: dateStart,
            to: dateEnd,
            realFrom:  soy,
            realTo: eoy
        }
    }
    private rangeOfMappedMonth(type: DateRangeType, weekMappings: WeekMappingDTO[]): IDateRange {

        let som = new Date();
        let eom = new Date();
        if(type == 'month_current') {
            som =  startOfMonth(new Date())
            eom = endOfMonth(new Date())
        } else if(type == 'month_last') {
            som = subMonths(startOfMonth(new Date()), 1);
            eom = subMonths(endOfMonth(new Date()), 1);
        } else {
            return this.getDateRangeForCurrentWeek();
        }
        const month = getMonth(som) + 1;
        for (const weekMapping of weekMappings) {
            if(weekMapping.month === month) {
                const weekStart = weekMapping.calenderWeekStart;
                const weekEnd: number = weekMapping.calenderWeekEnd;

                let dateStart = parse(`${weekStart}`,'I', new Date())
                let dateEnd = parse(`${weekEnd}`,'I', new Date())

                dateStart = startOfWeek(dateStart, {weekStartsOn: 1});
                dateEnd = endOfWeek(dateEnd, {weekStartsOn: 1});

                console.log(`${dateStart.toLocaleDateString()} - ${dateEnd.toLocaleDateString()}`)

                return {
                    type: type,
                    from: dateStart,
                    to: dateEnd,
                    realFrom: som,
                    realTo: eom
                }
            }
        }
        return this.getDateRangeForCurrentWeek();
    }

    private getDateRangeForCurrentWeek() :IDateRange {
        return {
            from: startOfWeek(new Date(), {weekStartsOn: 1}),
            to: endOfWeek(new Date(), {weekStartsOn: 1}),
            realFrom: startOfWeek(new Date(), {weekStartsOn: 1}),
            realTo: endOfWeek(new Date(), {weekStartsOn: 1}),
            type:'week_current'
        }
    }
}