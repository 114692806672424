import {IShop, IUser} from "../../../rest-data-provider/models";
import {ReactElement} from "react";
import {v4 as uuidv4} from "uuid";

export abstract class BaseExternalData {
    abstract id: number|undefined
    abstract uuid?: string|undefined
    abstract day: Date|null
    abstract createdAt: string|null
    abstract updatedAt: string|null
    abstract updatedBy: IUser|null

    abstract equals(other: {id: number|undefined, uuid?: string|undefined }) : boolean;
    abstract clone(): BaseExternalData;
    abstract generateRowId(shop: IShop|undefined|null):string;
    abstract generateDeleteConfirmation():ReactElement;
    abstract updateFromResponse(response:any):void;
    abstract toRequest():any;

    public cloneWithNewUuid(): BaseExternalData {
        const clone = this.clone();
        clone.uuid = uuidv4();
        return clone;
    }
}