import {AuthBindings, AuthProvider, LoginFormTypes} from "@refinedev/core";
import {axiosInstance} from "./rest-data-provider/utils";
import {IUser} from "./rest-data-provider/models";
import {userData} from "./AccessControlProvider";
import {AuthActionResponse} from "@refinedev/core/dist/contexts/auth/types";

export const TOKEN_KEY = "refine-auth";





export const authProvider: AuthProvider = {
  login: async ( {email, password }): Promise<AuthActionResponse>  => {

    try{
      if (email && password) {
        const url = import.meta.env.VITE_API_BASE_URL;
        localStorage.removeItem(TOKEN_KEY);
        delete axiosInstance.defaults.headers.common['Authorization'];
        const response = await axiosInstance.post(url + '/auth/login', {
          email: email,
          otp: password
        });
        localStorage.setItem(TOKEN_KEY, response.data.token);
        axiosInstance.defaults.headers.common = {
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        };
        return {
          success: true,
          redirectTo: "/",
        }
      }
    } catch (e) {
      console.error(e)
    }

    return {
      success: false,
      error: {
        name: "Login Fehler",
        message: "Ungültige Zugangsdaten",
      },
    };
  },
  logout: async () => {
    localStorage.removeItem(TOKEN_KEY);
    delete axiosInstance.defaults.headers.common['Authorization'];
    userData.role = null;
    localStorage.removeItem('timestampSendOtp');
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  check: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      };
      const url = import.meta.env.VITE_API_BASE_URL;
      const response = await axiosInstance.get<IUser>(url + '/users/aboutme');
      return {
        authenticated: !!response.data.email,
      };
    }
    // localStorage.removeItem(TOKEN_KEY);
    delete axiosInstance.defaults.headers.common['Authorization'];
    return {
      authenticated: false,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      const url = import.meta.env.VITE_API_BASE_URL;
      const response = await axiosInstance.get<IUser>(url + '/users/aboutme');
      return {
        id: response.data.id,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        name: response.data.firstName + " " + response.data.lastName,
        avatar: null,
        role: response.data.role
      };
    }
    return null;
  },
  onError: async (error) => {
    console.error(error);
    if(error && error.statusCode && error.statusCode == 401) {
      localStorage.removeItem(TOKEN_KEY);
      delete axiosInstance.defaults.headers.common['Authorization'];
      window.location.replace("/login");
    }
    return { error };
  },
};
